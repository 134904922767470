// @file Network requests related to lms passback

import type { VersionedFetchOptions } from '@@/surface/api/fetchable_object'
import FetchableObject from '@@/surface/api/fetchable_object'
import { fetchJson } from '@@/surface/api_fetch'
import type { LmsPassbackResponse, SyncStateUserIds, WallId } from '@@/types'
import type { JsonAPIResponse } from '@padlet/arvo'
import { HTTPMethod } from '@padlet/fetch'

class LmsPassbackApi extends FetchableObject {
  public static async syncWithLms(
    wallId: WallId,
    fetchOptions: VersionedFetchOptions = {},
  ): Promise<JsonAPIResponse<LmsPassbackResponse>> {
    const { apiVersion = 1, ...otherFetchOptions } = fetchOptions
    return await fetchJson(`/api/${apiVersion}/grades/sync`, {
      method: HTTPMethod.post,
      query: {
        wallId: String(wallId),
      },
      ...otherFetchOptions,
    })
  }

  public static async fetchUserSyncState(
    wallId: WallId,
    fetchOptions: VersionedFetchOptions = {},
  ): Promise<JsonAPIResponse<SyncStateUserIds>> {
    const { apiVersion = 1, ...otherFetchOptions } = fetchOptions
    return await fetchJson(`/api/${apiVersion}/grades/user-sync-status`, {
      method: HTTPMethod.get,
      query: {
        wallId: String(wallId),
      },
      ...otherFetchOptions,
    })
  }
}

export { LmsPassbackApi }
