// @file Network requests related to Wall Custom Post Properties from surface

import getCsrfToken from '@@/bits/csrf_token'
import window, { browsingContextUid } from '@@/bits/global'
import { asciiSafeStringify } from '@@/bits/json_stringify'
import { getSubmissionRequestTokenHash } from '@@/bits/surface_share_links_helper'
import type { VersionedFetchOptions } from '@@/surface/api/fetchable_object'
import FetchableObject from '@@/surface/api/fetchable_object'
import { fetchJson } from '@@/surface/api_fetch'
import type { WallCustomPostProperty } from '@@/types'
import type { JsonAPIResource, JsonAPIResponse } from '@padlet/arvo'
import { fetchResponse, HTTPMethod } from '@padlet/fetch'

class WallCustomPostPropertiesApi extends FetchableObject {
  public static get url(): string {
    return `/api/\${ apiVersion }/walls/\${ wallId }/custom-post-properties`
  }

  public static get urlWithPropertyId(): string {
    return `/api/\${ apiVersion }/walls/\${ wallId }/custom-post-properties/\${propertyId}`
  }

  public static async createWallCustomPostProperty(
    wallId: number,
    postProperties: WallCustomPostProperty,
    fetchOptions: VersionedFetchOptions = { apiVersion: 1 },
  ): Promise<WallCustomPostProperty> {
    const { apiVersion, ...otherFetchOptions } = fetchOptions
    const response: JsonAPIResponse<WallCustomPostProperty> = await fetchJson(
      this.buildUrl(this.url, { wallId, apiVersion }),
      {
        method: HTTPMethod.post,
        body: asciiSafeStringify({ data: { attributes: postProperties } }),
        ...otherFetchOptions,
      },
    )
    const newCustomPostProperty = Array.isArray(response.data)
      ? response.data[0]?.attributes
      : response.data?.attributes
    if (newCustomPostProperty == null) throw new Error('Invalid response from server')
    return newCustomPostProperty
  }

  public static async updateWallCustomPostProperty(
    wallId: number,
    updatedPostProperty: WallCustomPostProperty,
    fetchOptions: VersionedFetchOptions = { apiVersion: 1 },
  ): Promise<WallCustomPostProperty> {
    const { apiVersion, ...otherFetchOptions } = fetchOptions
    const response: JsonAPIResponse<WallCustomPostProperty> = await fetchJson(
      this.buildUrl(this.urlWithPropertyId, { wallId, apiVersion, propertyId: updatedPostProperty.id }),
      {
        method: HTTPMethod.patch,
        body: asciiSafeStringify({
          data: {
            attributes: {
              property_id: updatedPostProperty.id,
              ...updatedPostProperty,
            },
          },
        }),
        ...otherFetchOptions,
      },
    )
    const newCustomPostProperty = Array.isArray(response.data)
      ? response.data[0]?.attributes
      : response.data?.attributes
    if (newCustomPostProperty == null) throw new Error('Invalid response from server')
    return newCustomPostProperty
  }

  public static async fetchWallCustomPostProperties(
    wallId: number,
    fetchOptions: VersionedFetchOptions = { apiVersion: 1 },
  ): Promise<WallCustomPostProperty[]> {
    const { apiVersion, ...otherFetchOptions } = fetchOptions
    const response: JsonAPIResponse<WallCustomPostProperty[]> = await fetchJson(
      this.buildUrl(this.url, { wallId, apiVersion }),
      {
        method: HTTPMethod.get,
        query: getSubmissionRequestTokenHash(),
        ...otherFetchOptions,
      },
    )
    if (!Array.isArray(response.data) || response.data == null)
      throw new Error('Custom post properties should be an array')

    const customPostProperties = (response.data as unknown as Array<JsonAPIResource<WallCustomPostProperty>>).map(
      ({ attributes }) => {
        return attributes
      },
    )
    return customPostProperties
  }

  public static async deleteWallCustomPostProperty(
    wallId: number,
    propertyId: string,
    fetchOptions: VersionedFetchOptions = { apiVersion: 1 },
  ): Promise<void> {
    const { apiVersion, ...otherFetchOptions } = fetchOptions
    await fetchResponse(this.buildUrl(this.urlWithPropertyId, { wallId, apiVersion, propertyId }), {
      method: HTTPMethod.delete,
      headers: {
        'X-CSRF-Token': getCsrfToken(),
        'X-UID': window?.ww?.uid || browsingContextUid,
      },
      ...otherFetchOptions,
    })
  }
}

export { WallCustomPostPropertiesApi }
