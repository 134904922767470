// @file Network requests related to submission request from surface

import getCsrfToken from '@@/bits/csrf_token'
import { browsingContextUid } from '@@/bits/global'
import { asciiSafeStringify } from '@@/bits/json_stringify'
import type { VersionedFetchOptions } from '@@/surface/api/fetchable_object'
import FetchableObject from '@@/surface/api/fetchable_object'
import { fetchJson } from '@@/surface/api_fetch'
import type { WallId, WallSubmissionRequestLink, WallSubmissionRequestProps } from '@@/types'
import { fetchResponse, HTTPMethod } from '@padlet/fetch'

class SubmissionRequestApi extends FetchableObject {
  public static get submissionRequestUrl(): string {
    return `/api/\${ apiVersion }/walls/\${ wallId }/submission-request\${ method }`
  }

  public static async enable(
    wallId: WallId,
    confirmationText: string,
    fetchOptions: VersionedFetchOptions = { apiVersion: 1 },
  ): Promise<WallSubmissionRequestLink> {
    const { apiVersion, ...restFetchOptions } = fetchOptions

    const response = await fetchJson(
      this.buildUrl(this.submissionRequestUrl, { wallId, apiVersion, method: '/enable' }),
      {
        method: HTTPMethod.patch,
        body: asciiSafeStringify({
          confirmation_text: confirmationText,
        }),
        ...restFetchOptions,
      },
    )

    return response.data.attributes
  }

  public static async update(
    wallId: WallId,
    attributes: WallSubmissionRequestProps,
    fetchOptions: VersionedFetchOptions = { apiVersion: 1 },
  ): Promise<WallSubmissionRequestLink> {
    const { apiVersion, ...restFetchOptions } = fetchOptions

    const response = await fetchJson(this.buildUrl(this.submissionRequestUrl, { wallId, apiVersion, method: '' }), {
      method: HTTPMethod.put,
      body: asciiSafeStringify({ attributes }),
      ...restFetchOptions,
    })

    return response.data.attributes
  }

  public static async fetch(
    wallId: WallId,
    fetchOptions: VersionedFetchOptions = { apiVersion: 1 },
  ): Promise<WallSubmissionRequestLink | undefined> {
    const { apiVersion, ...restFetchOptions } = fetchOptions

    const response = await fetchJson(this.buildUrl(this.submissionRequestUrl, { wallId, apiVersion, method: '' }), {
      method: HTTPMethod.get,
      ...restFetchOptions,
    })

    return response?.data?.attributes
  }

  public static async disable(wallId: WallId, fetchOptions: VersionedFetchOptions = { apiVersion: 1 }): Promise<void> {
    const { apiVersion, ...restFetchOptions } = fetchOptions

    // using fetchResponse because api does not return json
    await fetchResponse(this.buildUrl(this.submissionRequestUrl, { wallId, apiVersion, method: '/disable' }), {
      method: HTTPMethod.patch,
      headers: {
        'X-CSRF-Token': getCsrfToken(),
        'X-UID': window?.ww?.uid || browsingContextUid,
      },
      ...restFetchOptions,
    })
  }
}

export { SubmissionRequestApi }
