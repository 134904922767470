// @file Network requests related to grades

import { asciiSafeStringify } from '@@/bits/json_stringify'
import type { VersionedFetchOptions } from '@@/surface/api/fetchable_object'
import FetchableObject from '@@/surface/api/fetchable_object'
import { fetchJson } from '@@/surface/api_fetch'
import type { Grade, UserId, WallId } from '@@/types'
import type { JsonAPIResource } from '@padlet/arvo'
import { HTTPMethod } from '@padlet/fetch'

class GradesApi extends FetchableObject {
  public static async fetch(
    wallId: WallId,
    userId?: UserId,
    fetchOptions: VersionedFetchOptions = {},
  ): Promise<Grade[]> {
    const { apiVersion = 1, ...otherFetchOptions } = fetchOptions
    const query: { wallId: string; userId?: string } = {
      wallId: String(wallId),
    }
    if (userId != null) query.userId = userId.toString()
    const response = await fetchJson(`/api/${apiVersion}/grades`, {
      method: HTTPMethod.get,
      query,
      ...otherFetchOptions,
    })

    return response.data.map((d: JsonAPIResource<Grade[]>): Grade[] => d.attributes)
  }

  public static async create(attributes: Grade, fetchOptions: VersionedFetchOptions = {}): Promise<Grade> {
    const { apiVersion = 1, ...otherFetchOptions } = fetchOptions
    const response = await fetchJson(`/api/${apiVersion}/grades`, {
      method: HTTPMethod.post,
      body: asciiSafeStringify({ data: { attributes } }),
      ...otherFetchOptions,
    })
    return response.data.attributes as Grade
  }

  public static async update(attributes: Partial<Grade>, fetchOptions: VersionedFetchOptions = {}): Promise<Grade> {
    const { apiVersion = 1, ...otherFetchOptions } = fetchOptions
    const response = await fetchJson(`/api/${apiVersion}/grades`, {
      method: HTTPMethod.patch,
      body: asciiSafeStringify({ data: { attributes } }),
      ...otherFetchOptions,
    })
    return response.data.attributes as Grade
  }

  public static async delete(attributes: Grade, fetchOptions: VersionedFetchOptions = {}): Promise<void> {
    const { apiVersion = 1, ...otherFetchOptions } = fetchOptions
    await fetchJson(`/api/${apiVersion}/grades`, {
      method: HTTPMethod.delete,
      body: asciiSafeStringify({ data: { attributes } }),
      ...otherFetchOptions,
    })
  }
}

export { GradesApi }
