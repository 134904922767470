// @file Surface comments store
import { trackEvent } from '@@/bits/analytics'
import { CONFIRM_MODERATION_REJECT_COMMENT, SELF_HARM_COMMENT_ALERT } from '@@/bits/confirmation_dialog'
import { captureFetchException } from '@@/bits/error_tracker'
import { __ } from '@@/bits/intl'
import { navigateTo } from '@@/bits/location'
import { COMMENT_BODY_CHAR_LIMIT } from '@@/bits/numbers'
import { getVuexStore } from '@@/bits/pinia'
import { useReactiveSet } from '@@/bits/reactivity'
import { findLast } from '@@/bits/set'
import type { UploadJob } from '@@/bits/uploader'
import { selfHarmHelplineUrl } from '@@/bits/url'
import { vDel, vSet } from '@@/bits/vue'
import { SnackbarNotificationType } from '@@/enums'
import { useExpandedPostStore } from '@@/pinia/expanded_post'
import {
  OzConfirmationDialogBoxButtonScheme,
  useGlobalConfirmationDialogStore,
} from '@@/pinia/global_confirmation_dialog'
import { useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import { useNativeAppStore } from '@@/pinia/native_app'
import { CommentAttachmentState, useSurfaceCommentAttachmentsStore } from '@@/pinia/surface_comment_attachments'
import { useSurfaceContainerSizeStore } from '@@/pinia/surface_container_size'
import { ContributionType, useSurfaceGuestStore } from '@@/pinia/surface_guest_store'
import { CommentApi, CommentV2Api } from '@@/surface/api/comment'
import type { Cid, Comment, CommentId, HashId, Id, Post, User } from '@@/types'
import type { RootState as SurfaceState } from '@@/vuexstore/surface/types'
import type { JsonAPIResource } from '@padlet/arvo'
import { cloneDeep, isEmpty } from 'lodash-es'
import { defineStore } from 'pinia'
import type { ComputedRef } from 'vue'
import { computed, ref } from 'vue'

export interface CreateCommentPayload {
  postId: Id
  htmlBody?: string
  attachment?: string
}

export const useCommentsStore = defineStore('comments', () => {
  const surfaceVuexStore = getVuexStore<SurfaceState>()
  const globalSnackbarStore = useGlobalSnackbarStore()
  const surfaceCommentAttachments = useSurfaceCommentAttachmentsStore()
  const surfaceGuestStore = useSurfaceGuestStore()
  const remoteComments = ref<Comment[]>([])

  const user = computed<User>(() => surfaceVuexStore?.getters.user)
  const commentIds = computed<string[]>(() => surfaceVuexStore?.getters['comment/commentIds'])
  const commentIdsByPost = computed<{ [wishId: number]: CommentId[] }>(
    () => surfaceVuexStore?.getters['comment/commentIdsByPost'],
  )
  const commentsByPostId = computed<Record<Id, Comment[]>>(() => surfaceVuexStore?.getters['comment/commentsByPost'])

  const isCommenting = computed<boolean>(() => hasCommentBeingEdited.value || activeNewCommentPostId.value != null)
  const inlineCommentInputLastTypedInMs = computed<number>(
    () => surfaceVuexStore?.state.comment.inlineCommentInputLastTypedInMs ?? 0,
  )
  const commentEntities = computed<Record<Id, Comment>>(() => surfaceVuexStore?.state.comment.commentEntities ?? {})
  const unapprovedComments = computed<Comment[]>(() => {
    const comments: Comment[] = Object.values(commentEntities.value)
    return comments.filter((comment) => comment.is_awaiting_approval === true)
  })
  const commentBeingEditedId = computed<CommentId | null>(
    () => surfaceVuexStore?.state.comment.commentBeingEditedId ?? null,
  )
  const commentUnderActionId = computed<CommentId | null>(
    () => surfaceVuexStore?.state.comment.actionMenuCommentId ?? null,
  )
  const activeNewCommentPostId = computed<Id | null>(
    () => surfaceVuexStore?.state.comment.activeNewCommentPostId ?? null,
  )
  const isAddingNewComment = computed(() => activeNewCommentPostId.value != null)

  /**
   * A set of comment IDs that are currently being edited.
   */
  const commentsBeingEditedSet = useReactiveSet<CommentId>()

  const hasCommentBeingEdited = computed(() => {
    return commentsBeingEditedSet.value.size > 0
  })

  const isCommentBeingEdited = (commentId: CommentId): boolean => {
    return commentsBeingEditedSet.value.has(commentId)
  }

  const latestCommentBeingEdited = computed(() => findLast(commentsBeingEditedSet.value))

  // #region NEW/DRAFT COMMENTS
  interface CommentDraft {
    html_body?: string
    attachment?: string
    uploadingFile?: File
    uploadingProgress?: number
    uploadJob?: UploadJob
  }

  const newCommentByPostCid = ref<Record<Cid, CommentDraft>>({})

  const updateNewCommentByPostCid = (postCid: Cid, comment: Partial<CommentDraft>): void => {
    const existingComment = newCommentByPostCid.value[postCid]
    if (existingComment == null && isEmpty(comment)) return
    vSet(newCommentByPostCid.value, postCid, {
      ...(existingComment ?? {}),
      ...comment,
    })
  }

  const deleteNewCommentProperties = (postCid: Cid, props: Array<keyof CommentDraft>): void => {
    const existingComment = newCommentByPostCid.value[postCid]
    if (existingComment == null) return

    const newComment = cloneDeep(existingComment)
    props.forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete newComment[key]
    })

    // If the object is now empty, delete it from the `newCommentByPostCid` state.
    if (isEmpty(newComment)) {
      vDel(newCommentByPostCid.value, postCid)
    } else {
      vSet(newCommentByPostCid.value, postCid, newComment)
    }
  }

  const updateNewCommentBody = ({ postCid, body }: { postCid: Cid; body: string }): void => {
    updateNewCommentByPostCid(postCid, { html_body: body })
  }

  const updateNewCommentAttachment = ({ postCid, attachment }: { postCid: Cid; attachment: string | null }): void => {
    if (attachment != null) {
      updateNewCommentByPostCid(postCid, { attachment })
    } else {
      deleteNewCommentProperties(postCid, ['attachment'])
    }
  }

  const updateNewCommentUploadingFile = ({
    postCid,
    file,
    uploadJob,
  }: {
    postCid: Cid
    file: File
    uploadJob: UploadJob
  }): void => {
    updateNewCommentByPostCid(postCid, { uploadingFile: file, uploadJob })
  }

  const updateNewCommentUploadingFileProgress = ({ postCid, progress }: { postCid: Cid; progress: number }): void => {
    if (newCommentByPostCid.value[postCid]?.uploadingFile == null) return
    updateNewCommentByPostCid(postCid, { uploadingProgress: progress })
  }

  const isUploadingAttachmentForNewComment = ({ postCid }: { postCid: Cid }): boolean =>
    newCommentByPostCid.value[postCid]?.uploadingFile != null

  /**
   * Takes action on this new comment.
   * - Cancels the upload job if there is one.
   * - Deletes all states related to uploading file.
   */
  const removeUploadingFileForNewComment = ({ postCid }: { postCid: Cid }): void => {
    newCommentByPostCid.value[postCid].uploadJob?.cancel()
    deleteNewCommentProperties(postCid, ['uploadingFile', 'uploadingProgress', 'uploadJob'])
  }

  /**
   * Removes all states related to attachment for this new comment.
   */
  const removeAttachmentForNewComment = ({ postCid }: { postCid: Cid }): void => {
    newCommentByPostCid.value[postCid]?.uploadJob?.cancel()
    deleteNewCommentProperties(postCid, ['attachment', 'uploadingFile', 'uploadingProgress', 'uploadJob'])
  }

  const newCommentBodyForPostCid = (postCid: Cid): string => {
    return newCommentByPostCid.value[postCid]?.html_body ?? ''
  }

  const newCommentAttachmentForPostCid = (postCid: Cid): string | null => {
    return newCommentByPostCid.value[postCid]?.attachment ?? null
  }

  const newCommentUploadingFileForPostCid = (postCid: Cid): File | null => {
    return newCommentByPostCid.value[postCid]?.uploadingFile ?? null
  }

  const newCommentUploadingProgressForPostCid = (postCid: Cid): number | null => {
    return newCommentByPostCid.value[postCid]?.uploadingProgress ?? null
  }

  const resetComments = (): void => {
    newCommentByPostCid.value = {}
  }
  // #endregion

  // #region COMMENTS BEING EDITED
  const editedCommentById = ref<Record<CommentId, CommentDraft>>({})

  const updateEditedCommentById = (id: CommentId, comment: Partial<CommentDraft>): void => {
    const existingComment = editedCommentById.value[id]
    if (existingComment == null && isEmpty(comment)) return
    vSet(editedCommentById.value, id, {
      ...(existingComment ?? {}),
      ...comment,
    })
  }

  const deleteEditedCommentProperties = (commentId: CommentId, props: Array<keyof CommentDraft>): void => {
    const existingComment = editedCommentById.value[commentId]
    if (existingComment == null) return

    const newComment = cloneDeep(existingComment)
    props.forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete newComment[key]
    })

    // If the object is now empty, delete it from the `editedCommentById` state.
    if (isEmpty(newComment)) {
      vDel(editedCommentById.value, commentId)
    } else {
      vSet(editedCommentById.value, commentId, newComment)
    }
  }

  function updateEditedDraftCommentBody(payload: { commentId: CommentId; body: string }): void {
    updateEditedCommentById(payload.commentId, { html_body: payload.body })
  }

  const updateEditedDraftCommentAttachment = (payload: { commentId: CommentId; attachment: string | null }): void => {
    if (payload.attachment != null) {
      updateEditedCommentById(payload.commentId, { attachment: payload.attachment })
    } else {
      deleteEditedCommentProperties(payload.commentId, ['attachment'])
    }
  }

  const updateEditedDraftCommentUploadingFile = ({
    commentId,
    file,
    uploadJob,
  }: {
    commentId: CommentId
    file: File
    uploadJob: UploadJob
  }): void => {
    updateEditedCommentById(commentId, { uploadingFile: file, uploadJob })
  }

  const updateEditedDraftCommentUploadingFileProgress = ({
    commentId,
    progress,
  }: {
    commentId: CommentId
    progress: number
  }): void => {
    if (editedCommentById.value[commentId]?.uploadingFile == null) return
    updateEditedCommentById(commentId, { uploadingProgress: progress })
  }

  /**
   * Takes action on this comment.
   * - Cancels the upload job if there is one.
   * - Deletes all states related to uploading file.
   */
  const removeUploadingFileForEditedComment = ({ commentId }: { commentId: CommentId }): void => {
    editedCommentById.value[commentId].uploadJob?.cancel()
    deleteEditedCommentProperties(commentId, ['uploadingFile', 'uploadingProgress', 'uploadJob'])
  }

  /**
   * Removes all states related to attachment for this comment.
   */
  const removeAttachmentForEditedComment = ({ commentId }: { commentId: CommentId }): void => {
    editedCommentById.value[commentId].uploadJob?.cancel()
    deleteEditedCommentProperties(commentId, ['attachment', 'uploadingFile', 'uploadingProgress', 'uploadJob'])
  }

  const isUploadingAttachmentForEditedComment = ({ commentId }: { commentId: CommentId }): boolean =>
    editedCommentById.value[commentId]?.uploadingFile != null

  const editedDraftCommentBodyFromId = (commentId: CommentId): string => {
    return editedCommentById.value[commentId]?.html_body ?? ''
  }

  const editedDraftCommentAttachmentFromId = (commentId: CommentId): string | null => {
    return editedCommentById.value[commentId]?.attachment ?? null
  }

  const editedDraftCommentUploadingFileFromId = (commentId: CommentId): File | null => {
    return editedCommentById.value[commentId]?.uploadingFile ?? null
  }

  const editedDraftCommentUploadingProgressFromId = (commentId: CommentId): number | null => {
    return editedCommentById.value[commentId]?.uploadingProgress ?? null
  }

  async function createCommentV2({ postId, htmlBody, attachment }: CreateCommentPayload): Promise<Comment | undefined> {
    if (surfaceGuestStore.shouldShowGuestIdModal) {
      surfaceGuestStore.showGuestIdModal({
        afterSaveActions: [async () => await createCommentV2({ postId, htmlBody, attachment })],
        contributionType: ContributionType.Comment,
        contributionPayload: { postId, htmlBody, attachment },
      })
      return
    } else if (surfaceGuestStore.shouldUpdateNameBeforePublishing) {
      surfaceGuestStore.setUserName(surfaceGuestStore.displayName)
      await surfaceGuestStore.updateSessionUserName({ shouldModerate: false })
    }

    if (htmlBody != null && htmlBody.length > COMMENT_BODY_CHAR_LIMIT) {
      showCommentCharacterLimitSnackbar()
      return
    }

    // tempId will be used to identify the comment in the frontend until it is persisted,
    // after which, we will use the server-provided Id.
    const tempId = `new_${Date.now()}`

    const newComment = {
      wish_id: postId,
      html_body: htmlBody,
      attachment,
      user_id: user.value.id,
      user_hashid: user.value.hashid,
    }
    surfaceVuexStore?.commit('comment/ADD_PENDING_COMMENT', { id: tempId, ...newComment })

    try {
      const post = surfaceVuexStore?.getters['post/getPostByServerId'](postId)
      // Delete the new comment from the store and reset the attachment state to IDLE.
      // - The new comment isn't published, it's not new anymore.
      // - Reverse the state back to IDLE so that new comments can be created again.
      deleteNewCommentProperties(post.cid, ['html_body', 'attachment'])
      surfaceCommentAttachments.updateNewCommentAttachmentState({
        postCid: post.cid,
        state: CommentAttachmentState.IDLE,
      })

      const response = await CommentV2Api.create(newComment, {
        apiVersion: 8,
      })
      const comment = (response.data as JsonAPIResource<Comment>).attributes

      surfaceVuexStore?.commit('comment/ADD_COMMENT', { comment, tempId })
      return comment
    } catch (e) {
      void globalSnackbarStore.genericFetchError()
      captureFetchException(e, { source: 'SurfaceCreateCommentV2' })
      const post = surfaceVuexStore?.getters['post/getPostByServerId'](postId)
      if (post == null || post.cid == null) {
        void surfaceVuexStore?.commit('comment/REMOVE_PENDING_COMMENT', { commentId: tempId })
      } else {
        void surfaceVuexStore?.commit('comment/REVERT_PENDING_COMMENT', { commentId: tempId, postCid: post.cid })
        const comment = commentEntities.value[tempId]
        updateNewCommentBody({ postCid: post.cid, body: comment.html_body ?? comment.body })
        updateNewCommentAttachment({ postCid: post.cid, attachment: comment.attachment })
        return comment
      }
    } finally {
      void surfaceVuexStore?.dispatch('realtime/dispatchAllQueuedActions', null, { root: true })
      void useNativeAppStore().postSurfaceState()
    }
    return undefined
  }

  function startEditingCommentV2({ commentId }: { commentId: CommentId }): void {
    commentsBeingEditedSet.value.add(commentId)
    const editingComment = commentEntities.value[commentId]
    updateEditedCommentById(commentId, {
      html_body: editingComment?.html_body,
      attachment: editingComment?.attachment,
    })
    if (editingComment?.attachment != null) {
      surfaceCommentAttachments.updateEditedCommentAttachmentState({ commentId, state: CommentAttachmentState.PREVIEW })
    }
  }

  async function saveEditComment({
    id,
    body,
    attachment,
  }: {
    id: Id
    body?: string
    attachment?: string
  }): Promise<void> {
    const payload: { htmlBody?: string; attachment?: string } = {}
    if (body !== undefined) {
      payload.htmlBody = body
    }

    if (attachment !== undefined) {
      payload.attachment = attachment
    }
    await editCommentV2({ id, payload })
  }

  async function editCommentV2({
    id,
    payload,
  }: {
    id: Id
    payload: { htmlBody?: string; attachment?: string }
  }): Promise<void> {
    // Comparing to existingComment so that only comments that already exceed character limit can continue to exceed character limit
    const existingCommentBody = commentEntities.value[id]?.html_body ?? ''
    if (
      existingCommentBody.length <= COMMENT_BODY_CHAR_LIMIT &&
      payload.htmlBody != null &&
      payload.htmlBody.length > COMMENT_BODY_CHAR_LIMIT
    ) {
      showCommentCharacterLimitSnackbar()
      return
    }

    try {
      void surfaceVuexStore?.commit('comment/UPDATE_COMMENT', {
        id,
        obj: { html_body: payload.htmlBody, attachment: payload.attachment },
      })
      const {
        data: {
          attributes: { html_body: newHtmlBody, body: newBody, attachment: newAttachment },
        },
      } = await CommentV2Api.update(id, payload, {
        apiVersion: 8,
      })
      void surfaceVuexStore?.commit('comment/UPDATE_COMMENT', {
        id,
        obj: { html_body: newHtmlBody, body: newBody, attachment: newAttachment },
      })
    } catch (e) {
      void globalSnackbarStore.genericFetchError()
      captureFetchException(e, { source: 'SurfaceEditCommentV2' })
      startEditingCommentV2({ commentId: id })
    } finally {
      stopEditingComment(id)
      void surfaceVuexStore?.dispatch('realtime/dispatchAllQueuedActions', null, { root: true })
      void useNativeAppStore().postSurfaceState()
    }
  }

  async function approveComment(comment: Comment): Promise<void> {
    const {
      data: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        attributes: { is_awaiting_approval },
      },
    } = await CommentV2Api.approve(Number(comment.id))
    void surfaceVuexStore?.commit('comment/UPDATE_COMMENT', {
      id: comment.id,
      obj: { is_awaiting_approval },
    })
  }

  async function rejectComment(comment: Comment): Promise<void> {
    void useGlobalConfirmationDialogStore().openConfirmationDialog({
      ...CONFIRM_MODERATION_REJECT_COMMENT,
      afterConfirmActions: [
        async () => {
          void surfaceVuexStore?.commit('comment/REMOVE_COMMENT', comment.id)
          await CommentApi.delete(comment)
        },
      ],
    })
  }

  function showCommentCharacterLimitSnackbar(): void {
    void globalSnackbarStore.setSnackbar({
      message: __('Comment character limit reached'),
      notificationType: SnackbarNotificationType.error,
    })
  }

  function askToDeleteComment({ commentId }: { commentId: Id }): void {
    void useGlobalConfirmationDialogStore().openConfirmationDialog({
      title: __('Delete this comment?'),
      body: __('Are you sure you want to delete this comment? This cannot be undone!'),
      confirmButtonText: __('Delete'),
      cancelButtonText: __('Cancel'),
      buttonScheme: OzConfirmationDialogBoxButtonScheme.Danger,
      afterConfirmActions: [
        async () => await surfaceVuexStore?.dispatch('comment/deleteComment', { commentId }, { root: true }),
      ],
    })
  }
  // #endregion

  // eslint-disable-next-line @typescript-eslint/naming-convention
  function showSelfHarmCommentAlert({ comment_id }: { comment_id?: Id }): void {
    if (comment_id == null) return

    trackEvent('SurfaceSelfHarmWarning', 'Showed Self-harm dialog for comment', comment_id)
    void useGlobalConfirmationDialogStore().openConfirmationDialog({
      ...SELF_HARM_COMMENT_ALERT,
      afterConfirmActions: [() => navigateTo(selfHarmHelplineUrl(), { target: 'blank' })],
    })
  }

  /* ---------------------- */
  /* VUEX ACTIONS           */
  /* ---------------------- */

  function fetchComments(payload: { shouldResetState?: boolean; wishId: Id; wishHashid: HashId }): void {
    void surfaceVuexStore?.dispatch('comment/fetchComments', payload)
  }

  function refreshComments(): void {
    void surfaceVuexStore?.dispatch('comment/refreshComments')
  }

  function showCommentActionMenu(payload: { commentId: CommentId }): void {
    void surfaceVuexStore?.dispatch('comment/showCommentActionMenu', payload)
  }

  function hideCommentActionMenu(): void {
    void surfaceVuexStore?.dispatch('comment/hideCommentActionMenu')
  }

  function stopEditingComment(commentId: CommentId): void {
    if (isCommentBeingEdited(commentId)) {
      vDel(editedCommentById.value, commentId)
      surfaceCommentAttachments.removeEditedCommentAttachmentState({ commentId })
    }
    commentsBeingEditedSet.value.delete(commentId)
  }

  function startWritingNewComment(payload: { postId: Id }): void {
    void surfaceVuexStore?.dispatch('comment/startWritingNewComment', payload)
  }

  function stopWritingNewComment(payload: { postId: Id | undefined }): void {
    void surfaceVuexStore?.dispatch('comment/stopWritingNewComment', payload)
  }

  function resetWritingNewComment(payload: { post: Post }): void {
    deleteNewCommentProperties(payload.post.cid, ['html_body', 'attachment'])
    stopWritingNewComment({ postId: payload.post.id })
    surfaceCommentAttachments.removeNewCommentAttachmentState({ postCid: payload.post.cid })
  }

  function deleteComment(payload: { commentId: CommentId }): void {
    void surfaceVuexStore?.dispatch('comment/deleteComment', payload)
  }

  function newCommentRemote(payload: { comment: Comment }): void {
    void surfaceVuexStore?.dispatch('comment/newCommentRemote', payload)
    remoteComments.value = [...remoteComments.value, payload.comment]
  }

  function editCommentRemote(payload: { comment: Comment }): void {
    void surfaceVuexStore?.dispatch('comment/editCommentRemote', payload)
    remoteComments.value = remoteComments.value.map((comment) =>
      comment.id === payload.comment.id ? payload.comment : comment,
    )
  }

  function deleteCommentRemote(payload: { comment: Comment }): void {
    void surfaceVuexStore?.dispatch('comment/deleteCommentRemote', payload)
    remoteComments.value = remoteComments.value.filter((comment) => comment.id !== payload.comment.id)
  }

  function updateInlineCommentInputLastTypedInMs(): void {
    void surfaceVuexStore?.dispatch('comment/updateInlineCommentInputLastTypedInMs')
  }

  /* ---------------------- */
  /* COMMENTS PANEL         */
  /* ---------------------- */
  const xCommentsPanel = computed<boolean>(() => surfaceVuexStore?.state.xCommentsPanel)
  const commentsPanelPostId = computed<Id>(() => surfaceVuexStore?.state.commentsPanelPostId)
  const hideCommentsPanel = (): void => {
    void surfaceVuexStore?.dispatch('hideCommentsPanel')
  }

  return {
    commentIds,
    commentIdsByPost,
    commentBeingEditedId,
    commentsByPostId,
    isCommenting,
    inlineCommentInputLastTypedInMs,
    commentEntities,
    commentUnderActionId,
    activeNewCommentPostId,
    xCommentsPanel,
    commentsPanelPostId,
    unapprovedComments,
    commentsBeingEditedSet,
    hasCommentBeingEdited,
    isCommentBeingEdited,
    remoteComments,
    // New/draft comments
    newCommentByPostCid,
    isAddingNewComment,
    isUploadingAttachmentForNewComment,
    newCommentBodyForPostCid,
    newCommentAttachmentForPostCid,
    newCommentUploadingFileForPostCid,
    newCommentUploadingProgressForPostCid,
    resetComments,
    updateNewCommentBody,
    updateNewCommentAttachment,
    updateNewCommentUploadingFile,
    updateNewCommentUploadingFileProgress,
    removeUploadingFileForNewComment,
    removeAttachmentForNewComment,

    // Comments being edited
    editedCommentById,
    latestCommentBeingEdited,
    isUploadingAttachmentForEditedComment,
    updateEditedDraftCommentBody,
    updateEditedDraftCommentAttachment,
    updateEditedDraftCommentUploadingFile,
    updateEditedDraftCommentUploadingFileProgress,
    removeUploadingFileForEditedComment,
    removeAttachmentForEditedComment,
    editedDraftCommentBodyFromId,
    editedDraftCommentAttachmentFromId,
    editedDraftCommentUploadingFileFromId,
    editedDraftCommentUploadingProgressFromId,

    // Actions
    createCommentV2,
    startEditingCommentV2,
    saveEditComment,
    editCommentV2,
    approveComment,
    rejectComment,
    askToDeleteComment,
    fetchComments,
    refreshComments,
    showCommentActionMenu,
    hideCommentActionMenu,
    stopEditingComment,
    startWritingNewComment,
    stopWritingNewComment,
    resetWritingNewComment,
    deleteComment,
    showSelfHarmCommentAlert,
    newCommentRemote,
    editCommentRemote,
    deleteCommentRemote,
    updateInlineCommentInputLastTypedInMs,
    hideCommentsPanel,
  }
})

// Use independent composable that dependents on multiple stores,
// instead of having circular dependencies between these stores.
export function useCommentEditMode(): {
  shouldEditCommentsInModal: ComputedRef<boolean>
} {
  const surfaceContainerSizeStore = useSurfaceContainerSizeStore()
  const commentsStore = useCommentsStore()
  const expandedPostStore = useExpandedPostStore()

  const shouldEditCommentsInModal = computed(() => {
    return (
      surfaceContainerSizeStore.isPhone &&
      ((commentsStore.isAddingNewComment && !expandedPostStore.xExpandedPostPanel) ||
        commentsStore.hasCommentBeingEdited)
    )
  })
  return { shouldEditCommentsInModal }
}
