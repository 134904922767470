// @file Composable used by pinia surface store (see pinia/surface.ts). State and actions related to freezing padlets.
import { captureFetchException } from '@@/bits/error_tracker'
import PromiseQueue from '@@/bits/promise_queue'
import { useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import { useSurfaceSettingsStore } from '@@/pinia/surface_settings'
import { useSurfaceShareLinksStore } from '@@/pinia/surface_share_links'
import { WallApi } from '@@/surface/api/wall'
import type { Wall } from '@padlet/arvo'
import type { ComputedRef } from 'vue'
import { computed } from 'vue'
import type { Store as VuexStore } from 'vuex'

const freezePadletOperationQueue = new PromiseQueue()
export function useFreezePadlet(
  wallAttributes: ComputedRef<Partial<Wall>>,
  surfaceVuexStore: VuexStore<any> | undefined,
): {
  isFrozen: ComputedRef<boolean>
  handlePadletFrozen: () => void
  handlePadletUnfrozen: () => void
  toggleFreezePadlet: () => Promise<void>
} {
  const globalSnackbarStore = useGlobalSnackbarStore()
  const surfaceShareLinksStore = useSurfaceShareLinksStore()
  const surfaceSettingsStore = useSurfaceSettingsStore()

  const isFrozen = computed<boolean>(() => wallAttributes.value.frozen === true)

  function setFrozenState(state: boolean): void {
    void surfaceVuexStore?.dispatch('updateWall', { frozen: state })
  }

  function freezePadletSideEffects(): void {
    if (wallAttributes.value.viz !== 'whiteboard') {
      void surfaceShareLinksStore.handleSectionBreakoutDisabled()
      void surfaceShareLinksStore.handleSubmissionRequestUpdated() // TODO: change this to just disable submission request
      surfaceSettingsStore.disableCommentsAndReactions()
    }
  }

  function unfreezePadletSideEffects(): void {
    if (wallAttributes.value.viz !== 'whiteboard') {
      void surfaceShareLinksStore.handleSectionBreakoutUpdated()
      void surfaceShareLinksStore.handleSubmissionRequestUpdated()
      void surfaceSettingsStore.fetchCommentAndReactionSettings()
    }
  }

  // triggered by external source, eg realtime
  function handlePadletFrozen(): void {
    setFrozenState(true)
    freezePadletSideEffects()
  }

  // triggered by external source, eg realtime
  function handlePadletUnfrozen(): void {
    setFrozenState(false)
    unfreezePadletSideEffects()
  }

  // triggered by user clicking on the freeze padlet toggle, using a promise queue
  async function toggleFreezePadlet(): Promise<void> {
    const { id: wallId } = wallAttributes.value
    const isCurrentlyFrozen = isFrozen.value

    if (wallId == null || isCurrentlyFrozen == null) return

    // optimistically update isFrozen attribute first, then enqueue
    setFrozenState(!isCurrentlyFrozen)

    const unfreezeOperation = async (): Promise<void> => {
      await WallApi.unfreeze(wallId)
      unfreezePadletSideEffects()
    }

    const freezeOperation = async (): Promise<void> => {
      await WallApi.freeze(wallId)
      freezePadletSideEffects()
    }

    const operation: () => Promise<void> = (() => {
      const callApi: () => Promise<void> = isCurrentlyFrozen ? unfreezeOperation : freezeOperation

      return async () => {
        try {
          await callApi()
        } catch (err) {
          freezePadletOperationQueue.clear()
          globalSnackbarStore.genericFetchError()
          captureFetchException(err, { source: 'surfaceToggleFreezePadlet' })
          setFrozenState(isCurrentlyFrozen)
        }
      }
    })()

    void freezePadletOperationQueue.enqueue('toggleFreezePadlet', operation)
  }
  return {
    isFrozen,
    handlePadletFrozen,
    handlePadletUnfrozen,
    toggleFreezePadlet,
  }
}
