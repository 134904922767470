// @file Network requests related to Wall User Contributor from surface
/* eslint-disable no-return-await, no-template-curly-in-string */
import FetchableObject from '@@/surface/api/fetchable_object'
import type { JsonAPIResponse, User as UserType } from '@padlet/arvo'
import { fetchUser } from '@padlet/arvo'

const API_VERSION = 5

class UserContributorApi extends FetchableObject {
  public static get url(): string {
    return `/api/${API_VERSION}/users`
  }

  public static async read(userId: number | string): Promise<JsonAPIResponse<UserType>> {
    return await fetchUser(userId)
  }
}

export { UserContributorApi }
