// @file Whiteboard tools helpers
import { getWhiteboardContentSources, type ContentPickerSource, type ContentSource } from '@@/bits/content_picker'
import { isAppUsing } from '@@/bits/flip'
import { __ } from '@@/bits/intl'
import { getEditorObject } from '@@/bits/tldraw/editor'
import type { WhiteboardTool } from '@@/types/whiteboard'
import type { PadletSpeechBubbleShape } from '@padlet/whiteboard-schema'
import type { TLGeoShape, TLGeoShapeGeoStyle } from 'tldraw'

// #region Tool settings
interface WhiteboardToolSetting {
  id: string
  text: string
  ariaLabel: string
  icon: string
}

interface WhiteboardToolSettingEntry extends WhiteboardToolSetting {
  isEnabled: boolean
  iconColor?: ContentSource['color']
}

const TLDRAW_TOOLS_IN_SETTINGS: WhiteboardToolSetting[] = [
  {
    id: 'laser',
    text: __('Pointer'),
    ariaLabel: __('Toggle ‘Pointer’ tool'),
    icon: 'laser_pointer',
  },
  {
    id: 'draw-nested',
    text: __('Draw'),
    ariaLabel: __('Toggle ‘Draw’ tool'),
    icon: 'swiggly_line',
  },
  {
    id: 'text',
    text: __('Text'),
    ariaLabel: __('Toggle ‘Text’ tool'),
    icon: 'text',
  },
  {
    id: 'note',
    text: __('Note'),
    ariaLabel: __('Toggle ‘Note’ tool'),
    icon: 'postnote',
  },
  {
    id: 'geo',
    text: __('Shape'),
    ariaLabel: __('Toggle ‘Shape’ tool'),
    icon: 'geo_star',
  },
  {
    id: 'arrow',
    text: __('Connector'),
    ariaLabel: __('Toggle ‘Connector’ tool'),
    icon: 'solid_single_up_right',
  },
].filter(Boolean) as WhiteboardToolSetting[]

const TLDRAW_TOOL_IDS = TLDRAW_TOOLS_IN_SETTINGS.map((tool) => tool.id)

const PADLET_TOOL_IDS_WITHOUT_PREFIX = getWhiteboardContentSources().map((s) => s.type)

/**
 * Converts a tool ID to the format stored in the settings.
 * Specifically, we prepend `padlet-` to the tool ID if it is a Padlet tool
 * so that there's less of a chance of collision with TLDraw tool IDs.
 */
const toStoredToolId = (toolId: string): string => {
  let storedToolId = toolId
  if (PADLET_TOOL_IDS_WITHOUT_PREFIX.includes(toolId as ContentPickerSource)) {
    storedToolId = `padlet-${toolId}`
  }
  return storedToolId
}

const PADLET_TOOL_IDS = PADLET_TOOL_IDS_WITHOUT_PREFIX.map((id) => toStoredToolId(id))

/**
 * These tools are shown in the toolbar so we must map them from `getWhiteboardContentSources`
 * which already filters out unavailable tools (e.g. if the device doesn't support
 * screen recording).
 */
const AVAILABLE_PADLET_TOOLS: WhiteboardTool[] = getWhiteboardContentSources().map((s) => ({
  id: toStoredToolId(s.type),
  label: s.text,
  iconName: s.icon,
  tldrawToolId: '', // Padlet tools don't have tldrawToolId
}))

const isPadletTool = (toolId: string): boolean => {
  return PADLET_TOOL_IDS.includes(toolId)
}

/**
 * Converts a tool ID to a `ContentPickerSource`.
 * Returns `null` if the tool ID is not a Padlet tool.
 */
const toContentSource = (toolId: string): ContentPickerSource | null => {
  if (!isPadletTool(toolId)) return null
  return toolId.replace('padlet-', '') as ContentPickerSource
}
// #endregion

// #region Tool definitions
const CONNECTOR_COLORS = [
  'park-500',
  'oceanic-500',
  'dark-text-100',
  'canary-500',
  'grape-500',
  'light-text-100',
] as const
const HIGHLIGHTER_COLORS = ['neon-pink', 'neon-orange', 'neon-yellow', 'neon-green', 'neon-blue', 'grey-200'] as const
const TEXT_COLORS = ['scarlet-500', 'canary-500', 'park-500', 'oceanic-500', 'dark-text-100', 'light-text-100'] as const
const DRAW_COLORS = ['scarlet-500', 'canary-500', 'park-500', 'oceanic-500', 'dark-text-100', 'light-text-100'] as const
const NOTES_COLORS = ['scarlet-200', 'canary-200', 'park-200', 'oceanic-200', 'grape-200', 'light-ui-300'] as const
const SHAPE_BORDER_COLORS = [
  'scarlet-500', // red
  'tangerine-600', //  orange
  'canary-600', // yellow
  'oceanic-400', // blue
  'teal-500', //  teal
  'park-500', // green
  'whiteboard-shape-border-grape', //  purple
  'light-text-100', // white
  'dark-text-100', // black
] as const

const SHAPE_BORDER_COLORS_UNUSED = [
  'park-600', // Old green (light-green)
] as const

const SHAPE_FILL_COLORS = [
  'scarlet-100', // red
  'tangerine-100', // orange
  'canary-100', // yellow
  'whiteboard-shape-fill-park', // green
  'teal-100', // teal
  'oceanic-100', // blue
  'grape-100', // purple
  'grey-200', // black
  'grey-50', // white
] as const

const SHAPE_BORDER_TO_FAKE_FILL_COLOR_MAP: Record<typeof SHAPE_BORDER_COLORS[number], string> = {
  'scarlet-500': 'scarlet-200', // red
  'tangerine-600': 'tangerine-200', // orange
  'canary-600': 'canary-200', // yellow
  'park-500': 'whiteboard-shape-fill-park', // green
  'teal-500': 'teal-200', // teal
  'oceanic-400': 'oceanic-200', // blue
  'whiteboard-shape-border-grape': 'grape-200', // purple
  'dark-text-100': 'grey-400', // black
  'light-text-100': 'grey-200', // white
}

const SHAPE_BORDER_TO_FAKE_BORDER_COLOR_MAP: Partial<Record<typeof SHAPE_BORDER_COLORS[number], string>> = {
  'whiteboard-shape-border-grape': 'grape-500', // purple
}

const DEFAULT_COLORS = {
  highlighter: HIGHLIGHTER_COLORS[2],
  text: TEXT_COLORS[4],
  draw: DRAW_COLORS[4],
  note: NOTES_COLORS[1],
  shapeBorder: SHAPE_BORDER_COLORS[4],
  connector: CONNECTOR_COLORS[1],
} as const

type ToolTailwindColorType =
  | typeof HIGHLIGHTER_COLORS[number]
  | typeof TEXT_COLORS[number]
  | typeof DRAW_COLORS[number]
  | typeof NOTES_COLORS[number]
  | typeof CONNECTOR_COLORS[number]
  | typeof SHAPE_BORDER_COLORS[number]
  | typeof SHAPE_FILL_COLORS[number]
  | typeof SHAPE_BORDER_COLORS_UNUSED[number]

type AllToolTailwindColorListType =
  | typeof HIGHLIGHTER_COLORS
  | typeof TEXT_COLORS
  | typeof DRAW_COLORS
  | typeof NOTES_COLORS
  | typeof CONNECTOR_COLORS
  | typeof SHAPE_BORDER_COLORS
  | typeof SHAPE_FILL_COLORS
  | typeof SHAPE_BORDER_COLORS_UNUSED

type TldrawColorVariantType = 'highlight' | 'solid' | 'note' | 'semi'

// @see https://github.com/tldraw/tldraw/blob/master/packages/tlschema/src/styles/TLColorStyle.ts
type TldrawDefaultColorStyleColor =
  | 'black'
  | 'grey'
  | 'light-violet'
  | 'violet'
  | 'blue'
  | 'light-blue'
  | 'yellow'
  | 'orange'
  | 'green'
  | 'light-green'
  | 'light-red'
  | 'red'
  | 'white'

// Highlighter colors
const HIGHLIGHT_COLOR_MAP: Record<typeof HIGHLIGHTER_COLORS[number], TldrawDefaultColorStyleColor> = {
  'neon-pink': 'light-violet',
  'neon-yellow': 'yellow',
  'neon-orange': 'orange',
  'neon-green': 'light-green',
  'neon-blue': 'light-blue',
  'grey-200': 'grey',
}

// Text colors
// Draw colors
// Shape border colors
const SOLID_COLOR_MAP: Record<
  | typeof TEXT_COLORS[number]
  | typeof DRAW_COLORS[number]
  | typeof CONNECTOR_COLORS[number]
  | typeof SHAPE_BORDER_COLORS[number]
  | typeof SHAPE_BORDER_COLORS_UNUSED[number],
  TldrawDefaultColorStyleColor
> = {
  'grape-500': 'violet',
  'scarlet-500': 'red', //        draw, text, shape: red
  'tangerine-600': 'orange', //   shape: orange
  'canary-500': 'light-red', //   draw, text: yellow // Use light-red for canary-500 because we have 2 shades of yellow and there is no light-yellow in tldraw
  'canary-600': 'yellow', //      shape: yellow
  'park-500': 'light-green', //         draw, text, shape: green
  'park-600': 'green', //   shape: light-green
  'teal-500': 'light-violet', //          shape: teal
  'oceanic-500': 'blue', //       draw, text: blue
  'oceanic-400': 'light-blue', // shape: blue
  'dark-text-100': 'black', //    draw, text, shape: black
  'light-text-100': 'white', //   draw, text, shape: white
  'whiteboard-shape-border-grape': 'violet', //       shape: purple
}

// Note colors
const NOTE_COLOR_MAP: Record<typeof NOTES_COLORS[number], TldrawDefaultColorStyleColor> = {
  'scarlet-200': 'light-red',
  'canary-200': 'yellow',
  'park-200': 'light-green',
  'oceanic-200': 'light-blue',
  'grape-200': 'violet',
  'light-ui-300': 'grey',
}

// Shape fill colors
const SEMI_COLOR_MAP: Record<typeof SHAPE_FILL_COLORS[number], TldrawDefaultColorStyleColor> = {
  'scarlet-100': 'red',
  'tangerine-100': 'orange',
  'canary-100': 'yellow',
  'whiteboard-shape-fill-park': 'light-green',
  'teal-100': 'light-violet',
  'oceanic-100': 'light-blue',
  'grape-100': 'violet',
  'grey-200': 'black',
  'grey-50': 'white',
}

const TOOL_COLOR_TO_TLDRAW_DEFAULT_COLOR_STYLE_COLOR: Record<
  TldrawColorVariantType,
  Partial<Record<ToolTailwindColorType, TldrawDefaultColorStyleColor>>
> = {
  highlight: HIGHLIGHT_COLOR_MAP,
  solid: SOLID_COLOR_MAP,
  note: NOTE_COLOR_MAP,
  semi: SEMI_COLOR_MAP,
}

// Only the cone currently in use
type TldrawGeoShapeGeoStyle =
  | 'ellipse'
  | 'rectangle'
  | 'cloud'
  | 'oval'
  | 'arrow-right'
  | 'star'
  | 'heart'
  | 'triangle'
  | 'diamond'
  | 'rhombus'
  | 'hexagon'

/**
 * Currently, tldraw does not support hex colors, so we need to map our colors to the closest tldraw default color style color
 * This is a temporary solution until we patch tldraw to support hex colors or tldraw adds support for custom colors
 **/
function closestTldrawDefaultColorStyleColor(
  toolId: string,
  toolColor: ToolTailwindColorType,
): TldrawDefaultColorStyleColor {
  let colorCategory: TldrawColorVariantType = 'solid'
  switch (toolId) {
    case 'highlight':
      colorCategory = 'highlight'
      break
    case 'text':
    case 'draw':
    case 'shape':
    case 'connector':
      colorCategory = 'solid'
      break
    case 'note':
      colorCategory = 'note'
      break
    default:
      break
  }
  const nonTldrawColors = ['default', 'dark-text-100']
  if (nonTldrawColors.includes(toolColor)) {
    if (toolId === 'note') {
      return 'grey'
    }
    return 'black'
  }
  return TOOL_COLOR_TO_TLDRAW_DEFAULT_COLOR_STYLE_COLOR[colorCategory][toolColor] as TldrawDefaultColorStyleColor
}

function createSecondaryMenuFromColors(toolId: string, colors: AllToolTailwindColorListType): WhiteboardTool[] {
  return colors.map((color: ToolTailwindColorType) => ({
    label: '', // TODO: Add color name for accessibility label
    iconName: 'color',
    id: color,
    // tldrawToolId: tailwindFlattenedColors[color], // TODO: Patch tldraw to support hex colors
    tldrawToolId: closestTldrawDefaultColorStyleColor(toolId, color),
  }))
}

const geoToolColors: WhiteboardTool[] = SHAPE_BORDER_COLORS.map((color) => ({
  label: '', // TODO: Add color name for accessibility label
  iconName: 'color',
  id: color,
  tldrawToolId: closestTldrawDefaultColorStyleColor('geo', color),
}))

const arrowToolColors: WhiteboardTool[] = createSecondaryMenuFromColors('arrow', CONNECTOR_COLORS)

const DRAW_TOOL: WhiteboardTool = {
  label: __('Draw'),
  shortcut: 'D',
  iconName: 'pen',
  id: 'draw-1',
  tldrawToolId: 'draw',
  secondaryMenu: {
    id: 'color',
    items: createSecondaryMenuFromColors('draw', DRAW_COLORS),
    defaultSelection: DEFAULT_COLORS.draw,
  },
}

const HIGHLIGHT_TOOL: WhiteboardTool = {
  label: __('Highlight'),
  shortcut: 'H',
  iconName: 'highlighter',
  id: 'highlight',
  tldrawToolId: 'highlight',
  secondaryMenu: {
    id: 'color',
    items: createSecondaryMenuFromColors('highlight', HIGHLIGHTER_COLORS),
    defaultSelection: DEFAULT_COLORS.highlighter,
  },
}

const ERASER_TOOL: WhiteboardTool = {
  label: __('Eraser'),
  shortcut: 'E',
  iconName: 'eraser',
  id: 'eraser',
  tldrawToolId: 'padlet-draw-eraser',
}

/**
 * All tools available in the whiteboard
 * NOTE: When you add a new primary Tldraw tool here, please also add it to
 * `bits/whiteboard_tools#TLDRAW_TOOLS_IN_SETTINGS` so it can be
 * toggled in the Allowed tools setting panel.
 */
const getAllTools = (): WhiteboardTool[] => [
  {
    label: __('Select'),
    shortcut: 'V',
    iconName: 'cursor',
    id: 'select',
    tldrawToolId: 'select',
  },
  {
    label: __('Interact'),
    shortcut: 'I',
    iconName: 'pointer',
    id: 'interact',
    tldrawToolId: 'interact',
  },
  {
    label: __('Draw'),
    iconName: 'draw-nested',
    id: 'draw-nested',
    tldrawToolId: 'draw-nested',
    secondaryMenu: {
      id: 'draw-tool',
      items: [DRAW_TOOL, HIGHLIGHT_TOOL, ERASER_TOOL],
      defaultSelection: DRAW_TOOL.id,
    },
  },
  {
    label: __('Pointer'),
    shortcut: 'P',
    iconName: 'laser_pointer',
    id: 'laser',
    tldrawToolId: 'laser',
  },
  // Include them here to allow selectedTool to be set to them and for toolStates object
  // they are not in toolSections so they won't be displayed in the toolbar
  { ...DRAW_TOOL, shortcut: '' },
  { ...HIGHLIGHT_TOOL, shortcut: '' },
  { ...ERASER_TOOL, shortcut: '' },

  {
    label: 'Geometry',
    iconName: 'geo',
    id: 'geo',
    tldrawToolId: 'geo',
    secondaryMenu: {
      id: 'geo-shape',
      items: [
        {
          label: __('Ellipse'),
          shortcut: 'O',
          iconName: 'geo_ellipse',
          id: 'ellipse',
          tldrawToolId: 'ellipse',
        },
        {
          label: __('Rectangle'),
          shortcut: 'R',
          iconName: 'geo_rectangle',
          id: 'rectangle',
          tldrawToolId: 'rectangle',
        },
        {
          label: __('Cloud'),
          iconName: 'geo_cloud',
          id: 'cloud',
          tldrawToolId: 'cloud',
        },
        {
          label: __('Oval'),
          iconName: 'geo_oval',
          id: 'oval',
          tldrawToolId: 'oval',
        },
        {
          label: __('Arrow'),
          iconName: 'geo_arrow_right',
          id: 'arrow-right',
          tldrawToolId: 'arrow-right',
        },
        {
          label: __('Star'),
          iconName: 'geo_star',
          id: 'star',
          tldrawToolId: 'star',
        },
        {
          label: __('Heart'),
          iconName: 'geo_heart',
          id: 'heart',
          tldrawToolId: 'heart',
        },
        ...(isAppUsing('tldrawShapesV2')
          ? [
              {
                label: __('Triangle'),
                iconName: 'geo_triangle',
                id: 'triangle',
                tldrawToolId: 'triangle',
              },
              {
                label: __('Diamond'),
                iconName: 'geo_diamond',
                id: 'diamond',
                tldrawToolId: 'diamond',
              },
              {
                label: __('Parallelogram'),
                iconName: 'geo_parallelogram',
                id: 'rhombus',
                tldrawToolId: 'rhombus',
              },
              {
                label: __('Hexagon'),
                iconName: 'geo_hexagon',
                id: 'hexagon',
                tldrawToolId: 'hexagon',
              },
              {
                label: __('Speech Bubble'),
                iconName: 'geo_speech',
                id: 'padlet-speech-bubble',
                tldrawToolId: 'padlet-speech-bubble',
              },
            ]
          : []),
      ],
      defaultSelection: 'rectangle',
    },
  },
  {
    label: __('Note'),
    shortcut: 'N',
    iconName: 'postnote',
    id: 'note',
    tldrawToolId: 'note',
    secondaryMenu: {
      id: 'color',
      items: createSecondaryMenuFromColors('note', NOTES_COLORS),
      defaultSelection: DEFAULT_COLORS.note,
    },
  },
  {
    label: __('Text'),
    shortcut: 'T',
    iconName: 'text',
    id: 'text',
    tldrawToolId: 'text',
    secondaryMenu: {
      id: 'color',
      items: createSecondaryMenuFromColors('text', TEXT_COLORS),
      defaultSelection: DEFAULT_COLORS.text,
    },
  },
  {
    label: __('Connector'),
    shortcut: 'X',
    iconName: 'solid_single_up_right',
    id: 'arrow',
    tldrawToolId: 'arrow',
    secondaryMenu: {
      id: 'connector',
      items: [
        {
          label: 'Double solid',
          iconName: 'solid_double',
          id: 'arrow-draw-arrow',
          tldrawToolId: 'arrow-draw-arrow',
        },
        {
          label: 'Single solid',
          iconName: 'solid_single',
          id: 'none-draw-arrow',
          tldrawToolId: 'none-draw-arrow',
        },
        {
          label: 'None solid',
          iconName: 'solid_none',
          id: 'none-draw-none',
          tldrawToolId: 'none-draw-none',
        },
        {
          label: 'Double dashed',
          iconName: 'dash_double',
          id: 'arrow-dashed-arrow',
          tldrawToolId: 'arrow-dashed-arrow',
        },
        {
          label: 'Single dashed',
          iconName: 'dash_single',
          id: 'none-dashed-arrow',
          tldrawToolId: 'none-dashed-arrow',
        },
        {
          label: 'None dashed',
          iconName: 'dash_none',
          id: 'none-dashed-none',
          tldrawToolId: 'none-dashed-none',
        },
      ],
      defaultSelection: 'none-draw-arrow',
    },
  },
  {
    label: __('Attachment'),
    iconName: 'attachment',
    id: 'padlet-attachment',
    tldrawToolId: 'padlet-attachment',
  },

  {
    label: __('Undo'),
    shortcut: '',
    iconName: 'undo',
    id: 'undo',
    tldrawToolId: 'undo',
  },
  {
    label: __('Redo'),
    shortcut: '',
    iconName: 'redo',
    id: 'redo',
    tldrawToolId: 'redo',
  },

  ...(isAppUsing('sandboxAllowedTools') ? AVAILABLE_PADLET_TOOLS : []),
]

const getAllToolsAndSecondaryTools = (): WhiteboardTool[] => {
  const allTools = getAllTools()
  return allTools.concat(
    allTools.filter((tool) => tool.secondaryMenu != null).flatMap((tool) => tool.secondaryMenu?.items ?? []),
  )
}

function getTldrawToolId(toolId: string): string | undefined {
  return getAllToolsAndSecondaryTools().find((tool) => tool.id === toolId)?.tldrawToolId
}

// Used to display menu of GeoStyles that can be applied to a GeoShape element
const geoShapeGeoStyles: WhiteboardTool[] =
  getAllTools()
    .filter((tool) => tool.id === 'geo')[0]
    .secondaryMenu?.items.map((item) => ({
      label: item.label,
      /* Unlike the toolbar, the element modifier uses expanded versions of the geo shape icons. 
    The only expansion is the square icon, which isn't expanded fully so that it can fit nicely within the 28px circle background of buttons 
    We might eventually want to use these expanded icons for the toolbar as well 
    */
      iconName: item.iconName + '_expanded',
      id: item.id,
      tldrawToolId: item.tldrawToolId,
    })) ?? []
function getGeoShapeGeoStyles(): WhiteboardTool[] {
  const padletSpeechBubbleTool = getAllTools().find((tool) => tool.id === 'padlet-speech-bubble')
  const menuItems = [
    ...(getAllTools().filter((tool) => tool.id === 'geo')[0].secondaryMenu?.items ?? []),
    ...(padletSpeechBubbleTool != null ? [padletSpeechBubbleTool] : []),
  ]
  return (
    menuItems.map((item) => ({
      label: item.label,
      /* Unlike the toolbar, the element modifier uses expanded versions of the geo shape icons. 
        The only expansion is the square icon, which isn't expanded fully so that it can fit nicely within the 28px circle background of buttons 
        We might eventually want to use these expanded icons for the toolbar as well 
        */
      iconName: item.iconName + '_expanded',
      id: item.id,
      tldrawToolId: item.tldrawToolId,
    })) ?? []
  )
}
// #endregion

function createPadletSpeechBubbleShapeFromGeoShape(geoShape: TLGeoShape): void {
  const tldrawEditor = getEditorObject()
  tldrawEditor?.createShape({
    type: 'padlet-speech-bubble',
    index: geoShape.index,
    x: geoShape.x,
    y: geoShape.y,
    opacity: geoShape.opacity,
    rotation: geoShape.rotation,
    isLocked: geoShape.isLocked,
    props: {
      w: geoShape.props.w,
      h: geoShape.props.h,
      color: geoShape.props.color,
      size: geoShape.props.size,
      font: geoShape.props.font,
      align: geoShape.props.align,
      verticalAlign: geoShape.props.verticalAlign,
      growY: geoShape.props.growY,
      text: geoShape.props.text,
      scale: geoShape.props.scale,
      url: geoShape.props.url,
      fill: geoShape.props.fill,
      dash: geoShape.props.dash,
      tail: { x: 0.5, y: 1.5 },
    },
    meta: {
      ...geoShape.meta,
      updatingShapeId: geoShape.id,
    },
  })
}

function createGeoShapeFromPadletSpeechBubbleShape({
  padletSpeechBubbleShape,
  geoStyle,
}: {
  padletSpeechBubbleShape: PadletSpeechBubbleShape
  geoStyle: TLGeoShapeGeoStyle
}): void {
  const tldrawEditor = getEditorObject()
  tldrawEditor?.createShape({
    type: 'geo',
    index: padletSpeechBubbleShape.index,
    x: padletSpeechBubbleShape.x,
    y: padletSpeechBubbleShape.y,
    opacity: padletSpeechBubbleShape.opacity,
    rotation: padletSpeechBubbleShape.rotation,
    isLocked: padletSpeechBubbleShape.isLocked,
    props: {
      w: padletSpeechBubbleShape.props.w,
      h: padletSpeechBubbleShape.props.h,
      color: padletSpeechBubbleShape.props.color,
      size: padletSpeechBubbleShape.props.size,
      font: padletSpeechBubbleShape.props.font,
      align: padletSpeechBubbleShape.props.align,
      verticalAlign: padletSpeechBubbleShape.props.verticalAlign,
      growY: padletSpeechBubbleShape.props.growY,
      text: padletSpeechBubbleShape.props.text,
      scale: padletSpeechBubbleShape.props.scale,
      url: padletSpeechBubbleShape.props.url,
      fill: padletSpeechBubbleShape.props.fill,
      dash: padletSpeechBubbleShape.props.dash,
      geo: geoStyle,
    },
    meta: {
      ...padletSpeechBubbleShape.meta,
      updatingShapeId: padletSpeechBubbleShape.id,
    },
  })
}

// #endregion
export {
  arrowToolColors,
  AVAILABLE_PADLET_TOOLS,
  CONNECTOR_COLORS,
  createGeoShapeFromPadletSpeechBubbleShape,
  createPadletSpeechBubbleShapeFromGeoShape,
  DEFAULT_COLORS,
  geoShapeGeoStyles,
  geoToolColors,
  getAllTools,
  getGeoShapeGeoStyles,
  getTldrawToolId,
  isPadletTool,
  PADLET_TOOL_IDS,
  PADLET_TOOL_IDS_WITHOUT_PREFIX,
  SHAPE_BORDER_TO_FAKE_BORDER_COLOR_MAP,
  SHAPE_BORDER_TO_FAKE_FILL_COLOR_MAP,
  TLDRAW_TOOL_IDS,
  TLDRAW_TOOLS_IN_SETTINGS,
  toContentSource,
  TOOL_COLOR_TO_TLDRAW_DEFAULT_COLOR_STYLE_COLOR,
  toStoredToolId,
}
export type {
  TldrawColorVariantType,
  TldrawDefaultColorStyleColor,
  TldrawGeoShapeGeoStyle,
  ToolTailwindColorType,
  WhiteboardToolSettingEntry,
}
