// @file The store for handling the surface onboarding demo padlet panel and its related logic
import { trackEvent } from '@@/bits/analytics'
import { captureFetchException } from '@@/bits/error_tracker'
import { isAppUsing } from '@@/bits/flip'
import { __ } from '@@/bits/intl'
import { WallCreatedFromType } from '@@/enums'
import { usePostComposerModalStore } from '@@/pinia/post_composer_modal_store'
import { useSurfaceStore } from '@@/pinia/surface'
import { useSurfaceDemoPadletWindowSizeStore } from '@@/pinia/surface_demo_padlet_window_size_store'
import { useSurfaceOnboardingDemoPadletAnimationsStore } from '@@/pinia/surface_onboarding_demo_padlet_animations_store'
import { useSurfaceOnboardingDemoPadletPanelCopyStore } from '@@/pinia/surface_onboarding_demo_padlet_panel_copy_store'
import { useSurfacePostsStore } from '@@/pinia/surface_posts'
import { useSurfaceSectionsStore } from '@@/pinia/surface_sections'
import { useSurfaceStartingStateStore } from '@@/pinia/surface_starting_state'
import { useWindowSizeStore } from '@@/pinia/window_size'
import { WallOnboardingDemoApi } from '@@/surface/api/wall_onboarding_demo'
import { defineStore } from 'pinia'
import { computed, ref, watch } from 'vue'

interface HeaderContent {
  title: string
  number?: number
  icon?: string
}

interface DemoPadletPanelVisibility {
  showSurfaceActionBar: boolean
  // Note: showSurfaceActionBarButtons does not include the OzAvatar at the top of the SurfaceSearchBar
  showSurfaceActionBarButtons: boolean
  showSurfaceAddPost: boolean
  showSurfaceSearchBar: boolean
  showModifiedSurfaceAddPostButton: boolean
  showSurfaceAiChat: boolean
  showDialogPermitNotification: boolean
  showSurfaceOpenInAppButton: boolean
  showSurfaceModifyGearIcon: boolean
  showModifiedSurfaceActionBarShareButton: boolean
  showModifiedSurfaceActionBarSettingsButton: boolean
  showModifiedSurfaceActionBarSlideshowButton: boolean
}

// Steps are arranging in the order they are displayed, note: 'Done' is not the last step
// TODO: ENG-17332 - Refactor this to use the enum from enums.ts
export enum DemoPadletPanelStep {
  Landing = 'landing',
  Topic = 'topic',
  Post = 'post',
  Share = 'share',
  Done = 'done',
  LearnMore = 'learnmore',
  ShareAndPrivacy = 'shareandprivacy',
  Personalize = 'personalize',
  PresentAsSlideshow = 'presentaslideshow',
}

// Even though the LearnMoreMethod has overlap with DemoPadletPanelStep we use this smaller enum to restrict options in handleShareRowClick on the LearnMore panel, also it's more explicit in case we want to add more options in the future
export enum LearnMoreMethod {
  ShareAndPrivacy = DemoPadletPanelStep.ShareAndPrivacy,
  Personalize = DemoPadletPanelStep.Personalize,
  PresentAsSlideshow = DemoPadletPanelStep.PresentAsSlideshow,
}

export enum StepStatus {
  NotStarted = 'notstarted',
  InProgress = 'inprogress',
  Complete = 'complete',
}

export const useSurfaceOnboardingDemoPadletPanelStore = defineStore('surfaceOnboardingDemoPadletPanelStore', () => {
  const surfaceStartingStateStore = useSurfaceStartingStateStore()
  const postComposerModalStore = usePostComposerModalStore()
  const surfacePostsStore = useSurfacePostsStore()
  const surfaceDemoPadletAnimationsStore = useSurfaceOnboardingDemoPadletAnimationsStore()
  const surfaceDemoPadletWindowSizeStore = useSurfaceDemoPadletWindowSizeStore()
  const surfaceStore = useSurfaceStore()
  const windowSizeStore = useWindowSizeStore()
  const surfaceDemoPadletPanelCopyStore = useSurfaceOnboardingDemoPadletPanelCopyStore()
  // ------------------------------
  // General
  // ------------------------------

  const isDemoPadletPanel = computed(() => {
    return (
      isAppUsing('demoPadletPanel') &&
      surfaceStartingStateStore.startingState.onboardingFlow?.wallCreatedFromType === WallCreatedFromType.demo &&
      // If the demo padlet is done, we don't want to show the panel, switching the activeStep to Done does not hide the panel
      surfaceStartingStateStore.startingState.onboardingFlow?.onboardingDemoCurrentStep !== DemoPadletPanelStep.Done
    )
  })
  const isDemoPadletPanelMobile = computed(() => {
    return isDemoPadletPanel.value && !surfaceDemoPadletWindowSizeStore.isDemoPadletPanelScreensize
  })
  const isDemoPadletPanelDesktop = computed(() => {
    // Remember this should evaluate to false when demo padlet onboarding is completed
    return isDemoPadletPanel.value && surfaceDemoPadletWindowSizeStore.isDemoPadletPanelScreensize
  })

  const initialStep = surfaceStartingStateStore.startingState?.onboardingFlow?.onboardingDemoCurrentStep
  // The activeStep controls which step panel that is displayed, the activeStepStatus tracks the progress of the active step
  // Note: While related, the current step on the backend and the active step on the frontend are not the same thing. The currentStep is used to save progress only at the Done step, the active step reflects the ongoing step in the onboarding flow.
  // We set the initial step to Landing if the current step is not set or if it's set to Done
  const activeStep = ref(
    initialStep === DemoPadletPanelStep.Landing || initialStep === DemoPadletPanelStep.Done
      ? initialStep
      : DemoPadletPanelStep.Landing,
  )
  const activeStepStatus = ref(StepStatus.NotStarted)
  function setActiveStepStatus(status: StepStatus): void {
    activeStepStatus.value = status
  }

  const isStepNotStarted = computed(() => {
    return activeStepStatus.value === StepStatus.NotStarted
  })
  const isStepInProgress = computed(() => {
    return activeStepStatus.value === StepStatus.InProgress
  })
  const isStepCompleted = computed(() => {
    return activeStepStatus.value === StepStatus.Complete
  })

  async function goToNextStep(learnMoreStepOption?: string): Promise<void> {
    // Every time we change steps we reset progress
    setActiveStepStatus(StepStatus.NotStarted)
    switch (activeStep.value) {
      case DemoPadletPanelStep.Landing:
        trackEvent('Demo padlet onboarding', 'Go to topic step')
        activeStep.value = DemoPadletPanelStep.Topic
        await updateOnboardingDemoCurrentStep(DemoPadletPanelStep.Topic)
        break
      case DemoPadletPanelStep.Topic:
        trackEvent('Demo padlet onboarding', 'Go to post step')
        activeStep.value = DemoPadletPanelStep.Post
        await updateOnboardingDemoCurrentStep(DemoPadletPanelStep.Post)
        break
      case DemoPadletPanelStep.Post:
        trackEvent('Demo padlet onboarding', 'Go to share step')
        activeStep.value = DemoPadletPanelStep.Share
        await updateOnboardingDemoCurrentStep(DemoPadletPanelStep.Share)
        break
      case DemoPadletPanelStep.Share:
        trackEvent('Demo padlet onboarding', 'Go to done step')
        activeStep.value = DemoPadletPanelStep.Done
        await updateOnboardingDemoCurrentStep(DemoPadletPanelStep.Done)
        break
      case DemoPadletPanelStep.Done:
        trackEvent('Demo padlet onboarding', 'Go to learn more step')
        activeStep.value = DemoPadletPanelStep.LearnMore
        // We don't save the current step beyond the Done step
        break
      // There are four buttons on the Learn More step, but since only one continues the flow we can maintain a linear progression
      case DemoPadletPanelStep.LearnMore:
        if (learnMoreStepOption === LearnMoreMethod.ShareAndPrivacy) {
          trackEvent('Demo padlet onboarding', 'Go to share and privacy step')
          activeStep.value = DemoPadletPanelStep.ShareAndPrivacy
        } else if (learnMoreStepOption === LearnMoreMethod.PresentAsSlideshow) {
          trackEvent('Demo padlet onboarding', 'Go to present as slideshow step')
          activeStep.value = DemoPadletPanelStep.PresentAsSlideshow
        } else if (learnMoreStepOption === LearnMoreMethod.Personalize) {
          trackEvent('Demo padlet onboarding', 'Go to personalize step')
          activeStep.value = DemoPadletPanelStep.Personalize
        }
        break
      default:
        activeStep.value = DemoPadletPanelStep.Landing
    }
  }

  const currentHeader = computed(() => getHeaderContent(activeStep.value))
  function getHeaderContent(step): HeaderContent {
    switch (step) {
      case DemoPadletPanelStep.Topic:
        return {
          title: __('Set a topic'),
          number: 1,
        }
      case DemoPadletPanelStep.Post:
        return {
          title: __('Post'),
          number: 2,
        }
      case DemoPadletPanelStep.Share:
        return {
          title: __('Share and collaborate'),
          number: 3,
        }
      case DemoPadletPanelStep.Done:
        return {
          title: __('Congratulations'),
          icon: 'firework_explosion',
        }
      default:
        return {
          title: '',
          number: 0,
        }
    }
  }

  // The main flow steps are the steps that can be saved to the backend, all other steps are considered "Done" and are not saved
  const MAIN_FLOW_STEPS = [
    DemoPadletPanelStep.Landing,
    DemoPadletPanelStep.Topic,
    DemoPadletPanelStep.Post,
    DemoPadletPanelStep.Share,
    DemoPadletPanelStep.Done,
  ]

  async function updateOnboardingDemoCurrentStep(newCurrentStep: DemoPadletPanelStep): Promise<void> {
    const wallId = surfaceStore.wallId
    const savedCurrentStep = surfaceStartingStateStore.startingState.onboardingFlow?.onboardingDemoCurrentStep

    // Only save main flow steps
    if (!MAIN_FLOW_STEPS.includes(newCurrentStep)) {
      return
    }

    // Only validate steps in the main flow
    if (savedCurrentStep) {
      const currentStepIndex = MAIN_FLOW_STEPS.indexOf(savedCurrentStep)
      const newStepIndex = MAIN_FLOW_STEPS.indexOf(newCurrentStep)

      // Don't update the current step with a past step
      if (newStepIndex <= currentStepIndex) {
        return
      }
    }

    try {
      await WallOnboardingDemoApi.updateCurrentStep(wallId, newCurrentStep)
    } catch (error) {
      captureFetchException(error, { source: 'SurfaceOnboardingDemoPadletPanelStoreUpdateOnboardingDemoCurrentStep' })
    }
  }

  async function setFavoritePlacesSectionAsMostRecentlyTouched(): Promise<void> {
    if (surfaceDemoPadletPanelCopyStore.postData[0]?.wallSectionId !== undefined) {
      await useSurfaceSectionsStore().updateMostRecentlyTouchedSection(
        surfaceDemoPadletPanelCopyStore.postData[0].wallSectionId,
      )
    }
  }

  // ------------------------------
  // Hiding UI
  // ------------------------------

  const demoPadletPanelVisibility = computed<DemoPadletPanelVisibility>(() => {
    // If the demo padlet panel is not desktop, we don't need to hide any UI elements so we show everything
    if (!isDemoPadletPanelDesktop.value) {
      return {
        showSurfaceActionBar: true,
        showSurfaceActionBarButtons: true,
        showSurfaceAddPost: true,
        showSurfaceSearchBar: true,
        showModifiedSurfaceAddPostButton: false,
        showSurfaceAiChat: true,
        showDialogPermitNotification: true,
        showSurfaceOpenInAppButton: true,
        showSurfaceModifyGearIcon: true,
        showModifiedSurfaceActionBarShareButton: false,
        showModifiedSurfaceActionBarSettingsButton: false,
        showModifiedSurfaceActionBarSlideshowButton: false,
      }
    }
    // Note when adding a new piece of UI, every step previous to the step it's shown should be hidden
    switch (activeStep.value) {
      case DemoPadletPanelStep.Landing:
        return {
          showSurfaceActionBar: false,
          showSurfaceActionBarButtons: false,
          showSurfaceAddPost: false,
          showSurfaceSearchBar: false,
          showModifiedSurfaceAddPostButton: false,
          showSurfaceAiChat: false,
          showDialogPermitNotification: false,
          showSurfaceOpenInAppButton: false,
          showSurfaceModifyGearIcon: false,
          showModifiedSurfaceActionBarShareButton: false,
          showModifiedSurfaceActionBarSettingsButton: false,
          showModifiedSurfaceActionBarSlideshowButton: false,
        }
      case DemoPadletPanelStep.Topic:
        return {
          showSurfaceActionBar: false,
          showSurfaceActionBarButtons: false,
          showSurfaceAddPost: false,
          showSurfaceSearchBar: false,
          showModifiedSurfaceAddPostButton: false,
          showSurfaceAiChat: false,
          showDialogPermitNotification: false,
          showSurfaceModifyGearIcon: false,
          showSurfaceOpenInAppButton: false,
          showModifiedSurfaceActionBarShareButton: false,
          showModifiedSurfaceActionBarSettingsButton: false,
          showModifiedSurfaceActionBarSlideshowButton: false,
        }
      case DemoPadletPanelStep.Post:
        return {
          showSurfaceActionBar: true,
          showSurfaceActionBarButtons: true,
          showSurfaceAddPost: !hasAddedPost.value,
          showSurfaceSearchBar: false,
          showModifiedSurfaceAddPostButton: false,
          showSurfaceAiChat: false,
          showDialogPermitNotification: true,
          showSurfaceOpenInAppButton: false,
          showSurfaceModifyGearIcon: false,
          showModifiedSurfaceActionBarShareButton: false,
          showModifiedSurfaceActionBarSettingsButton: false,
          showModifiedSurfaceActionBarSlideshowButton: false,
        }
      case DemoPadletPanelStep.Share:
        return {
          showSurfaceActionBar: true,
          showSurfaceActionBarButtons: true,
          showSurfaceAddPost: true,
          showSurfaceSearchBar: false,
          showModifiedSurfaceAddPostButton: false,
          showSurfaceAiChat: false,
          showDialogPermitNotification: true,
          showSurfaceOpenInAppButton: false,
          showSurfaceModifyGearIcon: false,
          showModifiedSurfaceActionBarShareButton: false,
          showModifiedSurfaceActionBarSettingsButton: false,
          showModifiedSurfaceActionBarSlideshowButton: false,
        }
      case DemoPadletPanelStep.Done:
        return {
          showSurfaceActionBar: true,
          showSurfaceActionBarButtons: true,
          showSurfaceAddPost: true,
          showSurfaceSearchBar: true,
          showSurfaceSearchBarButtons: true,
          showModifiedSurfaceAddPostButton: false,
          showSurfaceAiChat: false,
          showDialogPermitNotification: true,
          showSurfaceOpenInAppButton: false,
          showSurfaceModifyGearIcon: true,
          showModifiedSurfaceActionBarShareButton: false,
          showModifiedSurfaceActionBarSettingsButton: false,
          showModifiedSurfaceActionBarSlideshowButton: false,
        }
      case DemoPadletPanelStep.LearnMore:
        return {
          showSurfaceActionBar: true,
          showSurfaceActionBarButtons: true,
          showSurfaceAddPost: true,
          showSurfaceSearchBar: true,
          showSurfaceSearchBarButtons: true,
          showModifiedSurfaceAddPostButton: false,
          showSurfaceAiChat: false,
          showDialogPermitNotification: true,
          showSurfaceOpenInAppButton: false,
          showSurfaceModifyGearIcon: true,
          showModifiedSurfaceActionBarShareButton: false,
          showModifiedSurfaceActionBarSettingsButton: false,
          showModifiedSurfaceActionBarSlideshowButton: false,
        }
      case DemoPadletPanelStep.ShareAndPrivacy:
        return {
          showSurfaceActionBar: true,
          showSurfaceActionBarButtons: true,
          showSurfaceAddPost: true,
          showSurfaceSearchBar: true,
          showSurfaceSearchBarButtons: true,
          showModifiedSurfaceAddPostButton: false,
          showSurfaceAiChat: false,
          showDialogPermitNotification: true,
          showSurfaceOpenInAppButton: false,
          showSurfaceModifyGearIcon: true,
          showModifiedSurfaceActionBarShareButton: true,
          showModifiedSurfaceActionBarSettingsButton: false,
          showModifiedSurfaceActionBarSlideshowButton: false,
        }
      case DemoPadletPanelStep.Personalize:
        return {
          showSurfaceActionBar: true,
          showSurfaceActionBarButtons: true,
          showSurfaceAddPost: true,
          showSurfaceSearchBar: true,
          showSurfaceSearchBarButtons: true,
          showModifiedSurfaceAddPostButton: false,
          showSurfaceAiChat: false,
          showDialogPermitNotification: true,
          showSurfaceOpenInAppButton: false,
          showSurfaceModifyGearIcon: true,
          showModifiedSurfaceActionBarShareButton: false,
          showModifiedSurfaceActionBarSettingsButton: true,
          showModifiedSurfaceActionBarSlideshowButton: false,
        }
      case DemoPadletPanelStep.PresentAsSlideshow:
        return {
          showSurfaceActionBar: true,
          showSurfaceActionBarButtons: true,
          showSurfaceAddPost: true,
          showSurfaceSearchBar: true,
          showSurfaceSearchBarButtons: true,
          showModifiedSurfaceAddPostButton: false,
          showSurfaceAiChat: false,
          showDialogPermitNotification: true,
          showSurfaceOpenInAppButton: false,
          showSurfaceModifyGearIcon: true,
          showModifiedSurfaceActionBarShareButton: false,
          showModifiedSurfaceActionBarSettingsButton: false,
          // There's no slideshow button on the SurfaceActionBar at tablet and below sizes, so we don't need to show it
          showModifiedSurfaceActionBarSlideshowButton: !windowSizeStore.isSmallerThanDesktop,
        }
      // After the post step (anything done step and beyond) we show everything, thus we use the default case
      default:
        // By default we show everything
        return {
          showSurfaceActionBar: true,
          showSurfaceActionBarButtons: true,
          showSurfaceAddPost: true,
          showSurfaceSearchBar: true,
          // By default we don't show the modified SurfaceAddPostButton
          showModifiedSurfaceAddPostButton: false,
          showSurfaceAiChat: true,
          showDialogPermitNotification: true,
          showSurfaceOpenInAppButton: true,
          showSurfaceModifyGearIcon: true,
          showModifiedSurfaceActionBarShareButton: false,
          showModifiedSurfaceActionBarSettingsButton: false,
          showModifiedSurfaceActionBarSlideshowButton: false,
        }
    }
  })

  // ------------------------------
  // Landing step
  // ------------------------------

  const isLandingStep = computed(() => {
    return (
      // TODO: ENG-15161 Refactor to remove this temporary check against activeStep and only read values from surfaceStartingStateStore (this will be after the upserter is implemented)
      // using this line: surfaceStartingStateStore.startingState.onboardingFlow?.wallCreatedFromType === WallCreatedFromType.demo
      activeStep.value === DemoPadletPanelStep.Landing
    )
  })

  // ------------------------------
  // Topic step
  // ------------------------------

  const handleStartTopicAnimation = async (): Promise<void> => {
    await surfaceDemoPadletAnimationsStore.startTopicAnimation()
  }
  // ------------------------------
  // Post step
  // ------------------------------

  const isPostStep = computed(() => {
    return activeStep.value === DemoPadletPanelStep.Post
  })

  const hasAddedPost = computed(() => {
    return surfacePostsStore.currentPostsCount > 0
  })

  const handleStartPostAnimation = async (): Promise<void> => {
    await surfaceDemoPadletAnimationsStore.startPostAnimation()
  }

  // ------------------------------
  // Share step
  // ------------------------------

  const isShareStep = computed(() => {
    return activeStep.value === DemoPadletPanelStep.Share
  })

  const handleStartShareAnimation = (): void => {
    surfaceDemoPadletAnimationsStore.startShareAnimation()
  }

  watch(
    () => postComposerModalStore.hasDockedComposerModal,
    (hasDockedComposerModal: boolean) => {
      if (isPostStep.value) {
        if (hasDockedComposerModal && !isStepInProgress.value) {
          setActiveStepStatus(StepStatus.InProgress)
        } else if (!hasDockedComposerModal && !isStepCompleted.value) {
          setActiveStepStatus(StepStatus.NotStarted)
        }
      }
    },
  )

  // ------------------------------
  // Done step
  // ------------------------------
  const isDoneStep = computed(() => {
    return activeStep.value === DemoPadletPanelStep.Done
  })
  // ------------------------------
  // Learn more step
  // ------------------------------
  const isLearnMoreStep = computed(() => {
    return activeStep.value === DemoPadletPanelStep.LearnMore
  })

  // ------------------------------
  // Share and privacy step
  // ------------------------------
  const isShareAndPrivacyStep = computed(() => {
    return activeStep.value === DemoPadletPanelStep.ShareAndPrivacy
  })

  // ------------------------------
  // Present as slideshow step
  // ------------------------------
  const isPresentAsSlideshowStep = computed(() => {
    return activeStep.value === DemoPadletPanelStep.PresentAsSlideshow
  })

  // ------------------------------
  // Personalize step
  // ------------------------------
  const isPersonalizeStep = computed(() => {
    return activeStep.value === DemoPadletPanelStep.Personalize
  })

  function onLogoClick(): void {
    if (isDemoPadletPanelDesktop.value) {
      trackEvent('Demo padlet onboarding', 'Clicked padlet logo', activeStep.value)
    }
  }

  return {
    // ------------------------------
    // General
    // ------------------------------
    isDemoPadletPanel,
    isDemoPadletPanelMobile,
    isDemoPadletPanelDesktop,
    activeStep,
    activeStepStatus,
    setActiveStepStatus,
    isStepNotStarted,
    isStepInProgress,
    isStepCompleted,
    goToNextStep,
    getHeaderContent,
    currentHeader,
    updateOnboardingDemoCurrentStep,
    setFavoritePlacesSectionAsMostRecentlyTouched,
    onLogoClick,
    // ------------------------------
    // Hiding UI
    // ------------------------------
    demoPadletPanelVisibility,
    // ------------------------------
    // Landing step
    // ------------------------------
    isLandingStep,
    // ------------------------------
    // Topic step
    // ------------------------------
    handleStartTopicAnimation,
    // ------------------------------
    // Post step
    // ------------------------------
    isPostStep,
    hasAddedPost,
    handleStartPostAnimation,
    // ------------------------------
    // Share step
    // ------------------------------
    isShareStep,
    handleStartShareAnimation,
    // ------------------------------
    // Done step
    // ------------------------------
    isDoneStep,
    // ------------------------------
    // Learn more step
    // ------------------------------
    isLearnMoreStep,
    // ------------------------------
    // Share and privacy step
    // ------------------------------
    isShareAndPrivacyStep,
    // ------------------------------
    // Present as slideshow step
    // ------------------------------
    isPresentAsSlideshowStep,
    // ------------------------------
    // Personalize step
    // ------------------------------
    isPersonalizeStep,
  }
})
