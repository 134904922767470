// @file Collaboration settings on whiteboard
import { captureException } from '@@/bits/error_tracker'
import { ShownCursorType } from '@@/enums'
import { useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import { useSurfaceStore } from '@@/pinia/surface'
import { WhiteboardCollaborationSettingsApi } from '@@/surface/api/whiteboard_collaboration_settings'
import type { WhiteboardCollaborationSettings } from '@@/types'
import { defineStore } from 'pinia'
import { computed, readonly, ref } from 'vue'

export const useWhiteboardCollaborationStore = defineStore('whiteboardCollaboration', () => {
  const surfaceStore = useSurfaceStore()
  const globalSnackbarStore = useGlobalSnackbarStore()

  /**
   * Number of users on the same sandbox
   */
  const presenceCount = ref(1)

  /**
   * User setting for shown cursor type
   */
  const userSelectedShownCursorType = ref<ShownCursorType>(ShownCursorType.ALL)

  /**
   * Actual shown cursor type, could be affected by number of users in the same sandbox
   */
  const shownCursorType = computed(() => {
    if (presenceCount.value > 20) {
      return ShownCursorType.NONE
    }
    return userSelectedShownCursorType.value
  })

  /**
   * Set the collaboration settings for the whiteboard.
   * NOTE: Only use this when setting the initial state or from a real-time update.
   */
  function setCollaborationSettings(settings: WhiteboardCollaborationSettings): void {
    userSelectedShownCursorType.value = settings.collaboration_cursors
  }

  /**
   * Change the shown cursor setting, can be Never (NONE), When editing (TBA), or Always (ALL).
   */
  async function changeShownCursorType(type: ShownCursorType): Promise<void> {
    const previousShownCursorType = userSelectedShownCursorType.value

    // Preemptively update the value
    userSelectedShownCursorType.value = type

    try {
      const updated = await WhiteboardCollaborationSettingsApi.update(surfaceStore.wallHashid, {
        collaboration_cursors: type,
      })
      userSelectedShownCursorType.value = updated.collaboration_cursors
    } catch (e) {
      // There's an erorr, revert back to the previous value
      userSelectedShownCursorType.value = previousShownCursorType
      globalSnackbarStore.genericFetchError()
      captureException(e)
    }
  }

  return {
    presenceCount,
    userSelectedShownCursorType: readonly(userSelectedShownCursorType),
    shownCursorType,
    setCollaborationSettings,
    changeShownCursorType,
  }
})
