// @file Network requests related to user from surface

import FetchableObject from '@@/surface/api/fetchable_object'
import { fetchJson } from '@@/surface/api_fetch'
import type { UserLibrarySettingsApiResponse } from '@@/types'
import type { JsonAPIResponse } from '@padlet/arvo'
import { HTTPMethod } from '@padlet/fetch'

class UserApi extends FetchableObject {
  public static async fetchuserLibrarySettings(): Promise<JsonAPIResponse<UserLibrarySettingsApiResponse>> {
    return await fetchJson('/api/1/dashboard_settings/user_library_settings', {
      method: HTTPMethod.get,
    })
  }
}

export { UserApi }
