// @file Network requests related to Whiteboard remake link from sandbox

import { asciiSafeStringify } from '@@/bits/json_stringify'
import type { VersionedFetchOptions } from '@@/surface/api/fetchable_object'
import FetchableObject from '@@/surface/api/fetchable_object'
import { fetchJson } from '@@/surface/api_fetch'
import type { WallId, WallWhiteboardRemakeLink, WallWhiteboardRemakeLinkProps } from '@@/types'
import { HTTPMethod } from '@padlet/fetch'
import { mapKeys, snakeCase } from 'lodash-es'

class WhiteboardRemakeLinkApi extends FetchableObject {
  public static get whiteboardRemakeLinkUrl(): string {
    return `/api/\${ apiVersion }/walls/\${ wallId }/whiteboard/remake-link`
  }

  public static async update(
    wallId: WallId,
    props: Partial<WallWhiteboardRemakeLinkProps>,
    fetchOptions: VersionedFetchOptions = { apiVersion: 1 },
  ): Promise<WallWhiteboardRemakeLink> {
    const { apiVersion, ...restFetchOptions } = fetchOptions
    // Convert props to snake_case to send to backend
    const propsBody = mapKeys(props, (value, key) => snakeCase(key))
    const response = await fetchJson(this.buildUrl(this.whiteboardRemakeLinkUrl, { wallId, apiVersion }), {
      method: HTTPMethod.patch,
      body: asciiSafeStringify(propsBody),
      ...restFetchOptions,
    })
    return response.data.attributes
  }
}

export { WhiteboardRemakeLinkApi }
