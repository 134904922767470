// @file Network requests related to ai suggested comments from surface

import type { VersionedFetchOptions } from '@@/surface/api/fetchable_object'
import FetchableObject from '@@/surface/api/fetchable_object'
import { fetchJson } from '@@/surface/api_fetch'
import type { UserId, WallId } from '@@/types'
import { HTTPMethod } from '@padlet/fetch'

class AiSuggestedCommentsApi extends FetchableObject {
  public static async create({
    wallId,
    gradedUserId,
    fetchOptions = {},
  }: {
    wallId: WallId
    gradedUserId: UserId
    fetchOptions?: VersionedFetchOptions
  }): Promise<string> {
    const { apiVersion = 1, ...otherFetchOptions } = fetchOptions
    const query: { wallId: string; gradedUserId?: string } = {
      wallId: String(wallId),
      gradedUserId: String(gradedUserId),
    }
    const response = await fetchJson(`/api/${apiVersion}/ai-suggested-comment`, {
      method: HTTPMethod.post,
      query,
      ...otherFetchOptions,
    })

    return response.data.attributes.suggestedComment
  }
}

export { AiSuggestedCommentsApi }
