// @file Network requests related to wish draft from surface

import { asciiSafeStringify } from '@@/bits/json_stringify'
import FetchableObject from '@@/surface/api/fetchable_object'
import { fetchJson } from '@@/surface/api_fetch'
import type { DraftPost, Id, Wall as WallType } from '@@/types'
import { HTTPMethod } from '@padlet/fetch'

class WishDraftApi extends FetchableObject {
  public static get url(): string {
    return '/api/1/wish-drafts'
  }

  public static async list({ wallId }: { wallId: WallType['id'] }): Promise<DraftPost[]> {
    const response = await fetchJson(this.url, {
      method: HTTPMethod.get,
      query: {
        wallId: String(wallId),
      },
    })
    return response.data.map((d: any) => d.attributes)
  }

  public static async create(attributes: DraftPost): Promise<DraftPost> {
    const response = await fetchJson(this.url, {
      method: HTTPMethod.post,
      body: asciiSafeStringify({ attributes }),
    })
    return response.data.attributes
  }

  public static async update(draftId: Id, attributes: DraftPost): Promise<DraftPost> {
    const response = await fetchJson(`${this.url}/${draftId}`, {
      method: HTTPMethod.patch,
      body: asciiSafeStringify({ attributes }),
    })
    return response.data.attributes
  }

  public static async delete(draftId: Id): Promise<void> {
    await fetchJson(`${this.url}/${draftId}`, {
      method: HTTPMethod.delete,
    })
  }
}

export { WishDraftApi }
