// @file The store for surface onboarding panel displayed by surface_app.vue
import { trackEvent } from '@@/bits/analytics'
import device from '@@/bits/device'
import { captureFetchException } from '@@/bits/error_tracker'
import { isAppUsing } from '@@/bits/flip'
import window from '@@/bits/global'
import { __ } from '@@/bits/intl'
import { asciiSafeStringify } from '@@/bits/json_stringify'
import { navigateTo } from '@@/bits/location'
import { safeLocalStorage } from '@@/bits/safe_storage'
import { vSet } from '@@/bits/vue'
import { SurfaceOnboardingActionTypes, WallCreatedFromType } from '@@/enums'
import { SnackbarNotificationType, useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import { useSurfaceStore } from '@@/pinia/surface'
import { useSurfaceAIChatStore } from '@@/pinia/surface_ai_chat_store'
import { useSurfaceGuidedTemplateStore } from '@@/pinia/surface_guided_template_store'
import { useSurfaceOnboardingDemoPadletPanelStore } from '@@/pinia/surface_onboarding_demo_padlet_panel_store'
import { useSurfacePostsStore } from '@@/pinia/surface_posts'
import { useSurfaceStartingStateStore } from '@@/pinia/surface_starting_state'
import { useWindowSizeStore } from '@@/pinia/window_size'
import { WallOnboardingPanelApi } from '@@/surface/api/wall_onboarding_panel'
import type { OnboardingWallViz, WallViz } from '@@/types'
import type { JsonAPIResource } from '@padlet/arvo'
import { defineStore } from 'pinia'
import type { ComputedRef, Ref } from 'vue'
import { computed, ref } from 'vue'

interface SamplePostsStatusMap {
  [stepNumber: number]: SamplePostsStatus
}

export enum SamplePostsStatus {
  None = 'None',
  Loading = 'Loading',
  Generated = 'Generated',
  Errored = 'Errored',
}

const GENERATE_SAMPLE_POSTS_KEY = 'generatedSamplePostIds'

const PANEL_SIZES = {
  DESKTOP: 480,
  SMALLER_THAN_DESKTOP: 400,
}

export const GALLERY_ONBOARDING_MOBILE_HEIGHT = 176

export const useSurfaceOnboardingPanelStore = defineStore('surfaceOnboardingPanel', () => {
  const globalSnackbarStore = useGlobalSnackbarStore()
  const surfaceGuidedTemplateStore = useSurfaceGuidedTemplateStore()
  const surfaceDemoPadletPanelStore = useSurfaceOnboardingDemoPadletPanelStore()
  const surfaceAIChatStore = useSurfaceAIChatStore()

  // State
  const hasOnboardingCompleted = ref(false)
  const selectedWallFormat: Ref<WallViz | null> = ref(null)
  const sectionsSelected: Ref<boolean | null> = ref(null)
  const generatingSamplePostsStatusMap = ref<SamplePostsStatusMap>({})
  const randomTitle = computed<string>(
    () => useSurfaceStartingStateStore().startingState.onboardingFlow?.blankTemplateData?.randomTitle,
  )
  const isActionBarMounted = ref(false)

  const wallViz = computed(() => useSurfaceStore().wallAttributes.viz)
  // Gets the actual attribute of the wall, instead of preview attribute
  const sectionsSaved: ComputedRef<boolean> = computed<boolean>(() => useSurfaceStore().isSectioned)

  const wallFormats = computed<OnboardingWallViz[]>(
    () => useSurfaceStartingStateStore().startingState.onboardingFlow?.blankTemplateData?.layouts,
  )

  const savedWallFormat: ComputedRef<OnboardingWallViz> = computed<OnboardingWallViz>(() => {
    const savedFormat = wallFormats.value?.find((format) => format.value === wallViz.value)
    return savedFormat !== undefined ? savedFormat : wallFormats.value?.[0]
  })

  // Getters
  const showOnboardingPanel: ComputedRef<boolean> = computed<boolean>(
    () =>
      !device.app &&
      isShowingOnboardingPanelInitially.value &&
      !hasOnboardingCompleted.value &&
      !useSurfaceStore().isFrozen,
  )
  const isSmallerThanDesktop = computed(() => useWindowSizeStore().isSmallerThanDesktop)

  const isShowingOnboardingPanelInitially = computed<boolean>(
    () => useSurfaceStartingStateStore().startingState.onboardingFlow?.showOnboardingPanel ?? false,
  )

  const galleryOnboardingFlowData = computed(
    () => useSurfaceStartingStateStore().startingState.onboardingFlow?.galleryTemplateData,
  )

  const isGalleryTemplatePanel = computed(
    () =>
      useSurfaceStartingStateStore().startingState.onboardingFlow?.wallCreatedFromType === WallCreatedFromType.gallery,
  )

  const isBlankOnboardingPanel = computed(
    () =>
      useSurfaceStartingStateStore().startingState.onboardingFlow?.wallCreatedFromType === WallCreatedFromType.blank,
  )

  const xGalleryOnboardingPanelMobile = computed(() => {
    return (
      showOnboardingPanel.value &&
      isGalleryTemplatePanel.value &&
      useWindowSizeStore().isSmallerThanTabletPortrait &&
      isAppUsing('onboardingPanel')
    )
  })

  const xOnboardingPanel = computed(() => {
    return showOnboardingPanel.value && isAppUsing('onboardingPanel')
  })

  const wallId = computed(() => useSurfaceStore().wallId)
  // call this onMounted
  function setGenerateSamplePostStatus(): void {
    const steps = galleryOnboardingFlowData.value.steps
    steps.forEach((step: any, index: number) => {
      if (step.action === SurfaceOnboardingActionTypes.GenerateSamplePosts && step.samplePostIds !== null) {
        const storageItem = safeLocalStorage.getItem(`${GENERATE_SAMPLE_POSTS_KEY}:${wallId.value as string}:${index}`)
        if (storageItem !== null && JSON.parse(storageItem).length > 0) {
          vSet(generatingSamplePostsStatusMap.value, index, SamplePostsStatus.Generated)
        } else {
          vSet(generatingSamplePostsStatusMap.value, index, SamplePostsStatus.None)
        }
      }
    })
  }

  const panelWidth = computed(() => {
    if (!showOnboardingPanel.value) return 0
    return isSmallerThanDesktop.value ? PANEL_SIZES.SMALLER_THAN_DESKTOP : PANEL_SIZES.DESKTOP
  })

  const currentlySelectedWallFormat = computed(() => {
    if (selectedWallFormat.value !== null) {
      return selectedWallFormat.value
    } else {
      return savedWallFormat.value?.value
    }
  })

  const isSectionsCurrentlySelected = computed(() => {
    if (sectionsSelected.value !== null) {
      return sectionsSelected.value
    } else {
      return sectionsSaved.value
    }
  })

  // Actions
  function setIsActionBarMounted(newValue: boolean): void {
    isActionBarMounted.value = newValue
  }

  function closeOnboardingPanel(): void {
    void fetchWallOnboardingPanelHide()
    hasOnboardingCompleted.value = true
  }

  function openOnboardingPanel(): void {
    void fetchWallOnboardingPanelShow()
    hasOnboardingCompleted.value = false
  }

  function dontShowGalleryAgain(templateId: string): void {
    void fetchDontShowGalleryAgain(templateId)
    hasOnboardingCompleted.value = true
  }

  function setSelectedWallFormat(wallFormat: WallViz): void {
    selectedWallFormat.value = wallFormat
  }

  function setSelectedSections(isSectionSelected: boolean): void {
    sectionsSelected.value = isSectionSelected
  }

  const isGuidedTemplate = computed(() => {
    return surfaceGuidedTemplateStore.isDiscussionBoard || surfaceGuidedTemplateStore.isExitTicket
  })

  async function fetchWallOnboardingPanelShow(): Promise<void> {
    try {
      await WallOnboardingPanelApi.show(window.app?.$store.getters?.wallId)
      // Only show snackbar on guided template panels
      if (isGuidedTemplate.value || surfaceDemoPadletPanelStore.isDemoPadletPanelDesktop) {
        globalSnackbarStore.setSnackbar({
          message: isGuidedTemplate.value ? __('Guide reopened') : __('Onboarding reopened'),
          notificationType: SnackbarNotificationType.success,
          timeout: 5000,
        })
      }
    } catch (error) {
      globalSnackbarStore.setSnackbar({
        message: surfaceGuidedTemplateStore.isDiscussionBoard
          ? __('Guide was not reopened')
          : __('Onboarding was not reopened'),
        notificationType: SnackbarNotificationType.error,
        timeout: 5000,
      })
      captureFetchException(error, { source: ' SurfaceWallOnboardingPanelPanelWasNotReopened' })
    }
  }

  async function fetchWallOnboardingPanelHide(): Promise<void> {
    try {
      await WallOnboardingPanelApi.fetch(window.app?.$store.getters?.wallId)
      // Only show snackbar on guided template panels
      if (isGuidedTemplate.value || surfaceDemoPadletPanelStore.isDemoPadletPanelDesktop) {
        globalSnackbarStore.setSnackbar({
          message: isGuidedTemplate.value ? __('Guide closed') : __('Onboarding closed'),
          notificationType: SnackbarNotificationType.success,
          timeout: 5000,
          actionText: __('Undo'),
          actionTextActions: [
            () => {
              void openOnboardingPanel()
              // Ensure that the AI chat panel closes if it is open
              surfaceAIChatStore.xSurfaceAIChatPanel = false
            },
          ],
        })
      }
    } catch (error) {
      globalSnackbarStore.setSnackbar({
        message: surfaceGuidedTemplateStore.isDiscussionBoard
          ? __('Guide was not closed')
          : __('Onboarding was not closed'),
        notificationType: SnackbarNotificationType.error,
        timeout: 5000,
      })
      captureFetchException(error, { source: ' SurfaceWallOnboardingPanelPanelWasNotHidden' })
    }
  }

  async function fetchDontShowGalleryAgain(templateId: string): Promise<void> {
    try {
      await WallOnboardingPanelApi.dontShowGalleryAgain(window.app?.$store.getters?.wallId, templateId)
    } catch (e) {
      captureFetchException(e, { source: 'SurfaceWallOnboardingPanelDontShowGalleryAgain' })
    }
  }

  async function generateSamplePosts(stepNumber: number, samplePostIds: string[]): Promise<void> {
    try {
      vSet(generatingSamplePostsStatusMap.value, stepNumber, SamplePostsStatus.Loading)
      const { data } = await WallOnboardingPanelApi.generateSamplePosts(wallId.value, samplePostIds)
      const ids: number[] = (data as JsonAPIResource<any>).attributes?.ids
      safeLocalStorage.setItem(
        `${GENERATE_SAMPLE_POSTS_KEY}:${wallId.value as string}:${stepNumber}`, // generatedSamplePostIds:204:0
        asciiSafeStringify(ids),
      )
      vSet(generatingSamplePostsStatusMap.value, stepNumber, SamplePostsStatus.Generated)
    } catch (e) {
      captureFetchException(e, { source: 'SurfaceWallOnboardingPanelGenerateSamplePost' })
    }
  }

  async function deleteSamplePosts(stepNumber: number): Promise<void> {
    try {
      const storageItem = safeLocalStorage.getItem(
        `${GENERATE_SAMPLE_POSTS_KEY}:${wallId.value as string}:${stepNumber}`,
      )
      if (storageItem !== null) {
        const ids = JSON.parse(storageItem) as number[]
        await Promise.all(ids.map(async (id: number) => await useSurfacePostsStore().deletePost(`c${id}`)))
        safeLocalStorage.removeItem(`${GENERATE_SAMPLE_POSTS_KEY}:${wallId.value as string}:${stepNumber}`)
        vSet(generatingSamplePostsStatusMap.value, stepNumber, SamplePostsStatus.None)
      }
    } catch (e) {
      captureFetchException(e, { source: 'SurfaceWallOnboardingPanelDeleteSamplePost' })
    }
  }

  function navigateToExamplePadlet(): void {
    trackEvent('Gallery Onboarding Panel Action Clicked', 'See Example Padlet', 'Surface', null, {
      gallery_template_id: galleryOnboardingFlowData.value.id,
    })
    navigateTo(galleryOnboardingFlowData.value.previewPadletUrl, { target: '_blank' })
  }

  return {
    // State
    generatingSamplePostsStatusMap,
    sectionsSaved,
    selectedWallFormat,
    sectionsSelected,

    // Getters
    showOnboardingPanel,
    galleryOnboardingFlowData,
    wallFormats,
    savedWallFormat,
    isGalleryTemplatePanel,
    randomTitle,
    isShowingOnboardingPanelInitially,
    panelWidth,
    currentlySelectedWallFormat,
    isSectionsCurrentlySelected,
    isActionBarMounted,
    xGalleryOnboardingPanelMobile,
    xOnboardingPanel,
    isBlankOnboardingPanel,

    // Actions,
    setGenerateSamplePostStatus,
    setSelectedWallFormat,
    setSelectedSections,
    closeOnboardingPanel,
    dontShowGalleryAgain,
    generateSamplePosts,
    deleteSamplePosts,
    setIsActionBarMounted,
    navigateToExamplePadlet,
  }
})
