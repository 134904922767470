// @file Network requests related to Comments from surface

import { asciiSafeStringify } from '@@/bits/json_stringify'
import type { VersionedFetchOptions } from '@@/surface/api/fetchable_object'
import FetchableObject from '@@/surface/api/fetchable_object'
import { fetchJson } from '@@/surface/api_fetch'
import type { Id } from '@@/types'
import type { Comment as CommentType, CommentsFetchQuery, JsonAPIResponse } from '@padlet/arvo'
import { fetchComments } from '@padlet/arvo'
import { HTTPMethod } from '@padlet/fetch'

const API_VERSION = 5

class CommentApi extends FetchableObject {
  public static get url(): string {
    return `/api/${API_VERSION}/comments`
  }

  public static get fetchUrl(): string {
    return `/api/${API_VERSION}/comments?wall_id=\${ wall.id }`
  }

  public static async fetch(query: CommentsFetchQuery): Promise<JsonAPIResponse<CommentType>> {
    return await fetchComments(query)
  }
}

interface WallCommentsHashidFetchQuery {
  wallHashid: number
  wishHashid?: undefined
  pageStart?: string
}

interface WishCommentsHashidFetchQuery {
  wishHashid: number
  wallHashid?: undefined
  pageStart?: string
}

type CommentsFetchHashidQuery = WallCommentsHashidFetchQuery | WishCommentsHashidFetchQuery

class CommentV2Api extends FetchableObject {
  public static get url(): string {
    return `/api/\${ apiVersion }/comments`
  }

  public static get fetchUrlForWall(): string {
    return `/api/\${ apiVersion }/comments?wall_hashid=\${ wallHashid }&page_start=\${ pageStart }`
  }

  public static get fetchUrlForWish(): string {
    return `/api/\${ apiVersion }/comments?wish_hashid=\${ wishHashid }&page_start=\${ pageStart }`
  }

  private static get updateUrl(): string {
    return `/api/\${ apiVersion }/comments/\${ commentId }`
  }

  private static get approveUrl(): string {
    return `/api/\${ apiVersion }/comments/\${ commentId }/approve`
  }

  public static async fetch(query: CommentsFetchHashidQuery, fetchOptions: VersionedFetchOptions = {}): Promise<any> {
    const { apiVersion = 9, ...otherFetchOptions } = fetchOptions
    return await fetchJson(
      this.buildUrl(query.wishHashid != null ? this.fetchUrlForWish : this.fetchUrlForWall, {
        apiVersion,
        wallHashid: query.wallHashid,
        wishHashid: query.wishHashid,
        pageStart: query.pageStart,
      }),
      {
        method: HTTPMethod.get,
        ...otherFetchOptions,
      },
    )
  }

  public static async create(attributes: Partial<CommentType>, fetchOptions: VersionedFetchOptions = {}): Promise<any> {
    const { apiVersion = 7, ...otherFetchOptions } = fetchOptions
    return await fetchJson(this.buildUrl(this.url, { apiVersion }), {
      method: HTTPMethod.post,
      body: asciiSafeStringify({ attributes }),
      ...otherFetchOptions,
    })
  }

  public static async update(
    commentId: Id,
    attributes: Partial<CommentType>,
    fetchOptions: VersionedFetchOptions = {},
  ): Promise<any> {
    const { apiVersion = 7, ...otherFetchOptions } = fetchOptions
    return await fetchJson(this.buildUrl(this.updateUrl, { apiVersion, commentId }), {
      method: HTTPMethod.patch,
      body: asciiSafeStringify({ attributes }),
      ...otherFetchOptions,
    })
  }

  public static async approve(commentId: Id, fetchOptions: VersionedFetchOptions = {}): Promise<any> {
    const { apiVersion = 7, ...otherFetchOptions } = fetchOptions
    return await fetchJson(this.buildUrl(this.approveUrl, { apiVersion, commentId }), {
      method: HTTPMethod.patch,
      ...otherFetchOptions,
    })
  }
}

export { CommentApi, CommentV2Api }
