// @file Network requests related to Wall Collaborator from surface
/* eslint-disable no-return-await, no-template-curly-in-string */
import { asciiSafeStringify } from '@@/bits/json_stringify'
import { transformCurrentUrl } from '@@/bits/location'
import type { WallAccessRole } from '@@/enums'
import FetchableObject from '@@/surface/api/fetchable_object'
import { fetchJson } from '@@/surface/api_fetch'
import type {
  Id,
  PrivacyPolicyId,
  UserGroupWallCollaborator,
  UserId,
  UserWallCollaborator,
  UserWallCollaboratorInvite,
  WallId,
} from '@@/types'
import type { JsonAPIResponse } from '@padlet/arvo'
import { HTTPMethod } from '@padlet/fetch'

class WallCollaboratorApi extends FetchableObject {
  public static async invite({
    wallId,
    email,
    userId,
    role,
  }: UserWallCollaboratorInvite): Promise<JsonAPIResponse<UserWallCollaborator>> {
    const url = `/api/8/walls/${wallId}/user-collaborators`
    return await fetchJson(url, {
      method: HTTPMethod.post,
      body: asciiSafeStringify({
        wallId,
        email,
        userId,
        role,
        status: 'invited',
      }),
    })
  }

  // Right now, you can only update the collaborator's role.
  // In future, you may be able to update other attributes, such as status.
  public static async update({
    wallId,
    userId,
    role,
  }: {
    wallId: Id
    userId: UserId
    role: WallAccessRole
  }): Promise<JsonAPIResponse<UserWallCollaborator>> {
    const url = `/api/8/walls/${wallId}/user-collaborators/${userId}`
    return await fetchJson(url, {
      method: HTTPMethod.patch,
      body: asciiSafeStringify({ role }),
    })
  }

  public static async remove({
    wallId,
    userId,
  }: {
    wallId: Id
    userId: UserId
  }): Promise<JsonAPIResponse<UserWallCollaborator>> {
    const url = `/api/8/walls/${wallId}/user-collaborators/${userId}`
    return await fetchJson(url, {
      method: HTTPMethod.delete,
    })
  }

  public static async search({
    searchQuery,
    wallId,
    limit = 5,
  }: {
    searchQuery: string
    wallId: WallId
    limit?: number
  }): Promise<JsonAPIResponse<UserGroupWallCollaborator | UserWallCollaborator>> {
    const url = transformCurrentUrl(
      {},
      {
        path: '/api/1/wall-collaborators/search',
        search: {
          q: searchQuery,
          wall_id: String(wallId),
          limit: String(limit),
        },
      },
    )
    return await fetchJson(url, {
      method: HTTPMethod.get,
    })
  }

  // Rate-limited: you can only send one invite per collaborator per minute
  // Returns 429 Too many requests if rate-limited
  public static async resendInvitation(privacyPolicyId: PrivacyPolicyId, collaboratorId: UserId): Promise<any> {
    const url = `/privacy_policies/${privacyPolicyId}/reinvite`
    return await fetchJson(url, {
      method: HTTPMethod.post,
      body: asciiSafeStringify({ invite_user_id: collaboratorId }),
    })
  }
}

export { WallCollaboratorApi }
