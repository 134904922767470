// @file Surface Accounts Store

import initialLibrary from '@@/bits/current_library'
import { captureNonNetworkFetchError } from '@@/bits/error_tracker'
import { displayNameForUser } from '@@/bits/user_model'
import { FetchJsonStatus, LibraryMembershipRole, LibraryType } from '@@/enums'
import { useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import { useSurfaceStore } from '@@/pinia/surface'
import { useSurfaceCurrentUserStore } from '@@/pinia/surface_current_user'
import { fetchJson } from '@@/surface/api_fetch'
import type { JsonApiData, Library, LibraryAccess, LibraryId, Tenant, TenantId, User } from '@@/types'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

const NATIVE_TENANT_ID = 1
const EMPTY_LIBRARY_ACCESS: LibraryAccess = {
  canViewLibraryInfo: false,
  canUpdateLibraryInfo: false,
  canSetupLti: false,
  canUpdatePermissions: false,
  canViewLibraryMemberships: false,
  canViewLibraryAnalytics: false,
  canManageMembers: false,
  canManageBilling: false,
  canTrashLibrary: false,
  canUseLibraryAsOwner: false,
  canUseLibraryAsAdmin: false,
  canUseLibraryAsTeacher: false,
  canViewLibrarySecurity: false,
  canInviteSchoolAdmins: false,
  canInviteSchoolTeachers: false,
  canInviteSchoolStudents: false,
  canInviteMembers: false,
  canViewGallery: false,
  canManageContentSafetySettings: false,
  canTransferWalls: false,
}
const DEFAULT_AVATAR = 'https://padlet.net/avatars/tenant_avatar.webp'

export const useSurfaceAccountsStore = defineStore('surfaceAccountsStore', () => {
  const globalSnackbarStore = useGlobalSnackbarStore()
  const surfaceStore = useSurfaceStore()
  const surfaceCurrentUserStore = useSurfaceCurrentUserStore()

  function genericFetchError(payload: { error: any; source: string }): void {
    captureNonNetworkFetchError(payload.error, { source: payload.source })
    globalSnackbarStore.genericFetchError()
  }

  // State
  const tenantsById = ref<Record<TenantId, Tenant>>({})
  const librariesById = ref<Record<LibraryId, Library>>({})
  const fetchAccountsStatus = ref<FetchJsonStatus>(FetchJsonStatus.Fetching)

  // Getters
  const currentUser = computed(() => surfaceCurrentUserStore.currentUser)
  const wallId = computed(() => surfaceStore.wallId)
  const libraryId = computed(() => surfaceStore.libraryId)

  const currentTenant = computed((): Tenant | null => {
    if (Object.keys(tenantsById.value).length === 0) return null
    if (currentUser.value == null) return null
    return tenantsById.value[currentUser.value.tenant_id]
  })
  const currentTenantName = computed((): string => currentTenant.value?.name ?? '')
  const currentTenantLogo = computed((): string =>
    currentTenant.value?.assets != null ? (currentTenant.value?.assets?.home_logo as string) : DEFAULT_AVATAR,
  )
  const isBackpack = computed((): boolean => {
    if (currentUser.value == null) return false
    return currentTenant.value != null
      ? currentTenant.value.type === 'school'
      : currentUser.value.tenant_type === 'school'
  })
  const isBriefcase = computed((): boolean => currentTenant.value?.type === 'business')

  const isStudent = computed((): boolean => currentUser.value?.role === 'student')
  const isTeacher = computed((): boolean => currentUser.value?.role === 'teacher')
  const isAdmin = computed((): boolean => currentUser.value?.role === 'admin')
  const isOwner = computed((): boolean => currentUser.value?.role === 'owner')
  const isNativeAccount = computed((): boolean => currentUser.value?.tenant_id === NATIVE_TENANT_ID)

  const currentLibrary = computed((): Library | null => {
    if (libraryId.value == null) return null
    return librariesById.value[libraryId.value] ?? initialLibrary
  })
  const isCurrentLibrarySchool = computed((): boolean => currentLibrary.value?.libraryType === LibraryType.School)
  const isCurrentLibraryClassroom = computed((): boolean => currentLibrary.value?.libraryType === LibraryType.Classroom)
  const isCurrentLibraryTeam = computed((): boolean => currentLibrary.value?.libraryType === LibraryType.Team)
  const currentLibraryId = computed((): LibraryId | null => currentLibrary.value?.id ?? null)

  // The library membership of the current user in the current library
  const currentLibraryMembership = computed(() => {
    if (currentLibrary.value == null || currentUser.value == null) return null
    if (currentLibrary.value.membership == null) return null
    return currentLibrary.value?.membership.find((m) => m.user_id === currentUser.value?.id) || null
  })
  const isLibraryOwner = computed(() => currentLibraryMembership.value?.role === LibraryMembershipRole.Owner)
  const isLibraryAdmin = computed(() => currentLibraryMembership.value?.role === LibraryMembershipRole.Admin)
  const isLibraryTeacher = computed(() => currentLibraryMembership.value?.role === LibraryMembershipRole.Teacher)
  const defaultPersonalLibrary = computed((): Partial<Library> => {
    return {
      name: displayNameForUser(currentUser.value),
      avatar: currentUser.value.avatar,
      libraryType: LibraryType.Solo,
      libraryAccess: EMPTY_LIBRARY_ACCESS,
      slug: currentUser.value.username,
    }
  })
  const wallViewableAndVisibleLibrariesArray = computed((): Library[] =>
    Object.values(librariesById.value).filter((library) => library.visible),
  )
  const isAccountsFetched = computed((): boolean => fetchAccountsStatus.value === FetchJsonStatus.Completed)

  // Methods
  async function initialize(): Promise<void> {
    if (surfaceCurrentUserStore.currentUser == null) return
    await fetchAccounts()
  }

  async function fetchAccounts(): Promise<void> {
    try {
      const response = await fetchJson(`/api/1/accounts`)
      const accountData = response.data as Array<JsonApiData<User | Library>>
      const tenantData = response.included as Array<JsonApiData<Tenant>>

      tenantData.forEach((item) => {
        if (item.type === 'tenant') {
          tenantsById.value = { ...tenantsById.value, [item.id]: item.attributes }
        }
      })

      accountData.forEach((account) => {
        if (account.type === 'library') {
          librariesById.value = { ...librariesById.value, [account.id]: account.attributes }
        }
      })

      fetchAccountsStatus.value = FetchJsonStatus.Completed
    } catch (error) {
      genericFetchError({ error, source: 'SurfaceAccountsStoreFetchAccounts' })
    }
  }

  return {
    // State

    // Getters
    currentUser,
    currentTenant,
    currentTenantName,
    currentTenantLogo,
    wallId,
    libraryId,
    librariesById,
    currentLibrary,
    isCurrentLibrarySchool,
    isCurrentLibraryClassroom,
    isCurrentLibraryTeam,
    currentLibraryId,
    currentLibraryMembership,
    isLibraryOwner,
    isLibraryAdmin,
    isLibraryTeacher,
    isStudent,
    isTeacher,
    isAdmin,
    isOwner,
    isNativeAccount,
    isBackpack,
    isBriefcase,
    defaultPersonalLibrary,
    wallViewableAndVisibleLibrariesArray,
    isAccountsFetched,

    // Actions
    initialize,
  }
})
