// @file Network requests related to AI Chat from surface
/* eslint-disable no-return-await, no-template-curly-in-string */
import environment from '@@/bits/environment'
import { asciiSafeStringify } from '@@/bits/json_stringify'
import type { Message, Suggestion as SurfaceAiChatSuggestion } from '@@/pinia/surface_ai_chat_store'
import { useSurfaceStartingStateStore } from '@@/pinia/surface_starting_state'
import type { VersionedFetchOptions } from '@@/surface/api/fetchable_object'
import FetchableObject from '@@/surface/api/fetchable_object'
import { fetchJson } from '@@/surface/api_fetch'
import type { WallId } from '@@/types'
import { HTTPMethod } from '@padlet/fetch'

class SurfaceAIChatApi extends FetchableObject {
  public static get summarizeUrl(): string {
    return `/api/\${ apiVersion }/walls/\${ wallId }/ai-chat/summarize`
  }

  public static get baseUrl(): string {
    return environment === 'development' ? 'https://morpheus.padlet.dev' : 'https://morpheus.padlet.com'
  }

  public static get threadUrl(): string {
    return `/api/\${ apiVersion }/walls/\${ wallId }/ai-chat/context`
  }

  public static async getSuggestions(
    serializedWall: string,
    currentCountry: string,
    language: string,
    accountType: string,
    options: { signal?: AbortSignal } = {},
  ): Promise<{ suggestions: SurfaceAiChatSuggestion[] }> {
    const response = await fetch(`${this.baseUrl}/api/v1/surface-chat/suggestions`, {
      method: HTTPMethod.post,
      headers: {
        Authorization: `Bearer ${useSurfaceStartingStateStore().morpheusToken}`,
        'Content-Type': 'application/json',
      },
      body: asciiSafeStringify({
        serialized_wall: serializedWall,
        current_country: currentCountry,
        language,
        account_type: accountType,
      }),
      ...options,
    })
    return await response.json()
  }

  public static async getAssistants(): Promise<any> {
    const response = await fetch(`${this.baseUrl}/api/v1/assistant/all`, {
      method: HTTPMethod.get,
      headers: {
        Authorization: `Bearer ${useSurfaceStartingStateStore().morpheusToken}`,
      },
    })
    return await response.json()
  }

  public static async createThread(
    wallId: WallId,
    fetchOptions: VersionedFetchOptions = { apiVersion: 1 },
  ): Promise<any> {
    const response = await fetch(`${this.baseUrl}/api/v1/assistant/threads`, {
      method: HTTPMethod.post,
      headers: {
        Authorization: `Bearer ${useSurfaceStartingStateStore().morpheusToken}`,
      },
      ...fetchOptions,
    })
    return await response.json()
  }

  public static async getThreadId(
    wallId: WallId,
    fetchOptions: VersionedFetchOptions = { apiVersion: 1 },
  ): Promise<any> {
    const { apiVersion, ...restFetchOptions } = fetchOptions
    const response = await fetchJson(this.buildUrl(this.threadUrl, { wallId, apiVersion }), {
      method: HTTPMethod.get,
      ...restFetchOptions,
    })
    return response?.data?.attributes?.threadId
  }

  public static async getLatestRun(threadId: string): Promise<{ status: string; run_id: string | null }> {
    const response = await fetch(`${this.baseUrl}/api/v1/assistant/${threadId}/latest-run`, {
      method: HTTPMethod.get,
      headers: {
        Authorization: `Bearer ${useSurfaceStartingStateStore().morpheusToken}`,
      },
    })
    return await response.json()
  }

  public static async saveThreadId(
    wallId: WallId,
    threadId: string,
    fetchOptions: VersionedFetchOptions = { apiVersion: 1 },
  ): Promise<any> {
    const { apiVersion, ...restFetchOptions } = fetchOptions
    const response = await fetchJson(this.buildUrl(this.threadUrl, { wallId, apiVersion }), {
      method: HTTPMethod.put,
      body: asciiSafeStringify({ thread_id: threadId }),
      ...restFetchOptions,
    })
    return response?.data?.attributes?.threadId
  }

  public static async deleteThreadForWall(
    wallId: WallId,
    fetchOptions: VersionedFetchOptions = { apiVersion: 1 },
  ): Promise<any> {
    const { apiVersion, ...restFetchOptions } = fetchOptions
    await fetchJson(this.buildUrl(this.threadUrl, { wallId, apiVersion }), {
      method: HTTPMethod.delete,
      ...restFetchOptions,
    })
  }

  public static async getThreadMessages(
    threadId: string,
    fetchOptions: VersionedFetchOptions & { signal?: AbortSignal } = { apiVersion: 1 },
  ): Promise<any> {
    const response = await fetch(`${this.baseUrl}/api/v1/assistant/threads/${threadId}/messages`, {
      method: HTTPMethod.get,
      headers: {
        Authorization: `Bearer ${useSurfaceStartingStateStore().morpheusToken}`,
      },
      ...fetchOptions,
    })
    return await response.json()
  }

  public static async sendMessage(
    threadId: string,
    message: Message,
    fetchOptions: VersionedFetchOptions = { apiVersion: 1 },
  ): Promise<any> {
    const response = await fetch(`${this.baseUrl}/api/v1/assistant/threads/${threadId}`, {
      method: HTTPMethod.post,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${useSurfaceStartingStateStore().morpheusToken}`,
      },
      body: asciiSafeStringify(message),
      ...fetchOptions,
    })
    return await response.json()
  }

  public static async getSerializedWall(
    wallId: WallId,
    fetchOptions: VersionedFetchOptions = { apiVersion: 1 },
  ): Promise<any> {
    const response = await fetch(this.buildUrl(this.summarizeUrl, { wallId, apiVersion: 1 }), {
      method: HTTPMethod.get,
      ...fetchOptions,
    })
    return await response.json()
  }
}

export { SurfaceAIChatApi }
