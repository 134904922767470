// @file Whiteboard tools store
import { isAppCapableOf } from '@@/bits/app_can'
import type { ContentSource } from '@@/bits/content_picker'
import { CONTENT_PICKER_SOURCE_ARIA_LABEL_MAP_FOR_SETTINGS, getWhiteboardContentSources } from '@@/bits/content_picker'
import device from '@@/bits/device'
import { captureFetchException } from '@@/bits/error_tracker'
import { isAppUsing } from '@@/bits/flip'
import type { WhiteboardToolSettingEntry } from '@@/bits/whiteboard_tools'
import {
  getAllTools,
  isPadletTool,
  PADLET_TOOL_IDS,
  TLDRAW_TOOLS_IN_SETTINGS,
  toContentSource,
  toStoredToolId,
} from '@@/bits/whiteboard_tools'
import { useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import { useSurfaceStore } from '@@/pinia/surface'
import { useSurfacePermissionsStore } from '@@/pinia/surface_permissions'
import { useSurfacePostPropertiesStore } from '@@/pinia/surface_post_properties'
import { WhiteboardToolSettingsApi } from '@@/surface/api/whiteboard_tool_settings'
import type { WhiteboardToolSettings } from '@@/types'
import { cloneDeep, groupBy, isEqual, mapKeys, mapValues, pick, pickBy } from 'lodash-es'
import { defineStore } from 'pinia'
import type { TLShape } from 'tldraw'
import { computed, ref } from 'vue'

// #region Defaults
// By default we enable all tools, hence the boolean value is true.

const DEFAULT_WHITEBOARD_TLDRAW_TOOL_SETTINGS: WhiteboardToolSettings = TLDRAW_TOOLS_IN_SETTINGS.reduce(
  (settings, { id }) => ({ ...settings, [id]: true }),
  {},
)

const DEFAULT_WHITEBOARD_PADLET_TOOL_SETTINGS: WhiteboardToolSettings = PADLET_TOOL_IDS.reduce(
  (settings, id) => ({ ...settings, [id]: true }),
  {},
)

const DEFAULT_WHITEBOARD_TOOL_SETTINGS: WhiteboardToolSettings = {
  ...DEFAULT_WHITEBOARD_TLDRAW_TOOL_SETTINGS,
  ...DEFAULT_WHITEBOARD_PADLET_TOOL_SETTINGS,
}
// #endregion

export const useWhiteboardToolsStore = defineStore('whiteboardTools', () => {
  const surfaceStore = useSurfaceStore()
  const surfacePostPropertiesStore = useSurfacePostPropertiesStore()
  const surfacePermissionsStore = useSurfacePermissionsStore()
  const globalSnackbarStore = useGlobalSnackbarStore()

  const whiteboardToolSettings = ref<WhiteboardToolSettings>(DEFAULT_WHITEBOARD_TOOL_SETTINGS)

  const isPreviewingToolSettings = ref(false)
  const previewWhiteboardToolSettings = ref<WhiteboardToolSettings>({})

  /**
   * The currently being displayed tool settings, depending on the preview state.
   */
  const displayedWhiteboardToolSettings = computed(() => {
    return isPreviewingToolSettings.value ? previewWhiteboardToolSettings.value : whiteboardToolSettings.value
  })

  /**
   * `whiteboardToolSettings` can contain more keys than the default settings because the mobile app
   * can put additional keys for mobile-specific settings. We only want to perform checks on web-specific
   * settings here.
   */
  const webWhiteboardToolSettings = computed<WhiteboardToolSettings>(() => {
    return pick(displayedWhiteboardToolSettings.value, Object.keys(DEFAULT_WHITEBOARD_TOOL_SETTINGS))
  })

  // #region State getters
  const areAllToolsDisabled = computed<boolean>(() => {
    return Object.values(webWhiteboardToolSettings.value).every((enabled) => !enabled)
  })

  const areAllToolsEnabled = computed((): boolean => {
    return Object.values(webWhiteboardToolSettings.value).every((enabled) => enabled)
  })

  const allEnabledToolIds = computed(() => {
    return Object.keys(webWhiteboardToolSettings.value).filter((id) => webWhiteboardToolSettings.value[id])
  })

  const isPreviewToolSettingsDirty = computed<boolean>(() => {
    return isPreviewingToolSettings.value && !isEqual(whiteboardToolSettings.value, previewWhiteboardToolSettings.value)
  })
  // #endregion

  // #region State getter functions
  const isToolEnabled = (toolId: string): boolean => {
    // The padlet-speech-bubble shape is a specialized type of geo shape.
    // Therefore, its availability is tied to the geo tool's enabled/disabled state.
    // If the geo tool is disabled, padlet-speech-bubble shapes cannot be created.
    const effectiveToolId = isAppUsing('tldrawShapesV2') && toolId === 'padlet-speech-bubble' ? 'geo' : toolId
    return (
      webWhiteboardToolSettings.value[toStoredToolId(effectiveToolId)] ??
      // If for some reason the toolId doesn't exist in the settings:
      // - we default to true if sandboxAllowedTools flip is disabled (all tools are allowed)
      // - we default to false if sandboxAllowedTools flip is enabled (all tools are disallowed)
      !isAppUsing('sandboxAllowedTools')
    )
  }

  const allDrawTools = getAllTools().find((t) => t.id === 'draw-nested')?.secondaryMenu?.items ?? []

  const wasShapeCreatedFromAllowedTool = (shape: TLShape): boolean => {
    // This is the master flag, if it's false, we return true here to not disrupt any current flow.
    if (!isAppUsing('sandboxAllowedTools')) return true

    // Padlet attachment shapes have type of `padlet-attachment-shape`.
    // We need to check its `meta.contentSource` to see if it's allowed.
    const isPadletAttachmentShape = shape.type === 'padlet-attachment-shape'
    const isAllowedPadletAttachmentShape =
      isPadletAttachmentShape && typeof shape.meta.contentSource === 'string' && isToolEnabled(shape.meta.contentSource)

    // The `draw-nested` setting controls all draw tools (draw, highlight)
    const isDrawTool = allDrawTools.some((t) => t.tldrawToolId === shape.type)
    const isAllowedDrawTool = isDrawTool && isToolEnabled('draw-nested')

    // If current env is not app (web), we already support allowed tools.
    // If it's app, we need to check app capability.
    const supportsAllowedTools = !device.app || isAppCapableOf('whiteboardToolSettings')

    return supportsAllowedTools
      ? isAllowedPadletAttachmentShape || isAllowedDrawTool || isToolEnabled(shape.type)
      : true
  }
  // #endregion

  // #region Tool settings actions
  const syncToolSettingsToContentPicker = (settings: WhiteboardToolSettings): void => {
    // Whiteboard uses the same content picker as Board content picker (post content picker),
    // so we can update the attachment settings in wall post properties to make the whiteboard
    // content picker only show the enabled tools.
    // Don't modify this for admin users since they can use all the tools.
    if (!surfacePermissionsStore.canIAdminister && isAppUsing('sandboxAllowedTools')) {
      const attachmentSettings = mapKeys(
        pickBy(settings, (_value, key) => isPadletTool(key)),
        (_value, key) => toContentSource(key),
      )
      surfacePostPropertiesStore.setWallPostProperties({
        attachment_settings: attachmentSettings,
      })
    }
  }

  /**
   * NOTE: Always use this method to update the tool settings local state.
   * This method will ensure attachment_settings are in sync with the tool settings.
   * (So that the content picker only shows the enabled attachment types).
   */
  const setToolSettings = (settings: WhiteboardToolSettings): void => {
    whiteboardToolSettings.value = {
      ...whiteboardToolSettings.value,
      ...settings,
    }
    syncToolSettingsToContentPicker(whiteboardToolSettings.value)
  }

  const setPreviewToolSettings = (settings: WhiteboardToolSettings): void => {
    previewWhiteboardToolSettings.value = {
      ...previewWhiteboardToolSettings.value,
      ...settings,
    }
    syncToolSettingsToContentPicker(previewWhiteboardToolSettings.value)
  }

  const startPreviewingWhiteboardToolSettings = (): void => {
    isPreviewingToolSettings.value = true
    previewWhiteboardToolSettings.value = cloneDeep(whiteboardToolSettings.value)
  }

  const stopPreviewingWhiteboardToolSettings = (): void => {
    isPreviewingToolSettings.value = false
    previewWhiteboardToolSettings.value = {}
    // Reset content picker sources after stopping preview state.
    syncToolSettingsToContentPicker(whiteboardToolSettings.value)
  }

  const saveToolSettingsToServer = async (): Promise<void> => {
    try {
      const updatedSettings = await WhiteboardToolSettingsApi.update(
        surfaceStore.wallHashid,
        previewWhiteboardToolSettings.value,
      )
      setToolSettings(updatedSettings)
      setPreviewToolSettings(updatedSettings)
    } catch (e) {
      globalSnackbarStore.genericFetchError()
      captureFetchException(e, { source: 'saveWhiteboardToolSettingsToServer' })
    }
  }

  const toggleToolSetting = (toolId: string): void => {
    setPreviewToolSettings({
      [toStoredToolId(toolId)]: !isToolEnabled(toolId),
    })
  }

  const disableAllTools = (): void => {
    setPreviewToolSettings(mapValues(DEFAULT_WHITEBOARD_TOOL_SETTINGS, () => false))
  }

  const enableAllTools = (): void => {
    setPreviewToolSettings(mapValues(DEFAULT_WHITEBOARD_TOOL_SETTINGS, () => true))
  }
  // #endregion

  // #region Data to render tool settings.
  // Coupled to how the UI is structured so we have 2 main sections:
  // 1. TLDraw tools
  // 2. Padlet tools, grouped by color

  const displayedTldrawToolSettings = computed<WhiteboardToolSettingEntry[]>(() => {
    return TLDRAW_TOOLS_IN_SETTINGS.map((tool) => ({
      ...tool,
      isEnabled: isToolEnabled(tool.id),
    }))
  })

  const displayedPadletToolSettings = computed<Record<ContentSource['color'], WhiteboardToolSettingEntry[]>>(() => {
    return groupBy(
      getWhiteboardContentSources().map((s) => ({
        id: toStoredToolId(s.type),
        text: s.text,
        ariaLabel: CONTENT_PICKER_SOURCE_ARIA_LABEL_MAP_FOR_SETTINGS[s.type],
        isEnabled: isToolEnabled(s.type),
        icon: s.icon,
        iconColor: s.color,
      })),
      'iconColor',
    ) as Record<ContentSource['color'], WhiteboardToolSettingEntry[]>
  })
  // #endregion

  return {
    whiteboardToolSettings,
    webWhiteboardToolSettings,
    areAllToolsDisabled,
    areAllToolsEnabled,
    isPreviewToolSettingsDirty,
    allEnabledToolIds,
    displayedTldrawToolSettings,
    displayedPadletToolSettings,

    isToolEnabled,
    wasShapeCreatedFromAllowedTool,
    toggleToolSetting,
    disableAllTools,
    enableAllTools,
    setToolSettings,
    startPreviewingWhiteboardToolSettings,
    stopPreviewingWhiteboardToolSettings,
    saveToolSettingsToServer,
  }
})
