// @file Network requests related to wall section from surface

import { asciiSafeStringify } from '@@/bits/json_stringify'
import { getSubmissionRequestTokenHash } from '@@/bits/surface_share_links_helper'
import FetchableObject from '@@/surface/api/fetchable_object'
import { fetchJson } from '@@/surface/api_fetch'
import type { Id, Section } from '@@/types'
import { HTTPMethod } from '@padlet/fetch'

const API_VERSION = 5

class WallSectionApi extends FetchableObject {
  public static get url(): string {
    return `/api/${API_VERSION}/wall_sections`
  }

  public static get fetchUrl(): string {
    return `/api/${API_VERSION}/wall_sections?wall_id=\${ wall.id }`
  }

  public static async readAll(options, fetchOptions = {}): Promise<any> {
    return await fetchJson(this.buildUrl(this.fetchUrl, options), {
      method: HTTPMethod.get,
      query: getSubmissionRequestTokenHash(),
      ...fetchOptions,
    })
  }

  public static async patch(
    id: Id,
    sectionChanges: Partial<Section>,
    gapSize?: number,
    fetchOptions = {},
  ): Promise<Section> {
    return await fetchJson(`${this.url}/${id}`, {
      method: HTTPMethod.patch,
      body: asciiSafeStringify({ ...sectionChanges, gapSize }),
      ...fetchOptions,
    })
  }
}

export { WallSectionApi }
