// @file Network requests related to Wall User Contributor from surface
/* eslint-disable no-return-await, no-template-curly-in-string */
import FetchableObject from '@@/surface/api/fetchable_object'
import { fetchJson } from '@@/surface/api_fetch'
import type { WallId } from '@@/types'
import type { JsonAPIResponse, User as UserType } from '@padlet/arvo'
import { HTTPMethod } from '@padlet/fetch'

class WallUserContributorApi extends FetchableObject {
  private static get wallUserUrl(): string {
    return `/api/\${ apiVersion }/walls/\${ wallId }/contributors/\${userId}`
  }

  public static async read(userId: number | string, wallId: WallId): Promise<JsonAPIResponse<UserType>> {
    return await fetchJson(this.buildUrl(this.wallUserUrl, { apiVersion: 1, userId, wallId }), {
      method: HTTPMethod.get,
    })
  }
}

export { WallUserContributorApi }
