// @file Network requests related to wall access settings from surface

import { asciiSafeStringify } from '@@/bits/json_stringify'
import type { VersionedFetchOptions } from '@@/surface/api/fetchable_object'
import FetchableObject from '@@/surface/api/fetchable_object'
import { fetchJson } from '@@/surface/api_fetch'
import type { WallAccessSettings, WallId } from '@@/types'
import type { JsonAPIResponse } from '@padlet/arvo'
import { HTTPMethod } from '@padlet/fetch'

class WallAccessSettingsApi extends FetchableObject {
  public static async fetch(
    wallId: WallId,
    fetchOptions: VersionedFetchOptions = {},
  ): Promise<JsonAPIResponse<WallAccessSettings>> {
    const { apiVersion = 6, ...otherFetchOptions } = fetchOptions
    const path = apiVersion >= 8 ? 'access-settings' : 'access_settings'
    const url = `/api/${apiVersion}/walls/${wallId}/${path}`
    return await fetchJson(url, {
      method: HTTPMethod.get,
      ...otherFetchOptions,
    })
  }

  public static async update(
    wallId: WallId,
    accessSettings: Partial<WallAccessSettings>,
    fetchOptions: VersionedFetchOptions = {},
  ): Promise<JsonAPIResponse<WallAccessSettings>> {
    const { apiVersion = 6, ...otherFetchOptions } = fetchOptions
    const path = apiVersion >= 8 ? 'access-settings' : 'access_settings'
    const url = `/api/${apiVersion}/walls/${wallId}/${path}`
    const body = apiVersion >= 8 ? accessSettings : { data: { attributes: accessSettings } }
    return await fetchJson(url, {
      method: HTTPMethod.patch,
      body: asciiSafeStringify(body),
      ...otherFetchOptions,
    })
  }
}

export { WallAccessSettingsApi }
