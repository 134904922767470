// @file Network requests related to wall onboarding demo from surface

import { asciiSafeStringify } from '@@/bits/json_stringify'
import FetchableObject from '@@/surface/api/fetchable_object'
import { fetchJson } from '@@/surface/api_fetch'
import type { WallId } from '@@/types'
import type { JsonAPIResponse } from '@padlet/arvo'
import { HTTPMethod } from '@padlet/fetch'

const API_VERSION = 5

class WallOnboardingDemoApi extends FetchableObject {
  public static async updateCurrentStep(wallId: WallId, currentStep: string): Promise<JsonAPIResponse<any>> {
    return await fetchJson(`/api/${API_VERSION}/walls/${wallId}/onboarding-demo/update-current-step`, {
      method: HTTPMethod.post,
      body: asciiSafeStringify({ currentStep }),
    })
  }
}

export { WallOnboardingDemoApi }
