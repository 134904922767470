// @file Network requests related to wall follows from surface

import FetchableObject from '@@/surface/api/fetchable_object'
import { fetchJson } from '@@/surface/api_fetch'
import type { Id, WallFollowApiResponse } from '@@/types'
import type { JsonAPIResponse } from '@padlet/arvo'
import { HTTPMethod } from '@padlet/fetch'

const API_VERSION = 5

class WallFollowsApi extends FetchableObject {
  public static async create(fetchOptions = {}): Promise<JsonAPIResponse<WallFollowApiResponse>> {
    return await fetchJson(`/api/${API_VERSION}/notification_settings/wall_follows`, {
      method: HTTPMethod.post,
      ...fetchOptions,
    })
  }

  public static async update(
    { wallFollowId }: { wallFollowId: Id },
    fetchOptions = {},
  ): Promise<JsonAPIResponse<WallFollowApiResponse>> {
    return await fetchJson(`/api/${API_VERSION}/notification_settings/wall_follows/${String(wallFollowId)}`, {
      method: HTTPMethod.patch,
      ...fetchOptions,
    })
  }
}

export { WallFollowsApi }
