// @file Network requests related to content moderation

import { asciiSafeStringify } from '@@/bits/json_stringify'
import { poll } from '@@/bits/polling'
import type { VersionedFetchOptions } from '@@/surface/api/fetchable_object'
import FetchableObject from '@@/surface/api/fetchable_object'
import { fetchJson } from '@@/surface/api_fetch'
import type { JsonApiData, JsonApiResponse, ModerateUserNameApiResponse } from '@@/types'
import { HTTPMethod } from '@padlet/fetch'

class ContentModerationApi extends FetchableObject {
  public static async moderateUserName(
    createParams: { name: string },
    fetchOptions: VersionedFetchOptions = { apiVersion: 1 },
  ): Promise<{ userNameHasViolation: null | boolean }> {
    try {
      const response = await poll({
        pollingUrl: '/api/1/content-moderation/user-name',
        validationCallback: (response: JsonApiResponse<ModerateUserNameApiResponse>) =>
          (response.data as JsonApiData<ModerateUserNameApiResponse>)?.attributes?.userNameHasViolation != null,
        options: {
          intervalSecs: 1,
          maxAttempts: 5,
          method: HTTPMethod.post,
          fetchOptions: {
            body: asciiSafeStringify({
              data: { attributes: { name: createParams.name } },
            }),
          },
        },
      })

      return response.data.attributes
    } catch (e) {
      if (e == null) {
        return { userNameHasViolation: null }
      }
      throw e
    }
  }

  public static async moderateSandboxText(
    wallHashid: string,
    params: { shape_id: string },
  ): Promise<{ userNameHasViolation: null | boolean }> {
    return await fetchJson(`/api/7/content-moderation/whiteboard/${wallHashid}/text`, {
      method: HTTPMethod.post,
      body: asciiSafeStringify({ data: { attributes: params } }),
    })
  }
}

export { ContentModerationApi }
