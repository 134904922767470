// @file Network requests related to powwow

import { asciiSafeStringify } from '@@/bits/json_stringify'
import type { VersionedFetchOptions } from '@@/surface/api/fetchable_object'
import FetchableObject from '@@/surface/api/fetchable_object'
import { fetchJson } from '@@/surface/api_fetch'
import type { CreateSessionUserApiResponse, User as UserSnakeCase, UserId, WallId } from '@@/types'
import type { JsonAPIResource } from '@padlet/arvo'
import { HTTPMethod } from '@padlet/fetch'
import type { ArvoConfig } from '@padlet/universal-post-editor'

class PowwowApi extends FetchableObject {
  private static get updateSessionUserUrl(): string {
    return `/api/\${ apiVersion }/session/users/\${ userId }`
  }

  private static get createSessionUserUrl(): string {
    return `/api/\${ apiVersion }/session/users`
  }

  private static get getCurrentUserUrl(): string {
    return `/api/\${ apiVersion }/session`
  }

  private static get getArvoConfigUrl(): string {
    return `/api/\${ apiVersion }/session/arvo-config`
  }

  public static async updateSessionUserName(
    updateParams: { userId: UserId; name: string; wallId: WallId },
    fetchOptions: VersionedFetchOptions = { apiVersion: 1 },
  ): Promise<UserSnakeCase> {
    const { apiVersion, ...otherFetchOptions } = fetchOptions
    const response = await fetchJson(
      this.buildUrl(this.updateSessionUserUrl, { apiVersion, userId: updateParams.userId }),
      {
        ...otherFetchOptions,
        method: HTTPMethod.patch,
        body: asciiSafeStringify({ data: { attributes: { name: updateParams.name, wallId: updateParams.wallId } } }),
      },
    )
    const user = (response.data as JsonAPIResource<UserSnakeCase>).attributes
    return user
  }

  public static async createSessionUser(
    createParams: { name?: string } = {},
    fetchOptions: VersionedFetchOptions = { apiVersion: 1 },
  ): Promise<CreateSessionUserApiResponse> {
    const { apiVersion, ...otherFetchOptions } = fetchOptions

    const response = await fetchJson(
      this.buildUrl(this.createSessionUserUrl, {
        apiVersion,
      }),
      {
        ...otherFetchOptions,
        method: HTTPMethod.post,
        body: asciiSafeStringify({
          data: { attributes: { name: createParams.name } },
        }),
      },
    )
    const user = (response.data as JsonAPIResource<CreateSessionUserApiResponse>).attributes.user
    const arvoConfig = (response.data as JsonAPIResource<CreateSessionUserApiResponse>).attributes.arvoConfig
    return { user, arvoConfig }
  }

  public static async getCurrentUser(fetchOptions: VersionedFetchOptions = { apiVersion: 1 }): Promise<UserSnakeCase> {
    const { apiVersion, ...otherFetchOptions } = fetchOptions
    const response = await fetchJson(this.buildUrl(this.getCurrentUserUrl, { apiVersion }), {
      ...otherFetchOptions,
      method: HTTPMethod.get,
    })
    return (response.data as JsonAPIResource<UserSnakeCase>).attributes
  }

  public static async getArvoConfig(fetchOptions: VersionedFetchOptions = { apiVersion: 1 }): Promise<ArvoConfig> {
    const { apiVersion, ...otherFetchOptions } = fetchOptions
    const response = await fetchJson(this.buildUrl(this.getArvoConfigUrl, { apiVersion }), {
      ...otherFetchOptions,
      method: HTTPMethod.get,
    })
    return (response.data as JsonAPIResource<ArvoConfig>).attributes
  }
}

export { PowwowApi }
