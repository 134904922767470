// @file Network requests related to remake panel from surface

import FetchableObject from '@@/surface/api/fetchable_object'
import { fetchJson } from '@@/surface/api_fetch'
import type { HashId } from '@@/types'
import { HTTPMethod } from '@padlet/fetch'

class RemakePanelApi extends FetchableObject {
  static API_VERSION = 9
  public static async fetch(wallHashid: HashId, jsonData: any): Promise<any> {
    return await fetchJson(`api/${this.API_VERSION}/walls/${wallHashid}/remake`, {
      method: HTTPMethod.post,
      jsonData,
    })
  }
}

export { RemakePanelApi }
