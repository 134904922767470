// @file Surface attachments store
import { getDisplayAttributes, memoizedArvoFetchLink } from '@@/bits/beethoven'
import { captureNonNetworkFetchError } from '@@/bits/error_tracker'
import { vSet } from '@@/bits/vue'
import { useSurfacePostsStore } from '@@/pinia/surface_posts'
import { WishApi } from '@@/surface/api/wish'
import { FetchError } from '@@/surface/api_fetch'
import type { AttachmentProps, Cid, Post } from '@@/types'
import type { JsonAPIResource, Link } from '@padlet/arvo'
import type { BeethovenDisplayAttributes } from '@padlet/beethoven-client'
import { isEmpty } from 'lodash-es'
import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useSurfaceAttachmentsStore = defineStore('surfaceAttachments', () => {
  const surfacePostsStore = useSurfacePostsStore()

  /* ---------------------- */
  /* STATE                  */
  /* ---------------------- */

  /**
   * A map of `Link` object by its URL.
   * The `Link` object is the serialization of the link record in the `links` table.
   */
  const linkAttributesByUrl = ref<Record<string, Link>>({})
  /**
   * A map of `BeethovenDisplayAttributes` by its URL.
   * This `BeethovenDisplayAttributes` object is a transformation of the `Link` object
   * through the `getDisplayAttributes` function from @padlet/beethoven-client.
   * However, we shouldn't store this data in Pinia because display attributes are
   * dependent on the post size. It should be up to the component to get the display
   * attributes suitable for its use case.
   * @deprecated Should migrate to `linkAttributesByUrl`.
   */
  const linkDisplayAttributes = ref<Record<string, BeethovenDisplayAttributes | null>>({})
  const surfacePreviewImageLoaded = ref<Record<string, boolean>>({})
  const fileUploadingToPickerBox = ref<File | null>(null)

  /* ---------------------- */
  /* GETTER FUNCTIONS       */
  /* ---------------------- */

  const getLinkAttributesForUrl = (url: string): Link | null => {
    return linkAttributesByUrl.value[url] ?? null
  }

  const getLinkDisplayAttributesForUrl = (url: string): BeethovenDisplayAttributes | null => {
    return linkDisplayAttributes.value[url] ?? null
  }

  const getLinkDisplayAttributesForPost = (postCid: string): BeethovenDisplayAttributes | null => {
    const post = surfacePostsStore.postEntitiesByCid[postCid]
    if (post == null) return null
    const attachmentUrl = post.wish_content?.attachment_props?.url ?? post.attachment
    return !isEmpty(attachmentUrl) ? getLinkDisplayAttributesForUrl(attachmentUrl) : null
  }

  const getAttachmentPropsFromPost = (postCid: string): AttachmentProps | null => {
    const post = surfacePostsStore.postEntitiesByCid[postCid]
    if (post == null) return null
    return post.wish_content?.attachment_props ?? null
  }

  const isSurfacePreviewImageLoadedForUrl = (url: string): boolean => {
    return surfacePreviewImageLoaded[url] ?? false
  }

  /* ---------------------- */
  /* ACTIONS                */
  /* ---------------------- */

  const fetchAndStoreLinkAttributes = async (url: string): Promise<Link | null> => {
    try {
      const link = await memoizedArvoFetchLink(url)
      const attributes = (link.data as JsonAPIResource<Link>).attributes
      vSet(linkAttributesByUrl.value, url, attributes)
      return attributes
    } catch (error) {
      captureNonNetworkFetchError(error, { source: 'fetchAndStoreLinkAttributes' })
      return null
    }
  }

  const uploadFileToPickerBox = (file: File | null): void => {
    fileUploadingToPickerBox.value = file
  }
  const completeUploadingFileToPickerBox = (): void => {
    fileUploadingToPickerBox.value = null
  }

  const updateLinkDisplayAttributes = ({
    url,
    attributes,
  }: {
    url: string
    attributes: BeethovenDisplayAttributes | null
  }): void => {
    linkDisplayAttributes.value = {
      ...linkDisplayAttributes.value,
      [url]: attributes,
    }
  }

  const cancelUploadFileToPickerBox = (): void => {
    fileUploadingToPickerBox.value = null
  }

  const setSurfacePreviewImageLoaded = ({ url }: { url?: string }): void => {
    if (url != null) {
      surfacePreviewImageLoaded.value = {
        ...surfacePreviewImageLoaded.value,
        [url]: true,
      }
    }
  }

  const fetchDisplayAttributesForPost = async (postCid: Cid): Promise<void> => {
    const post = surfacePostsStore.postEntitiesByCid[postCid]
    if (post == null) return

    const attachmentUrl = post.wish_content?.attachment_props?.url ?? post.attachment
    if (isEmpty(attachmentUrl) || linkDisplayAttributes.value[attachmentUrl] != null) return
    const displayAttributes = await getDisplayAttributes(attachmentUrl)
    updateLinkDisplayAttributes({ url: attachmentUrl, attributes: displayAttributes })
  }

  // method to fetch the user-specific uploaded attachment permalink and update the post
  const fetchAndUpdateAttachmentPermalinkForPost = async (post: Post): Promise<void> => {
    if (post.hashid == null || post.wish_content?.attachment_props?.signed_url == null) {
      return
    }
    try {
      const attachmentPermalink = await WishApi.fetchAttachmentPermalink(post.hashid)
      post.wish_content.attachment_props.hotlink = attachmentPermalink
    } catch (error) {
      // In case of error, we fallback to the signed attachment URL.
      post.wish_content.attachment_props.hotlink = post.wish_content.attachment_props.signed_url
      if (error instanceof FetchError && error.status === 422) {
        // We don't want to capture 422 errors because they are expected when the
        // backend is not updated with the attachment details yet.
        return
      }
      captureNonNetworkFetchError(error, { source: 'fetchAndUpdateAttachmentPermalinkForPost' })
    }
  }

  return {
    // state
    linkAttributesByUrl,
    linkDisplayAttributes,
    surfacePreviewImageLoaded,
    fileUploadingToPickerBox,

    // getter functions
    getLinkAttributesForUrl,
    getLinkDisplayAttributesForUrl,
    getLinkDisplayAttributesForPost,
    getAttachmentPropsFromPost,
    isSurfacePreviewImageLoadedForUrl,

    // actions
    fetchAndStoreLinkAttributes,
    uploadFileToPickerBox,
    cancelUploadFileToPickerBox,
    completeUploadingFileToPickerBox,
    updateLinkDisplayAttributes,
    setSurfacePreviewImageLoaded,
    fetchDisplayAttributesForPost,
    fetchAndUpdateAttachmentPermalinkForPost,
  }
})
