// @file Network requests related to wall read token from surface

import type { VersionedFetchOptions } from '@@/surface/api/fetchable_object'
import FetchableObject from '@@/surface/api/fetchable_object'
import { fetchJson } from '@@/surface/api_fetch'
import type { WallAnalytics as WallAnalyticsType } from '@@/types'
import type { JsonAPIResponse } from '@padlet/arvo'
import { HTTPMethod } from '@padlet/fetch'

class WallAnalyticsApi extends FetchableObject {
  public static get url(): string {
    return `/api/\${ apiVersion }/walls/\${ wallHashid }/analytics`
  }

  public static async fetch(
    wallHashid: string,
    fetchOptions: VersionedFetchOptions = { apiVersion: 9 },
  ): Promise<JsonAPIResponse<WallAnalyticsType>> {
    const { apiVersion, ...otherFetchOptions } = fetchOptions
    return await fetchJson(this.buildUrl(this.url, { wallHashid, apiVersion }), {
      method: HTTPMethod.get,
      ...otherFetchOptions,
    })
  }
}

export { WallAnalyticsApi }
