// @file Network requests related to Wishes from surface

import { asciiSafeStringify } from '@@/bits/json_stringify'
import { getSubmissionRequestTokenHash } from '@@/bits/surface_share_links_helper'
import FetchableObject from '@@/surface/api/fetchable_object'
import { fetchJson, fetchJsonWithRetries } from '@@/surface/api_fetch'
import type { HashId, Post } from '@@/types'
import type { JsonAPIResponse, Wish as WishType } from '@padlet/arvo'
import { HTTPMethod } from '@padlet/fetch'

const API_VERSION = 9

class WishApi extends FetchableObject {
  public static get url(): string {
    return `/api/${API_VERSION}/wishes`
  }

  public static get fetchSingleWithHashidUrl(): string {
    return `/api/${API_VERSION}/wishes/\${ wishHashid }`
  }

  public static get fetchUrl(): string {
    return `/api/${API_VERSION}/wishes?wall_hashid=\${ wallHashid }&page_start=\${ pageStart }`
  }

  public static readonly fetchWithLinksUrl = `/api/10/wishes?wall_hashid=\${ wallHashid }&page_start=\${ pageStart }`

  public static readonly fetchAttachmentPermalinkUrl = `/api/10/wishes/\${ wishHashid }/attachment-permalink`

  public static get transferUrl(): string {
    return `/api/${API_VERSION}/wishes/\${ wishHashid }/transfer`
  }

  public static get copyUrl(): string {
    return `/api/${API_VERSION}/wishes/\${ wishHashid }/copy`
  }

  public static get approveUrl(): string {
    return `/api/${API_VERSION}/wishes/\${ wishHashid }/approve`
  }

  public static get rejectUrl(): string {
    return `/api/${API_VERSION}/wishes/\${ wishHashid }/reject`
  }

  public static async fetchSingleWithHashid({ wishHashid }, fetchOptions = {}): Promise<JsonAPIResponse<WishType>> {
    return await fetchJson(this.buildUrl(this.fetchSingleWithHashidUrl, { wishHashid }), {
      method: HTTPMethod.get,
      ...fetchOptions,
    })
  }

  public static async fetch(
    query: { wallHashid: number; pageStart?: number },
    fetchOptions = {},
  ): Promise<JsonAPIResponse<WishType>> {
    return await fetchJson(
      this.buildUrl(this.fetchUrl, {
        wallHashid: query.wallHashid,
        pageStart: query.pageStart == null ? '' : query.pageStart,
      }),
      {
        method: HTTPMethod.get,
        ...fetchOptions,
      },
    )
  }

  public static async fetchWithLinks(
    query: { wallHashid: number; pageStart?: number },
    fetchOptions = {},
  ): Promise<JsonAPIResponse<WishType>> {
    return await fetchJson(
      this.buildUrl(this.fetchWithLinksUrl, {
        wallHashid: query.wallHashid,
        pageStart: query.pageStart == null ? '' : query.pageStart,
      }),
      {
        method: HTTPMethod.get,
        ...fetchOptions,
      },
    )
  }

  public static async fetchAttachmentPermalink(wishHashid: HashId): Promise<string> {
    const jsonResponse = await fetchJson(
      this.buildUrl(this.fetchAttachmentPermalinkUrl, {
        wishHashid,
      }),
      {
        method: HTTPMethod.get,
      },
    )
    return jsonResponse.permalink
  }

  public static async transfer(
    {
      wishHashid,
      wallHashid,
      wallSectionHashid,
      wallTitle,
      wallDescription,
      libraryId,
      transferCustomPropValuesAcrossWalls,
    },
    fetchOptions = {},
  ): Promise<any> {
    return await fetchJson(this.buildUrl(this.transferUrl, { wishHashid }), {
      query: { transfer_custom_prop_values_across_walls: transferCustomPropValuesAcrossWalls },
      method: HTTPMethod.put,
      body: asciiSafeStringify({
        wall_hashid: wallHashid,
        wall_section_hashid: wallSectionHashid,
        wall_title: wallTitle,
        wall_description: wallDescription,
        library_id: libraryId,
      }),
      ...fetchOptions,
    })
  }

  public static async copy(
    {
      wishHashid,
      wallHashid,
      wallSectionHashid,
      wallTitle,
      wallDescription,
      postPosition,
      libraryId,
      transferCustomPropValuesAcrossWalls,
    },
    fetchOptions = {},
  ): Promise<any> {
    return await fetchJson(this.buildUrl(this.copyUrl, { wishHashid }), {
      query: { transfer_custom_prop_values_across_walls: transferCustomPropValuesAcrossWalls },
      method: HTTPMethod.post,
      body: asciiSafeStringify({
        wall_hashid: wallHashid,
        wall_section_hashid: wallSectionHashid,
        wall_title: wallTitle,
        wall_description: wallDescription,
        library_id: libraryId,
        ...postPosition,
      }),
      ...fetchOptions,
    })
  }

  public static async create(obj, fetchOptions = {}): Promise<any> {
    return await fetchJson(`${this.url}`, {
      method: HTTPMethod.post,
      body: asciiSafeStringify(obj),
      query: getSubmissionRequestTokenHash(),
      ...fetchOptions,
    })
  }

  public static async approve({ wishHashid }: { wishHashid: HashId }, fetchOptions = {}): Promise<any> {
    return await fetchJson(this.buildUrl(this.approveUrl, { wishHashid }), {
      method: HTTPMethod.put,
      body: asciiSafeStringify({}),
      ...fetchOptions,
    })
  }

  public static async reject({ wishHashid }: { wishHashid: HashId }, fetchOptions = {}): Promise<any> {
    return await fetchJson(this.buildUrl(this.rejectUrl, { wishHashid }), {
      method: HTTPMethod.put,
      ...fetchOptions,
    })
  }

  public static async update(obj: Post, fetchOptions = {}): Promise<any> {
    return await fetchJsonWithRetries(`${this.url}/${obj.hashid ?? ''}`, {
      method: HTTPMethod.put,
      body: asciiSafeStringify(obj),
      ...fetchOptions,
    })
  }

  public static async delete(obj: { wishHashid: HashId }, fetchOptions = {}): Promise<any> {
    return await fetchJson(`${this.url}/${obj.wishHashid}`, {
      method: HTTPMethod.delete,
      ...fetchOptions,
    })
  }
}

export { WishApi }
