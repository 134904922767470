// @file Surface store
import { useCommentsStore } from '@@/pinia/surface_comments'
import type { Comment, CommentId } from '@@/types'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useSurfaceExpandedCommentStore = defineStore('surface_expanded_comment', () => {
  const commentsStore = useCommentsStore()

  const activeExpandedCommentImageCommentId = ref<CommentId | null>(null)

  const xExpandedCommentImagePanel = computed(() => activeExpandedCommentImageCommentId.value != null)
  const activeExpandedCommentImageComment = computed<Comment | null>(() => {
    if (activeExpandedCommentImageCommentId.value == null) return null
    return commentsStore.commentEntities[activeExpandedCommentImageCommentId.value]
  })
  const openExpandedCommentImagePanel = (commentId: CommentId): void => {
    activeExpandedCommentImageCommentId.value = commentId
  }

  const closeExpandedCommentImagePanel = (): void => {
    activeExpandedCommentImageCommentId.value = null
  }

  return {
    // State
    activeExpandedCommentImageCommentId,
    activeExpandedCommentImageComment,

    // Getters
    xExpandedCommentImagePanel,
    // Actions
    openExpandedCommentImagePanel,
    closeExpandedCommentImagePanel,
  }
})
