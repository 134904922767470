// @file Vuex Store for handling comment
import { isAppCapableOf } from '@@/bits/app_can'
import device from '@@/bits/device'
import { captureFetchException } from '@@/bits/error_tracker'
import { isAppUsing } from '@@/bits/flip'
import { ACCESS_WITHDRAWN } from '@@/bits/snackbar_helper'
import { vDel } from '@@/bits/vue'
import { useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import { useNativeAppStore } from '@@/pinia/native_app'
import { useCommentsStore } from '@@/pinia/surface_comments'
import { CommentApi, CommentV2Api } from '@@/surface/api/comment'
import type { Comment, CommentId, Id } from '@@/types'
import { reconcileCommentsAfterFetch, reconcileCommentsForPostAfterFetch } from '@@/vuexstore/helpers/comment'
import type { RootState } from '@@/vuexstore/surface/types'
import type { JsonAPIResponse } from '@padlet/arvo'
import type { Module } from 'vuex'

interface CommentState {
  isIntialCommentsLoadDone: boolean
  commentEntities: { [id: number]: Comment }
  pendingCommentIds: CommentId[]
  commentBeingEditedId: CommentId | null
  actionMenuCommentId: CommentId | null
  activeNewCommentPostId: Id | null
  // the latest timestamp where user actively types in the inline comment input (as opposed to the comment modal)
  inlineCommentInputLastTypedInMs: number
}

const defaultState = (): CommentState => ({
  isIntialCommentsLoadDone: false,
  commentEntities: {},
  pendingCommentIds: [],
  commentBeingEditedId: null,
  actionMenuCommentId: null,
  activeNewCommentPostId: null,
  inlineCommentInputLastTypedInMs: 0,
})

const CommentModule: Module<CommentState, RootState> = {
  namespaced: true,
  state: defaultState,
  getters: {
    // getters that return comment data should return nthing until the initial paginated data load is done
    commentIds: (state, getters): string[] => {
      if (!getters.isIntialCommentsLoadDone) {
        return []
      }
      return Object.keys(state.commentEntities)
    },
    commentIdsByPost: (state, getters): { [wishId: number]: CommentId[] } => {
      const commentIdsByPost = {}
      getters.commentIds.forEach((rid) => {
        const r = state.commentEntities[rid]
        commentIdsByPost[r.wish_id] = commentIdsByPost[r.wish_id] || []
        commentIdsByPost[r.wish_id].push(rid)
      })
      return commentIdsByPost
    },
    commentsByPost: (state, getters): { [wishId: number]: Comment[] } => {
      const commentsByPost = {}
      getters.commentIds.forEach((rid) => {
        const r = state.commentEntities[rid]
        commentsByPost[r.wish_id] = commentsByPost[r.wish_id] || []
        commentsByPost[r.wish_id].push(r)
      })
      return commentsByPost
    },
    inlineCommentInputLastTypedInMs(state): number {
      return state.inlineCommentInputLastTypedInMs
    },
    isIntialCommentsLoadDone: (state): boolean => state.isIntialCommentsLoadDone,
  },
  mutations: {
    FETCH_COMMENTS_SUCCESS(state, newCommentEntities): void {
      state.commentEntities = newCommentEntities || {}
    },
    FETCH_COMMENTS_SUCCESS_FOR_POST(state, { wishId, newCommentEntities }): void {
      state.commentEntities = {
        ...Object.keys(state.commentEntities).reduce((acc, key) => {
          const comment = state.commentEntities[key]
          if (comment.wish_id !== wishId) {
            acc[key] = comment
          }
          return acc
        }, {}),
        ...newCommentEntities,
      }
    },
    FETCH_INITIAL_PAGINATED_COMMENTS_DONE(state): void {
      state.isIntialCommentsLoadDone = true
      state.pendingCommentIds = []
    },
    UPDATE_COMMENT(state, { id, obj }): void {
      if (id < 0 || !obj) {
        return
      }
      const updatedEntity = {
        ...state.commentEntities[id],
        ...obj,
      }
      state.commentEntities = {
        ...state.commentEntities,
        [id]: updatedEntity,
      }
    },
    ADD_PENDING_COMMENT(state, pendingComment): void {
      state.commentEntities = {
        ...state.commentEntities,
        [pendingComment.id]: pendingComment,
      }
      state.pendingCommentIds.push(pendingComment.id)
    },
    REMOVE_PENDING_COMMENT(state, { commentId }): void {
      const isCommentPending = state.pendingCommentIds.includes(commentId)
      if (!isCommentPending) return
      state.pendingCommentIds = state.pendingCommentIds.filter((id) => id !== commentId)
      vDel(state.commentEntities, commentId)
    },
    REVERT_PENDING_COMMENT(state, { commentId, postCid }): void {
      const isCommentPending = state.pendingCommentIds.includes(commentId)
      if (!isCommentPending) return
      state.pendingCommentIds = state.pendingCommentIds.filter((id) => id !== commentId)
      vDel(state.commentEntities, commentId)
    },
    ADD_COMMENT(state, { comment, tempId }): void {
      // Remove old comment if present
      if (state.commentEntities[tempId]) vDel(state.commentEntities, tempId)
      if (state.pendingCommentIds.includes(tempId)) {
        state.pendingCommentIds = state.pendingCommentIds.filter((id) => id !== tempId)
      }
      // Add updated comment
      state.commentEntities = { ...state.commentEntities, [comment.id]: comment }
    },
    REMOVE_COMMENT(state, id): void {
      const newCommentEntities = { ...state.commentEntities }
      delete newCommentEntities[id]
      state.commentEntities = newCommentEntities
    },
    EDIT_COMMENT_REMOTE(state, comment): void {
      const originalComment = state.commentEntities[comment.id]
      // real time can be out of sync sometimes.
      // here we make sure we are not updating a new copy with a stale one
      if (originalComment && originalComment.updated_at <= comment.updated_at) {
        state.commentEntities = {
          ...state.commentEntities,
          [comment.id]: comment,
        }
      }
    },
    START_EDITING_COMMENT(state, commentId): void {
      state.commentBeingEditedId = commentId
    },
    STOP_EDITING_COMMENT(state): void {
      state.commentBeingEditedId = null
    },
    SHOW_COMMENT_ACTION_MENU(state, commentId): void {
      if (state.actionMenuCommentId) {
        state.actionMenuCommentId = null
      } else {
        state.actionMenuCommentId = commentId
      }
    },
    HIDE_COMMENT_ACTION_MENU(state): void {
      state.actionMenuCommentId = null
    },
    START_WRITING_NEW_COMMENT(state, postId): void {
      state.activeNewCommentPostId = postId
    },
    STOP_WRITING_NEW_COMMENT(state, postId): void {
      if (state.activeNewCommentPostId === postId) {
        state.activeNewCommentPostId = null
      }
    },
    UPDATE_INLINE_COMMENT_INPUT_LAST_TYPED_IN_MS(state, newTimestamp): void {
      state.inlineCommentInputLastTypedInMs = newTimestamp
    },
  },
  actions: {
    /* ------------
     * CRUD
     * ------------ */
    // shouldResetState is a flag to reset the stored comment data
    // currently it is only set to true for the initial comment load when a Padlet page is first loaded
    async fetchComments({ dispatch, rootGetters, rootState }, { wishId, wishHashid, shouldResetState }): Promise<void> {
      let fetchedData
      try {
        if (!rootState.shouldFallbackWsDataFetchToRest && isAppUsing('realtimeFetching')) {
          fetchedData = await dispatch('realtime/fetchComments', { wishId, wishHashid }, { root: true })
        } else if (device.app && !isAppCapableOf('commentModeration')) {
          fetchedData = await CommentApi.fetch({ wallId: rootGetters.wallId, wishId })
        } else {
          fetchedData = await CommentV2Api.fetch({ wallHashid: rootGetters.wallHashid, wishHashid })
        }
      } catch (e: any) {
        // Don't capture exception when it's 401, show a snackbar instead
        if (e.status === 401) {
          useGlobalSnackbarStore().setSnackbar(ACCESS_WITHDRAWN)
        } else {
          captureFetchException(e, { source: 'fetchComments' })
        }
        return
      }
      const { data, meta } = fetchedData
      const nextPageCursor = meta?.next
      void dispatch('fetchCommentsSuccess', {
        newComments: data,
        wishId,
        isFirstPage: true,
        isLastPage: nextPageCursor == null || nextPageCursor === '',
        shouldResetState,
      })

      if (nextPageCursor != null && nextPageCursor !== '') {
        void dispatch('fetchCommentsForPage', { wishId, wishHashid, pageStart: nextPageCursor })
      }
    },
    async fetchCommentsForPage({ dispatch, rootGetters, rootState }, { wishId, wishHashid, pageStart }): Promise<void> {
      let commentPage
      try {
        if (!rootState.shouldFallbackWsDataFetchToRest && isAppUsing('realtimeFetching')) {
          commentPage = await dispatch('realtime/fetchComments', { wishId, wishHashid, pageStart }, { root: true })
        } else if (device.app && !isAppCapableOf('commentModeration')) {
          commentPage = await CommentApi.fetch({ wallId: rootGetters.wallId, wishId, pageStart })
        } else {
          commentPage = await CommentV2Api.fetch({ wallHashid: rootGetters.wallHashid, wishHashid, pageStart })
        }
      } catch (e: any) {
        captureFetchException(e, { source: 'fetchCommentsForPage' })
        return
      }
      const { data, meta } = commentPage
      const nextPageCursor = meta?.next
      void dispatch('fetchCommentsSuccess', {
        newComments: data,
        wishId,
        isFirstPage: meta?.isFirstPage,
        isLastPage: nextPageCursor == null || nextPageCursor === '',
        shouldResetState: false,
      })
      if (nextPageCursor != null && nextPageCursor !== '') {
        void dispatch('fetchCommentsForPage', { wishId, wishHashid, pageStart: nextPageCursor })
      }
    },
    fetchCommentsSuccess(context, { newComments, wishId, isFirstPage, isLastPage, shouldResetState }): void {
      const newCommentEntities =
        wishId != null
          ? reconcileCommentsForPostAfterFetch({
              context,
              newComments,
              wishId,
              isFirstPage,
              isLastPage,
              shouldResetState,
            })
          : reconcileCommentsAfterFetch({
              context,
              newComments,
              isFirstPage,
              isLastPage,
              shouldResetState,
            })
      const { commit } = context
      if (wishId == null) {
        commit('FETCH_COMMENTS_SUCCESS', newCommentEntities)
      } else {
        commit('FETCH_COMMENTS_SUCCESS_FOR_POST', { wishId, newCommentEntities })
      }

      if (isLastPage) {
        commit('FETCH_INITIAL_PAGINATED_COMMENTS_DONE')
      }

      void useNativeAppStore().postSurfaceState()
    },
    refreshComments({ dispatch }, payload: JsonAPIResponse<Comment>): void {
      const { data, meta } = payload
      void dispatch('fetchCommentsSuccess', {
        newComments: data,
        isFirstPage: meta?.isFirstPage,
        isLastPage: meta?.next == null || meta?.next === '',
        shouldResetState: false,
      })
    },
    deleteComment({ commit, state }, { commentId }): void {
      const existingComment = state.commentEntities[commentId]
      const commentsStore = useCommentsStore()
      commentsStore.stopEditingComment(commentId)
      if (existingComment) {
        commit('REMOVE_COMMENT', existingComment.id)
        CommentApi.delete(existingComment)
      }
      void useNativeAppStore().postSurfaceState()
    },
    startWritingNewComment({ commit }, { postId }): void {
      commit('START_WRITING_NEW_COMMENT', postId)
    },
    stopWritingNewComment({ commit }, { postId }): void {
      commit('STOP_WRITING_NEW_COMMENT', postId)
    },

    /* ------------
     * REMOTE
     * ------------ */
    newCommentRemote({ commit, dispatch }, comment): void {
      commit('ADD_COMMENT', { comment })
      void useNativeAppStore().postSurfaceState()
    },
    editCommentRemote({ commit, state, dispatch }, comment): void {
      const existingComment = state.commentEntities[comment.id]
      if (existingComment == null) {
        // For auto-moderated walls, a newly created comment is only published by realtime to the author.
        // Once it is auto-moderated, edit_comment is published to non-authors so we need to create the comment now.
        void dispatch('newCommentRemote', comment)
        return
      }
      commit('EDIT_COMMENT_REMOTE', comment)
      void useNativeAppStore().postSurfaceState()
    },
    deleteCommentRemote({ commit, state }, comment): void {
      const commentsStore = useCommentsStore()
      if (commentsStore.isCommentBeingEdited(comment.id)) {
        commentsStore.stopEditingComment(comment.id)
      }
      if (state.actionMenuCommentId && state.actionMenuCommentId === comment.id) {
        commit('HIDE_COMMENT_ACTION_MENU')
      }
      commit('REMOVE_COMMENT', comment.id)
      void useNativeAppStore().postSurfaceState()
    },

    /* ------------
     * ACTION MENU
     * ------------ */
    showCommentActionMenu({ commit }, { commentId }): void {
      commit('SHOW_COMMENT_ACTION_MENU', commentId)
    },
    hideCommentActionMenu({ commit }): void {
      commit('HIDE_COMMENT_ACTION_MENU')
    },

    /* ------------
     * Update the timestamp where user last actively type in the inline comment input
     * ------------ */
    updateInlineCommentInputLastTypedInMs({ commit }): void {
      commit('UPDATE_INLINE_COMMENT_INPUT_LAST_TYPED_IN_MS', Date.now())
    },
  },
}

export default CommentModule
export type { CommentState }
