// @file Network requests related to section breakout from surface

import type { VersionedFetchOptions } from '@@/surface/api/fetchable_object'
import FetchableObject from '@@/surface/api/fetchable_object'
import { fetchJson } from '@@/surface/api_fetch'
import type { WallId, WallSectionBreakoutLink } from '@@/types'
import type { JsonAPIResource } from '@padlet/arvo'
import { HTTPMethod } from '@padlet/fetch'

class SectionBreakoutApi extends FetchableObject {
  public static get sectionBreakoutUrl(): string {
    return `/api/\${ apiVersion }/walls/\${ wallId }/section-breakout\${ method }`
  }

  public static async list(
    wallId: WallId,
    fetchOptions: VersionedFetchOptions = { apiVersion: 1 },
  ): Promise<WallSectionBreakoutLink[] | undefined> {
    const { apiVersion, ...restFetchOptions } = fetchOptions

    const response = await fetchJson(this.buildUrl(this.sectionBreakoutUrl, { wallId, apiVersion, method: '' }), {
      method: HTTPMethod.get,
      ...restFetchOptions,
    })

    return response?.data?.map((d: JsonAPIResource<WallSectionBreakoutLink>): WallSectionBreakoutLink => d.attributes)
  }
}

export { SectionBreakoutApi }
