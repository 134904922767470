// @file Network requests related to folders from surface

import FetchableObject from '@@/surface/api/fetchable_object'
import { fetchJson } from '@@/surface/api_fetch'
import type { Folder as FolderType } from '@@/types'
import type { JsonAPIResponse } from '@padlet/arvo'
import { HTTPMethod } from '@padlet/fetch'

const API_VERSION = 5

class FolderApi extends FetchableObject {
  public static async create(attributes: Pick<FolderType, 'name'>): Promise<JsonAPIResponse<FolderType>> {
    return await fetchJson(`/api/${API_VERSION}/folders`, {
      method: HTTPMethod.post,
      jsonData: {
        data: {
          type: 'folder',
          attributes,
        },
      },
    })
  }
}

export { FolderApi }
