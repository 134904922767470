// @file Network requests related to wall attachments from surface

import { useSurfaceStore } from '@@/pinia/surface'
import FetchableObject from '@@/surface/api/fetchable_object'
import { HTTPMethod } from '@padlet/fetch'

class WallAttachmentsApi extends FetchableObject {
  public static get fetchDownloadAttachmentsZipUrl(): string {
    return `/padlets/\${ publicKey }/exports/zip-attachments`
  }

  public static async downloadAttachmentsZip(): Promise<any> {
    return await fetch(
      this.buildUrl(this.fetchDownloadAttachmentsZipUrl, {
        publicKey: useSurfaceStore().publicKey,
      }),
      {
        method: HTTPMethod.get,
      },
    )
  }
}

export { WallAttachmentsApi }
