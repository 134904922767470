// @file Surface posts store
import { trackEvent } from '@@/bits/analytics'
import { SELF_HARM_POST_ALERT } from '@@/bits/confirmation_dialog'
import { removeHashidPrefix } from '@@/bits/hashid_helper'
import { navigateTo } from '@@/bits/location'
import { getVuexStore } from '@@/bits/pinia'
import { hasCustomProperties } from '@@/bits/post_properties'
import { isPostPublished } from '@@/bits/post_state'
import { escapeStringRegexp } from '@@/bits/regex'
import type { Group } from '@@/bits/surface_posts_grouping_helper'
import { getPostsByGroupByProperty } from '@@/bits/surface_posts_grouping_helper'
import { selfHarmHelplineUrl } from '@@/bits/url'
import { useGlobalConfirmationDialogStore } from '@@/pinia/global_confirmation_dialog'
import { useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import { useSurfaceStore } from '@@/pinia/surface'
import { useSurfaceFilterStore } from '@@/pinia/surface_filter'
import { useSurfacePermissionsStore } from '@@/pinia/surface_permissions'
import { useSurfacePostSearchStore } from '@@/pinia/surface_post_search'
import { useSurfaceWishArrangementStore } from '@@/pinia/surface_wish_arrangement'
import { PollApi } from '@@/surface/api/poll'
import { WishApi } from '@@/surface/api/wish'
import { getNewUnpinSortIndex, getTopSortIndex } from '@@/surface/sorter'
import type { AttachmentProps, Cid, HashId, Id, Post, PostAttributes, UpdatePostArgs } from '@@/types'
import { usePostSorter } from '@@/vuecomposables/surface_post_sorter'
import { isEmpty } from '@@/vuexstore/helpers/post'
import type { RootState } from '@@/vuexstore/surface/types'
import { defineStore } from 'pinia'
import type { ComputedRef } from 'vue'
import { computed, ref } from 'vue'

export const useSurfacePostsStore = defineStore('surfacePosts', () => {
  const surfaceVuexStore = getVuexStore<RootState>()

  const surfaceStore = useSurfaceStore()
  const surfacePermissionsStore = useSurfacePermissionsStore()
  const surfaceWishArrangementStore = useSurfaceWishArrangementStore()
  const surfaceFilterStore = useSurfaceFilterStore()
  const surfacePostSearchStore = useSurfacePostSearchStore()
  const globalSnackbarStore = useGlobalSnackbarStore()
  const { sortPosts } = usePostSorter()

  /*
   * DRAGGING POSTS
   */
  /*
   * POST COUNT GETTERS
   */

  const currentPostsCount = computed(() => currentSortedPosts.value.length)

  /*
   * POST COLLECTION GETTERS
   */

  const allPosts = computed<Post[]>(() => {
    const posts = surfaceVuexStore?.getters['post/savedPostsArray'] ?? []
    if (surfaceStore.isSectionBreakout) {
      return posts.filter((post) => post.wall_section_id === surfaceStore.breakoutSectionId)
    }
    return posts
  })
  const allSortedPosts = computed<Post[]>(() => sortPosts(allPosts.value))
  const sortedPostCids = computed(() => allSortedPosts.value.map((p) => p.cid) ?? [])
  const currentSortedPosts = computed(() => sortPosts(currentPosts.value))

  /*
   * POST MAP GETTERS
   */

  const allSortedPostsByGroup: ComputedRef<{
    [x: string]: Post[] | undefined
    [x: number]: Post[] | undefined
  }> = computed(() => {
    const sortedGroupIds = surfaceWishArrangementStore.sortedGroups.map((group) => group.id)
    return getPostsByGroupByProperty(allSortedPosts.value, sortedGroupIds, surfaceWishArrangementStore.wishGroupBy)
  })
  // Map of group ID to posts. Order of groups not guaranteed.
  const postsByGroup: ComputedRef<{
    [x: string]: Post[] | undefined
    [x: number]: Post[] | undefined
  }> = computed(() => {
    const sortedGroupIds = surfaceWishArrangementStore.sortedGroups.map((group) => group.id)
    return getPostsByGroupByProperty(currentSortedPosts.value, sortedGroupIds, surfaceWishArrangementStore.wishGroupBy)
  })
  // Posts by groups, but in order by which groups are sorted.
  const postsBySortedGroups: ComputedRef<Post[][]> = computed(() => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    return surfaceWishArrangementStore.sortedGroups
      .map((group) => postsByGroup.value[group.id])
      .filter((group) => group != null) as Post[][]
  })

  const postsBySectionId = computed<{ [sectionId: number]: Post[] }>(
    () => surfaceVuexStore?.getters['post/postsBySectionId'] ?? {},
  )

  const postsByAuthorId = computed<{ [authorId: number]: Post[] }>(() => {
    const postsByAuthorId = {}
    allSortedPosts.value.forEach((post) => {
      if (post.author_id == null) return
      if (postsByAuthorId[post.author_id] == null) {
        postsByAuthorId[post.author_id] = []
      }
      postsByAuthorId[post.author_id].push(post)
    })
    return postsByAuthorId
  })

  const sortedPostCidsBySectionId = computed<Record<string, Cid[]>>(() => {
    if (surfaceStore.isSectioned) {
      const sortedPostCidsBySectionId = {}
      sortedPostCids.value.forEach((postCid) => {
        const post = postEntitiesByCid.value[postCid]
        if (post.wall_section_id == null || isEmpty(post)) return
        if (sortedPostCidsBySectionId[post.wall_section_id] == null) {
          sortedPostCidsBySectionId[post.wall_section_id] = []
        }
        sortedPostCidsBySectionId[post.wall_section_id].push(postCid)
      })
      return sortedPostCidsBySectionId
    }
    return {}
  })

  /*
   * OTHER POST GETTERS
   */
  const remotePosts = ref<Post[]>([])
  const postEntitiesByCid = computed<{ [postCid: string]: Post }>(
    () => surfaceVuexStore?.getters['post/postEntitiesByCid'] ?? {},
  )
  const savedPostEntitiesByCid = computed<{ [postCid: string]: Post }>(
    () => surfaceVuexStore?.getters['post/savedPostEntitiesByCid'] ?? {},
  )
  const postEntitiesById = computed<{ [postId: string]: Post }>(
    () => surfaceVuexStore?.getters['post/postEntitiesById'] ?? {},
  )
  const postEntities = computed<Post[]>(() => Object.values(postEntitiesByCid.value))
  const postEntitiesByHashId = computed<{ [hashedId: string]: Post }>(() => {
    const entities = {}
    postEntities.value.forEach((post) => {
      if (post.hashid != null) {
        const hashedId = removeHashidPrefix(post.hashid)
        entities[hashedId] = post
      }
    })
    return entities
  })
  const postCids = computed<string[]>(() => surfaceVuexStore?.getters['post/postCids'] ?? [])
  const newestPostCid = computed(() => surfaceVuexStore?.getters['post/newestPostCid'] ?? [])
  const publishedPosts = computed<Post[]>(() => surfaceVuexStore?.getters['post/publishedPosts'] ?? [])
  const unapprovedPosts = computed<Post[]>(() => {
    const savedPosts: Post[] = surfaceVuexStore?.getters['post/savedPostsArray'] ?? []
    return savedPosts.filter((post) => post.published === false)
  })
  const pendingPosts = computed<Post[]>(() => surfaceVuexStore?.getters['post/pendingPosts'] ?? [])
  const pendingPostEntitiesByCid = computed<Record<Cid, Post>>(
    () => surfaceVuexStore?.getters['post/pendingPostEntitiesByCid'] ?? {},
  )

  const mostRecentlyChangedGeolocationPostCid = computed<Cid | null>(
    () => surfaceVuexStore?.getters['post/mostRecentlyChangedGeolocationPostCid'] ?? null,
  )

  const hasFetchedPosts = computed<boolean>(() => surfaceVuexStore?.getters['post/isInitialPaginatedDataLoadDone'])

  const postBeingEditedCid = computed<Cid | null>(() => surfaceVuexStore?.state.post.postBeingEditedCid ?? null)
  const postBeingEdited = computed<Post | null>(() =>
    postBeingEditedCid.value != null ? savedPostEntitiesByCid.value[postBeingEditedCid.value] : null,
  )

  const hasPostsWithCustomProperties = computed(() => allPosts.value.some(hasCustomProperties))

  function isExistingPost(cid: Cid): boolean {
    return savedPostEntitiesByCid.value[cid] != null
  }

  function canPinPost(post: Post): boolean {
    return (
      surfacePermissionsStore.canIModerate && // only editor+ can pin
      isPostPublished(post) && // only published posts can be pinned
      surfaceVuexStore?.getters.isTimelineV1 === false && // legacy format not supported
      !surfaceStore.isFrozen
    )
  }

  function getPostByServerId(id: string): Post | null {
    return surfaceVuexStore?.getters['post/getPostByServerId'](id)
  }

  /*
   * POST SEARCH
   */

  const currentPosts = ref<Post[]>(allPosts.value)

  function updateCurrentPosts(payload: Post[]) {
    currentPosts.value = payload
  }

  /*
   * POST ACTIONS
   */

  function fetchPosts(): void {
    void surfaceVuexStore?.dispatch('post/fetchPosts', { shouldResetState: false })
  }

  async function startNewPost({ attributes }: { attributes?: PostAttributes }): Promise<{ cid: Cid } | undefined> {
    return await surfaceVuexStore?.dispatch('post/startNewPost', { attributes })
  }

  function sortPost(payload: {
    postCid: Cid
    previousCid?: Cid | null
    nextCid?: Cid | null
    sortIndex?: number | null
    sectionId?: number | null
    gapSize?: number | null
  }): void {
    void surfaceVuexStore?.dispatch('post/sortedPost', payload)
  }

  async function savePost(payload: {
    attributes: Post | PostAttributes
    updatePostArgs?: UpdatePostArgs
  }): Promise<void> {
    await surfaceVuexStore?.dispatch('post/savePost', payload, { root: true })
  }

  function updatePostSection(payload: { postCid: string; sectionId: number | string }): void {
    void surfaceVuexStore?.dispatch('post/updatePostSection', payload, { root: true })
    void surfaceVuexStore?.dispatch('post/updateAfterPostCreated', { postCid: payload.postCid }, { root: true })
  }

  function shouldDisplayPost(postCid: Cid): boolean {
    // For future reference, this function used to look like:
    // return currentSortedPostsCids.value.includes(postCid)
    //
    // But we ran into a bug where there are duplicate posts
    // in the padlet if `wishSortBy` is `shuffle`. This
    // `shouldDisplayPost` function called in the `v-show`
    // directive somehow causes a second render that passes
    // duplicate posts to the `SurfacePost` component.
    // We still don't know why, but it's better to avoid
    // referencing "sorted" post getters in this function.

    return currentPosts.value.some((p) => p.cid === postCid)
  }

  function shouldDisplayGroup(group: Group): boolean {
    // If we are not searching and no filters are selected, display the group
    if (surfacePostSearchStore.searchTerm === '' && !surfaceFilterStore.hasSelectedFilters) {
      return true
    }

    /* If we are searching or filters are selected, display the group if it has any posts that should be displayed
    or if its title matches the search term */
    const searchTermRegex = new RegExp(escapeStringRegexp(surfacePostSearchStore.searchTerm), 'i')

    return (
      (computed(() => postsByGroup.value[group.id]?.some((p) => shouldDisplayPost(p.cid))).value ?? false) ||
      searchTermRegex.test(group.title)
    )
  }

  async function postPollVote({
    wishId,
    pollId,
    pollChoiceIds,
  }: {
    wishId: Id
    pollId: Id
    pollChoiceIds: number[]
  }): Promise<void> {
    try {
      const updatedWish = await PollApi.vote({ wishId, pollId, pollChoiceIds })
      await surfaceVuexStore?.dispatch('post/updatePostInStore', updatedWish, { root: true })
    } catch (error) {
      void globalSnackbarStore.genericFetchError()
    }
  }

  async function rejectPost(id?: Id, wishHashid?: HashId): Promise<void> {
    if (id == null || wishHashid == null) return

    void surfaceVuexStore?.dispatch('post/removePost', { id })

    await WishApi.reject({ wishHashid })
  }

  function showSelfHarmPostAlert({ wish_id }: { wish_id?: Id }): void {
    if (wish_id == null) return

    trackEvent('SurfaceSelfHarmWarning', 'Showed Self-harm dialog for post', wish_id)
    void useGlobalConfirmationDialogStore().openConfirmationDialog({
      ...SELF_HARM_POST_ALERT,
      afterConfirmActions: [() => navigateTo(selfHarmHelplineUrl(), { target: 'blank' })],
    })
  }

  function movePost(payload: { postCid: Cid; position: any }): void {
    void surfaceVuexStore?.dispatch('post/movedPost', payload, { root: true })
  }

  // pin/unpin posts
  function pinPost(postCid: Cid): void {
    void savePost({
      attributes: {
        cid: postCid,
        pinned_at: new Date().toISOString(),
        // We already sort pinned posts to the top but to avoid any issues
        // when dropping a post to between a pinned and an unpinned post
        // we also set the sort_index.
        // For pinning a post, we set it to the top value.
        sort_index: getTopSortIndex(),
      },
    })
    trackEvent('Posts', 'Pinned post')
  }

  function unpinPost(postCid: Cid): void {
    const { sortIndex, gapSize } = getNewUnpinSortIndex()
    void savePost({
      attributes: {
        cid: postCid,
        pinned_at: null,
        // We already sort pinned posts to the top but to avoid any issues
        // when dropping a post to between a pinned and an unpinned post
        // we also set the sort_index.
        // For unpinning a post, we set it to the value between the last
        // pinned post and the first unpinned post.
        sort_index: sortIndex,
      },
      updatePostArgs: {
        gap_size: gapSize,
      },
    })
    trackEvent('Posts', 'Unpinned post')
  }

  function startEditingPost(payload: { postCid: Cid }): void {
    void surfaceVuexStore?.dispatch('post/startEditingPost', payload)
  }

  function stopEditingPost(): void {
    void surfaceVuexStore?.dispatch('post/stopEditingPost')
  }

  function resetMostRecentlyChangedGeolocationPostCid(): void {
    void surfaceVuexStore?.commit('post/SET_MOST_RECENTLY_CHANGED_GEOLOCATION_POST_CID', null)
  }

  async function deletePost(postCid: Cid): Promise<void> {
    return await surfaceVuexStore?.dispatch('post/deletePost', { postCid })
  }

  function createPendingPost(payload: { postAttributes: PostAttributes }): void {
    void surfaceVuexStore?.dispatch('post/createPendingPost', payload)
  }

  function removePendingPosts(payload: { cids: Cid[] }): void {
    void surfaceVuexStore?.dispatch('post/removePendingPosts', payload)
  }

  function updatePostInStore(payload: PostAttributes): void {
    void surfaceVuexStore?.dispatch('post/updatePostInStore', payload)
  }

  /**
   * Create post immediately. Example scenarios: paste/drop to post and in composer mode
   */
  function createPostImmediately(payload: { attributes: PostAttributes }): void {
    void surfaceVuexStore?.dispatch('post/createPost', payload)
  }

  function saveContent(payload: { content: AttachmentProps }): void {
    void surfaceVuexStore?.dispatch('post/saveContent', payload)
  }

  function saveAttachmentCaption(payload: { caption: string }): void {
    void surfaceVuexStore?.dispatch('post/saveAttachmentCaption', payload)
  }

  // REMOTE POST ACTIONS
  function newPostRemote(payload: { post: Post }): void {
    remotePosts.value = [...remotePosts.value, payload.post]
  }

  function editPostRemote(payload: { post: Post }): void {
    remotePosts.value = remotePosts.value.map((post) => (post.id === payload.post.id ? payload.post : post))
  }

  function deletePostRemote(payload: { post: Post }): void {
    remotePosts.value = remotePosts.value.filter((post) => post.id !== payload.post.id)
  }

  return {
    // POST COUNT GETTERS
    currentPostsCount,

    // POST COLLECTION GETTERS
    allPosts, // all posts, not sorted
    allSortedPosts, // all posts, sorted
    sortedPostCids,
    currentPosts: computed(() => currentPosts.value), // filtered posts, not sorted. exported as computed so it can't be mutated.
    currentSortedPosts, // filtered posts, sorted

    // POST MAP GETTERS
    allSortedPostsByGroup, // map of all posts by group, sorted
    postsByGroup, // map of filtered posts by group, sorted
    postsBySortedGroups, // 2D array of filtered posts, sorted
    postsBySectionId, // map of posts by section ID
    postsByAuthorId, // map of posts by author ID
    sortedPostCidsBySectionId, // map of post CIDs by section ID, sorted

    // OTHER POST GETTERS
    hasPostsWithCustomProperties,
    postEntitiesByCid,
    savedPostEntitiesByCid,
    postEntitiesById,
    postEntities,
    postCids,
    newestPostCid,
    publishedPosts,
    unapprovedPosts,
    pendingPosts,
    pendingPostEntitiesByCid,
    mostRecentlyChangedGeolocationPostCid,
    hasFetchedPosts,
    postBeingEditedCid,
    postBeingEdited,
    isExistingPost,
    canPinPost,
    getPostByServerId,
    postEntitiesByHashId,
    remotePosts,

    // POST ACTIONS
    fetchPosts,
    startNewPost,
    sortPost,
    shouldDisplayPost,
    shouldDisplayGroup,
    postPollVote,
    savePost,
    updatePostSection,
    rejectPost,
    showSelfHarmPostAlert,
    movePost,
    pinPost,
    unpinPost,
    stopEditingPost,
    resetMostRecentlyChangedGeolocationPostCid,
    deletePost,
    startEditingPost,
    createPendingPost,
    removePendingPosts,
    updateCurrentPosts,
    updatePostInStore,
    createPostImmediately,
    saveContent,
    saveAttachmentCaption,
    newPostRemote,
    editPostRemote,
    deletePostRemote,
  }
})
