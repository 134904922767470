// @file Network requests related to reaction from surface

import FetchableObject from '@@/surface/api/fetchable_object'
import { fetchJson } from '@@/surface/api_fetch'
import type { AccumulatedReactions } from '@@/types'
import type { JsonAPIResponse, Reaction, ReactionsFetchQuery } from '@padlet/arvo'
import { fetchReactions } from '@padlet/arvo'
import { HTTPMethod } from '@padlet/fetch'

const API_VERSION = 5

class ReactionApi extends FetchableObject {
  public static async fetchAccumulatedReactions({
    wallId,
  }: {
    wallId: number
  }): Promise<JsonAPIResponse<AccumulatedReactions>> {
    return await fetchJson(`/api/${API_VERSION}/accumulated_reactions?wall_id=${wallId}`, {
      method: HTTPMethod.get,
    })
  }

  public static get url(): string {
    return `/api/${API_VERSION}/reactions`
  }

  public static get fetchUrl(): string {
    return `/api/${API_VERSION}/reactions?wall_id=\${ wall.id }`
  }

  public static async fetch(query: ReactionsFetchQuery): Promise<JsonAPIResponse<Reaction>> {
    return await fetchReactions(query)
  }
}

export { ReactionApi }
