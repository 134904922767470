// @file All network requests relating to wall grading settings used on surface

import { asciiSafeStringify } from '@@/bits/json_stringify'
import type { VersionedFetchOptions } from '@@/surface/api/fetchable_object'
import FetchableObject from '@@/surface/api/fetchable_object'
import { fetchJson } from '@@/surface/api_fetch'
import type { WallGradingSettings, WallId } from '@@/types'
import type { JsonAPIResponse } from '@padlet/arvo'
import { HTTPMethod } from '@padlet/fetch'

class WallGradingSettingsApi extends FetchableObject {
  public static async fetch(
    wallId: WallId,
    fetchOptions: VersionedFetchOptions = {},
  ): Promise<JsonAPIResponse<WallGradingSettings>> {
    const { apiVersion = 1, ...otherFetchOptions } = fetchOptions
    return await fetchJson(`/api/${apiVersion}/walls/${wallId}/grading-settings`, {
      method: HTTPMethod.get,
      ...otherFetchOptions,
    })
  }

  public static async update(
    wallId: WallId,
    gradingSettings: Partial<WallGradingSettings>,
    fetchOptions: VersionedFetchOptions = {},
  ): Promise<JsonAPIResponse<WallGradingSettings>> {
    const { apiVersion = 1, ...otherFetchOptions } = fetchOptions
    return await fetchJson(`/api/${apiVersion}/walls/${wallId}/grading-settings`, {
      method: HTTPMethod.patch,
      body: asciiSafeStringify({ data: { attributes: gradingSettings } }),
      ...otherFetchOptions,
    })
  }

  public static async deleteContextFiles(wallId: WallId, fetchOptions: VersionedFetchOptions = {}): Promise<void> {
    const { apiVersion = 1, ...otherFetchOptions } = fetchOptions
    return await fetchJson(`/api/${apiVersion}/walls/${wallId}/grading-settings/context-files`, {
      method: HTTPMethod.delete,
      ...otherFetchOptions,
    })
  }
}

export { WallGradingSettingsApi }
