// @file Surface post action store
// TODO: [to-be-migrated][post_action]
import { CONFIRM_MODERATION_REJECT_POST } from '@@/bits/confirmation_dialog'
import { captureFetchException } from '@@/bits/error_tracker'
import { isAppUsing } from '@@/bits/flip'
import { __ } from '@@/bits/intl'
import { getVuexStore } from '@@/bits/pinia'
import { getPollFromPost } from '@@/bits/surface_polls'
import { useExpandedPostStore } from '@@/pinia/expanded_post'
import {
  OzConfirmationDialogBoxButtonScheme,
  useGlobalConfirmationDialogStore,
} from '@@/pinia/global_confirmation_dialog'
import { SnackbarNotificationType, useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import { useSurfaceStore } from '@@/pinia/surface'
import { useSurfaceAttachmentsStore } from '@@/pinia/surface_attachments'
import { useSurfaceCurrentUserStore } from '@@/pinia/surface_current_user'
import { useSurfacePermissionsStore } from '@@/pinia/surface_permissions'
import { useSurfacePostsStore } from '@@/pinia/surface_posts'
import { useSurfacePostConnectionStore } from '@@/pinia/surface_post_connection'
import { PollApi } from '@@/surface/api/poll'
import { WallApi } from '@@/surface/api/wall'
import { WallSectionApi } from '@@/surface/api/wall_section'
import { WishApi } from '@@/surface/api/wish'
import type { Cid, HashId, Id, Post, PostAttributes, Section, WallId } from '@@/types'
import { useCopyToClipboard } from '@@/vuecomposables/copy_to_clipboard'
import type { CopyOrTransferPostResult, RootState as SurfaceRootState } from '@@/vuexstore/surface/types'
import type { JsonAPIResource, JsonAPIResponse, PostColor } from '@padlet/arvo'
import { isEmpty } from 'lodash-es'
import { defineStore } from 'pinia'
import { computed, ref, shallowRef } from 'vue'

interface CopyXferPostParams {
  wallHashid: HashId
  wallDescription?: string
  wallTitle?: string
  wallSectionId?: Id
  wallSectionHashid?: HashId
  isDestinationThisWall: boolean
  postPosition?: { left: number; top: number }
  postId: Id
  postHashid: HashId
  transferredPostCid: Cid
  libraryId?: string
  transferCustomPropValuesAcrossWalls: boolean
}

export const useSurfacePostActionStore = defineStore('postAction', () => {
  const vuexStore = getVuexStore<SurfaceRootState>()
  const globalSnackbarStore = useGlobalSnackbarStore()
  const globalConfirmationDialogStore = useGlobalConfirmationDialogStore()
  const expandedPostStore = useExpandedPostStore()
  const surfaceCurrentUserStore = useSurfaceCurrentUserStore()
  const surfaceStore = useSurfaceStore()
  const surfacePostsStore = useSurfacePostsStore()
  const surfacePostConnectionStore = useSurfacePostConnectionStore()
  const surfacePermissionsStore = useSurfacePermissionsStore()
  const surfaceAttachmentStore = useSurfaceAttachmentsStore()

  const { copyToClipboard } = useCopyToClipboard()

  /* ---------------------- */
  /* POLL                   */
  /* ---------------------- */

  const closePoll = async (payload: { post: Post }): Promise<void> => {
    try {
      const pollParams = {
        ...getPollFromPost(payload.post),
        closed_at: new Date().toISOString(),
        wish_id: payload.post.id,
      }
      const updatedWish = await PollApi.updatePoll(pollParams)
      surfacePostsStore.updatePostInStore(updatedWish)
      globalSnackbarStore.setSnackbar({
        notificationType: SnackbarNotificationType.success,
        message: __('Closed poll'),
      })
    } catch (e) {
      globalSnackbarStore.genericFetchError()
      captureFetchException(e, { source: 'closePoll' })
    }
  }

  const reopenPoll = async (payload: { post: Post }): Promise<void> => {
    try {
      const pollParams = {
        ...getPollFromPost(payload.post),
        closed_at: null,
        wish_id: payload.post.id,
      }
      const updatedWish = await PollApi.updatePoll(pollParams)
      surfacePostsStore.updatePostInStore(updatedWish)
      globalSnackbarStore.setSnackbar({
        notificationType: SnackbarNotificationType.success,
        message: __('Reopened poll'),
      })
    } catch (e) {
      globalSnackbarStore.genericFetchError()
      captureFetchException(e, { source: 'reopenPoll' })
    }
  }

  /* ---------------------- */
  /* POST UNDER ACTION      */
  /* ---------------------- */
  const postUnderActionCid = ref<Cid | null>(null)

  const postUnderAction = computed<Post | undefined>(() =>
    postUnderActionCid.value != null ? surfacePostsStore.postEntitiesByCid[postUnderActionCid.value] : undefined,
  )

  const xPostAction = computed<boolean>(
    () =>
      postUnderActionCid.value != null ||
      expandedPostStore.expandedPostCid != null ||
      xTransferPostPanel.value ||
      xCopyPostPanel.value,
  )

  // Sent to the mobile app to determine where to render post action button.
  // https://github.com/padlet/mozart/pull/23494
  const postActionMenuTriggerRect = shallowRef<DOMRect | null>(null)
  const postRightClickCoordinates = ref<[number, number] | null>(null)

  const hasPostUnderActionWithConnections = computed<boolean>(() => {
    if (postUnderAction.value == null) return false
    const postId = postUnderAction.value.id
    const postConnections = surfacePostConnectionStore.connections
    return (
      postConnections.filter((connection) => connection.from_wish_id === postId || connection.to_wish_id === postId)
        .length > 0
    )
  })

  const isPostUnderActionUserAuthored = computed<boolean>(
    () => postUnderAction.value != null && postUnderAction.value?.author_id === surfaceCurrentUserStore.currentUser?.id,
  )

  const doesPostUnderActionHaveAttachment = computed<boolean>(
    () =>
      !isEmpty(postUnderAction.value?.attachment) ||
      !isEmpty(postUnderAction.value?.wish_content?.attachment_props?.url),
  )

  const isPostUnderActionPreviewPost = computed<boolean>(
    () => postUnderAction.value != null && postUnderAction.value.id === surfaceStore.coverPostId,
  )

  const showPostActionMenu = (payload: { postCid: Cid }): void => {
    postUnderActionCid.value = payload.postCid
  }

  const hidePostActionMenu = (): void => {
    postUnderActionCid.value = null
    postActionMenuTriggerRect.value = null
    postRightClickCoordinates.value = null
  }

  const setPostActionMenuTriggerRect = (payload: { postActionMenuTriggerRect: DOMRect | null }): void => {
    postActionMenuTriggerRect.value = payload.postActionMenuTriggerRect
    postRightClickCoordinates.value = null
  }

  const setPostRightClickCoordinates = (payload: { postRightClickCoordinates: [number, number] | null }): void => {
    postRightClickCoordinates.value = payload.postRightClickCoordinates
    postActionMenuTriggerRect.value = null
  }

  /* --------------------------------------- */
  /* POST ACTIONS THAT CAN BE PERFORMED      */
  /* --------------------------------------- */
  const canEditPostUnderAction = computed<boolean>(
    () =>
      !surfaceStore.isFrozen &&
      (surfacePermissionsStore.canIEdit || (surfacePermissionsStore.canIWrite && isPostUnderActionUserAuthored.value)),
  )

  const canDeletePostUnderAction = computed<boolean>(() => canEditPostUnderAction.value) // same as can edit

  const canTransferPostUnderAction = computed<boolean>(
    () =>
      surfacePermissionsStore.amIRegistered &&
      (surfacePermissionsStore.canIEdit || (surfacePermissionsStore.canIWrite && isPostUnderActionUserAuthored.value)),
  )

  const canCopyPostUnderAction = computed<boolean>(() => canTransferPostUnderAction.value) // same as can transfer

  const canOpenInMapApp = computed<boolean>(
    () =>
      surfaceStore.isMap &&
      postUnderAction.value?.location_point?.latitude != null &&
      postUnderAction.value?.location_point?.longitude != null,
  )

  const canConnectPostUnderAction = computed<boolean>(
    () => (surfaceStore.isCanvas || surfaceStore.isMap) && surfacePermissionsStore.canIWrite && !surfaceStore.isFrozen,
  )

  const canDisconnectPostUnderAction = computed<boolean>(
    () => canConnectPostUnderAction.value && hasPostUnderActionWithConnections.value,
  )

  const canChangeCoverToPostUnderAction = computed<boolean>(
    () =>
      surfacePermissionsStore.canIAdminister &&
      doesPostUnderActionHaveAttachment.value &&
      postUnderAction.value?.published === true &&
      !surfaceStore.isFrozen,
  )

  const canChangePostUnderActionLocation = computed<boolean>(
    () =>
      surfaceStore.isMap &&
      (surfacePermissionsStore.canIEdit || (surfacePermissionsStore.canIWrite && isPostUnderActionUserAuthored.value)),
  )

  const canChangePostStackOrder = computed<boolean>(
    () => (surfaceStore.isCanvas || surfaceStore.isMap) && surfacePermissionsStore.canIEdit && !surfaceStore.isFrozen,
  )

  // TODO: [to-be-migrated][expanded_post]
  const canChangeCoverToExpandedPost = computed<boolean>(
    () =>
      surfacePermissionsStore.canIAdminister &&
      expandedPostStore.doesExpandedPostHaveAttachment &&
      expandedPostStore.expandedPost?.published === true &&
      !surfaceStore.isFrozen,
  )

  /* ------------------------- */
  /* POST ACTIONS - TRANSFER   */
  /* ------------------------- */
  const xTransferPostPanel = ref(false)
  const postBeingTransferredCid = ref<Cid | null>(null)
  const postBeingTransferred = computed<Post | null>(() =>
    postBeingTransferredCid.value != null ? surfacePostsStore.postEntitiesByCid[postBeingTransferredCid.value] : null,
  )
  const copyOrTransferPostResult = ref<CopyOrTransferPostResult | null>(null)
  const copyOrTransferSectionId = ref<Id | null>(null)
  const destinationWallSections = ref<Section[]>([])

  const startPostTransfer = (payload: { transferPostCid: Cid }): void => {
    xTransferPostPanel.value = true
    postBeingTransferredCid.value = payload.transferPostCid
  }

  const endPostTransfer = (): void => {
    xTransferPostPanel.value = false
    postBeingTransferredCid.value = null
    copyOrTransferPostResult.value = null
  }

  const actuallyTransferPost = (params: CopyXferPostParams): void => {
    const transferPost = async (): Promise<void> => {
      const { isDestinationThisWall, transferredPostCid, wallTitle, wallDescription } = params

      let postBeingTransferred: Post | null = null
      if (!isDestinationThisWall) {
        // Optimistically remove the post being transferred to a different wall
        postBeingTransferred = surfacePostsStore.postEntitiesByCid[transferredPostCid]
        void vuexStore?.dispatch('post/removePost', { cid: transferredPostCid }, { root: true })
      }
      try {
        const { data } = await WishApi.transfer({
          wishHashid: params.postHashid,
          wallHashid: params.wallHashid,
          wallSectionHashid: params.wallSectionHashid,
          wallTitle,
          wallDescription,
          libraryId: params.libraryId,
          transferCustomPropValuesAcrossWalls: params.transferCustomPropValuesAcrossWalls,
        })
        const postAttributes = data.attributes
        const isDestinationNewWall = !isEmpty(wallTitle) || !isEmpty(wallDescription)
        await updatePostTransferred({
          newPostData: postAttributes,
          isDestinationThisWall,
          isDestinationNewWall,
        })
      } catch (e) {
        // contents of error
        // {
        //    "response": { /* response object */ },
        //    "status": 422, /* some 4xx error */
        //    "message": "{ \"error\": \"some error message\" }"
        // }
        // check to see if "message" can be JSON decoded
        let parsedError = e
        try {
          parsedError = JSON.parse(e.message)
        } finally {
          if (!isDestinationThisWall) {
            // Reinstate the removed post since the transfer failed
            void vuexStore?.dispatch('post/revivePost', postBeingTransferred, { root: true })
          }
          await updatePostTransferred({ error: parsedError })
        }
      }
    }
    const customRequest = { key: 'TRANSFER_POST', method: transferPost }
    copyOrTransferSectionId.value = params.wallSectionId ?? null
    void vuexStore?.dispatch('post/enqueueRequest', { post: { id: params.postId }, customRequest }, { root: true })
  }

  const updatePostTransferred = async ({
    error,
    newPostData,
    isDestinationThisWall = false,
    isDestinationNewWall = false,
  }: {
    error?: { error?: string }
    newPostData?: PostAttributes
    isDestinationNewWall?: boolean
    isDestinationThisWall?: boolean
  }): Promise<void> => {
    const result: CopyOrTransferPostResult = {
      status: 'success',
      operation: 'transfer',
      isDestinationThisWall,
      isDestinationNewWall,
    }
    if (error != null) {
      result.status = 'error'
      result.text = __('Sorry, we failed to transfer your post. %{cause_of_failure}', {
        cause_of_failure: error.error ?? '',
      })
    } else {
      let wallInfo = surfaceStore.wallAttributes
      if (!isDestinationThisWall) {
        try {
          if (newPostData?.wall_hashid != null) {
            wallInfo = await WallApi.fetch(newPostData?.wall_hashid)
          } else {
            // TODO: Systemic Authorization issues - remove fetch by id
            wallInfo = await WallApi.read({ id: newPostData?.wall_id })
          }
        } catch (e) {
          captureFetchException(e, { source: 'updatePostTransferred#Wall.read' })
        }
      } else {
        void vuexStore?.dispatch('post/updatePostInStore', newPostData, { root: true })
      }
      result.url = wallInfo.links?.show
      result.text = isAppUsing('padletPickerV2')
        ? __('Post transferred to %{padletTitle}', { padletTitle: wallInfo.title })
        : __('Post transferred successfully')
    }
    if (result.status !== 'error') {
      postBeingTransferredCid.value = null
    }
    copyOrTransferPostResult.value = result
  }

  /* ------------------------- */
  /* POST ACTIONS - COPYING    */
  /* ------------------------- */
  const xCopyPostPanel = ref(false)
  const postBeingCopiedCid = ref<Cid | null>(null)
  const postBeingCopied = computed<Post | null>(() =>
    postBeingCopiedCid.value != null ? surfacePostsStore.postEntitiesByCid[postBeingCopiedCid.value] : null,
  )

  const startPostCopy = (payload: { copyPostCid: Cid }): void => {
    xCopyPostPanel.value = true
    postBeingCopiedCid.value = payload.copyPostCid
  }

  const endPostCopy = (): void => {
    xCopyPostPanel.value = false
    postBeingCopiedCid.value = null
    copyOrTransferPostResult.value = null
  }

  const actuallyCopyPost = (params: CopyXferPostParams): void => {
    if (params.wallSectionId != null) {
      copyOrTransferSectionId.value = params.wallSectionId
    }
    const copyPost = async (): Promise<void> => {
      const { wallTitle, wallDescription } = params
      try {
        const { data } = await WishApi.copy({
          wishHashid: params.postHashid,
          wallHashid: params.wallHashid,
          wallSectionHashid: params.wallSectionHashid,
          wallTitle,
          wallDescription,
          libraryId: params.libraryId,
          postPosition: params.postPosition,
          transferCustomPropValuesAcrossWalls: !params.isDestinationThisWall,
        })
        const postAttributes = data.attributes
        const isDestinationNewWall = params.isDestinationThisWall
          ? false
          : !isEmpty(wallTitle) || !isEmpty(wallDescription)
        await updatePostCopied({
          newPostData: postAttributes,
          isDestinationNewWall,
        })
      } catch (e) {
        // contents of error
        // {
        //    "response": { /* response object */ },
        //    "status": 422, /* some 4xx error */
        //    "message": "{ \"error\": \"some error message\" }"
        // }
        // check to see if "message" can be JSON decoded
        let parsedError = e
        try {
          parsedError = JSON.parse(e.message)
        } finally {
          await updatePostCopied({ error: parsedError })
        }
      }
    }
    const customRequest = { key: 'COPY_POST', method: copyPost }
    void vuexStore?.dispatch('post/enqueueRequest', { post: { id: params.postId }, customRequest }, { root: true })
  }

  const updatePostCopied = async ({
    error,
    newPostData,
    isDestinationNewWall = false,
  }: {
    error?: { error?: string }
    newPostData?: PostAttributes
    isDestinationNewWall?: boolean
  }): Promise<void> => {
    const result: CopyOrTransferPostResult = {
      status: 'success',
      operation: 'copy',
      isDestinationThisWall: false,
      isDestinationNewWall,
    }
    if (error != null) {
      result.status = 'error'
      result.text = __('Sorry, we failed to duplicate your post. %{cause_of_failure}', {
        cause_of_failure: error.error ?? '',
      })
    } else {
      result.isDestinationThisWall = !isDestinationNewWall && surfaceStore.wallId === newPostData?.wall_id
      let wallInfo = surfaceStore.wallAttributes
      if (result.isDestinationThisWall) {
        void vuexStore?.dispatch('post/addPost', newPostData, { root: true })
      } else {
        try {
          if (newPostData?.wall_hashid != null) {
            wallInfo = await WallApi.fetch(newPostData?.wall_hashid)
          } else {
            // TODO: Systemic Authorization issues - remove fetch by id
            wallInfo = await WallApi.read({ id: newPostData?.wall_id })
          }
        } catch (e) {
          captureFetchException(e, { source: 'updatePostCopied#Wall.read' })
        }
      }
      result.url = wallInfo.links?.show
      result.text =
        isAppUsing('padletPickerV2') && isDestinationNewWall
          ? __('Post copied to %{padletTitle}', { padletTitle: wallInfo.title })
          : __('Post duplicated successfully')
    }
    copyOrTransferPostResult.value = result
  }

  const fetchDestinationWallSections = async (payload: { wallId: WallId }): Promise<void> => {
    try {
      destinationWallSections.value = []
      const response: JsonAPIResponse<Section> = await WallSectionApi.readAll({ wall: { id: payload.wallId } })
      const sections = (response.data as Array<JsonAPIResource<Section>>).map((section) => section.attributes)
      destinationWallSections.value = sections
    } catch (e) {
      captureFetchException(e, { source: 'fetchDestinationWallSections#WallSection.readAll' })
    }
  }

  /* ---------------------------- */
  /* POST ACTIONS - DELETE        */
  /* ---------------------------- */
  const confirmDeletePost = (payload: { postCid: Cid }): void => {
    globalConfirmationDialogStore.openConfirmationDialog({
      title: __('Delete post?'),
      body: __('Are you sure you want to delete this post? This cannot be undone!'),
      confirmButtonText: __('Delete'),
      cancelButtonText: __('Nevermind'),
      buttonScheme: OzConfirmationDialogBoxButtonScheme.Danger,
      afterConfirmActions: [
        () => expandedPostStore.unexpandPost(),
        async () => await vuexStore?.dispatch('post/deletePost', payload, { root: true }),
      ],
    })
  }

  const deletePostRemote = (post?: Post): void => {
    if (post == null) return

    const postFromStore = vuexStore?.getters['post/getLiveOrDeletedPost'](post)
    if (postFromStore == null) return

    const postCid = postFromStore.cid

    if (postCid === postBeingTransferredCid.value) {
      endPostTransfer()
    }

    if (postCid === postBeingCopiedCid.value) {
      endPostCopy()
    }

    if (postCid === postUnderActionCid.value) {
      hidePostActionMenu()
    }

    if (postCid === expandedPostStore.expandedPostCid) {
      // When the expanded post is deleted, show snackbar and navigate to the next post.
      globalSnackbarStore.setSnackbar({
        notificationType: SnackbarNotificationType.success,
        message: __('Previous post has been deleted elsewhere.'),
      })
      const postIndex = expandedPostStore.expandedPostPostsToNavigate.findIndex(
        (p: Post): boolean => p.cid === expandedPostStore.expandedPostCid,
      )
      if (expandedPostStore.expandedPostPostsToNavigate.length === 1) {
        expandedPostStore.unexpandPost()
      } else if (postIndex === expandedPostStore.expandedPostPostsToNavigate.length - 1) {
        // go to previous post
        expandedPostStore.expandPost({ postCid: expandedPostStore.expandedPostPostsToNavigate[postIndex - 1].cid })
      } else {
        // go to next post
        expandedPostStore.expandPost({ postCid: expandedPostStore.expandedPostPostsToNavigate[postIndex + 1].cid })
      }
    }
  }

  /* ---------------------- */
  /* POST ACTIONS - GENERAL */
  /* ---------------------- */

  const approvePost = (payload: { postCid: Cid }): void => {
    void vuexStore?.dispatch('post/approvePost', payload, { root: true })
  }

  const confirmModerationRejectPost = (payload: { postCid: Cid }): void => {
    globalConfirmationDialogStore.openConfirmationDialog({
      ...CONFIRM_MODERATION_REJECT_POST,
      afterConfirmActions: [
        () => expandedPostStore.unexpandPost(),
        async () => await vuexStore?.dispatch('post/deletePost', payload, { root: true }),
      ],
    })
  }

  const copyPostLinkAndAlert = (payload: { postCid: Cid }): void => {
    const post = surfacePostsStore.postEntitiesByCid[payload.postCid]
    if (post?.permalink == null) return
    void copyToClipboard({
      text: post.permalink,
      globalSnackbarOptions: {
        notificationType: SnackbarNotificationType.success,
        message: __('Link copied to clipboard'),
      },
    })
  }

  const copyAttachmentLinkAndAlert = (payload: { postCid: Cid }): void => {
    const post = surfacePostsStore.postEntitiesByCid[payload.postCid]
    const linkAttributes = surfaceAttachmentStore.getLinkDisplayAttributesForPost(payload.postCid)
    let linkImagePreviewUrl: string | undefined
    // for non-upload links pointing to image files, we want to use the url for the image copy stored in padlet-artifacts bucket
    if (linkAttributes != null && linkAttributes.content_category === 'photo') {
      linkImagePreviewUrl = linkAttributes.original_image_url
    }
    const url =
      post?.wish_content?.attachment_props?.hotlink ??
      post?.wish_content?.attachment_props?.url ??
      linkImagePreviewUrl ??
      post?.attachment
    if (url == null) return
    void copyToClipboard({
      text: url,
      globalSnackbarOptions: {
        notificationType: SnackbarNotificationType.success,
        message: __('Link copied to clipboard'),
      },
    })
  }

  // TODO we should not need this method, ideally. Getters and watches should take care of this.
  const recalibrate = (): void => {
    const postEntitiesByCid = useSurfacePostsStore().postEntitiesByCid

    if (postBeingTransferredCid.value != null && postEntitiesByCid[postBeingTransferredCid.value] == null) {
      endPostTransfer()
    }

    if (postBeingCopiedCid.value != null && postEntitiesByCid[postBeingCopiedCid.value] == null) {
      endPostCopy()
    }

    if (postUnderActionCid.value != null && postEntitiesByCid[postUnderActionCid.value] == null) {
      hidePostActionMenu()
    }

    const expandedPostCid = expandedPostStore.expandedPostCid
    if (expandedPostCid != null && postEntitiesByCid[expandedPostCid] == null) {
      expandedPostStore.unexpandPost()
    }
  }

  const changePostColor = (payload: { postCid: Cid; newBgColor: PostColor }): void => {
    void vuexStore?.dispatch('post/changePostColor', payload)
  }

  const removePostColor = (payload: { postCid: Cid }): void => {
    void vuexStore?.dispatch('post/removePostColor', payload)
  }

  const setCoverPost = (payload: { postId: Id }): void => {
    void vuexStore?.dispatch('setCoverPost', payload)
  }

  const unsetCoverPost = (payload: { postId: Id }): void => {
    void vuexStore?.dispatch('unsetCoverPost', payload)
  }

  const showReportPanel = ({ postId }: { postId?: Id } = {}): void => {
    void vuexStore?.dispatch('showReportPanel', { postId })
  }

  return {
    // getters
    postUnderActionCid,
    postRightClickCoordinates,
    postActionMenuTriggerRect,
    xTransferPostPanel,
    postBeingTransferredCid,
    copyOrTransferPostResult,
    xCopyPostPanel,
    postBeingCopiedCid,
    destinationWallSections,
    copyOrTransferSectionId,
    hasPostUnderActionWithConnections,
    xPostAction,
    isPostUnderActionUserAuthored,
    canEditPostUnderAction,
    canDeletePostUnderAction,
    canTransferPostUnderAction,
    canCopyPostUnderAction,
    canOpenInMapApp,
    canConnectPostUnderAction,
    canDisconnectPostUnderAction,
    canChangePostStackOrder,
    doesPostUnderActionHaveAttachment,
    canChangeCoverToPostUnderAction,
    canChangeCoverToExpandedPost,
    canChangePostUnderActionLocation,
    isPostUnderActionPreviewPost,
    postUnderAction,
    postBeingCopied,
    postBeingTransferred,

    // actions
    showPostActionMenu,
    setPostActionMenuTriggerRect,
    setPostRightClickCoordinates,
    hidePostActionMenu,
    startPostTransfer,
    endPostTransfer,
    actuallyTransferPost,
    updatePostTransferred,
    startPostCopy,
    endPostCopy,
    actuallyCopyPost,
    updatePostCopied,
    fetchDestinationWallSections,
    confirmDeletePost,
    approvePost,
    confirmModerationRejectPost,
    copyPostLinkAndAlert,
    copyAttachmentLinkAndAlert,
    closePoll,
    reopenPoll,
    deletePostRemote,
    recalibrate,
    changePostColor,
    removePostColor,
    setCoverPost,
    unsetCoverPost,
    showReportPanel,
  }
})
