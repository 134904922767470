// @file Surface sections store
import device from '@@/bits/device'
import { captureException, captureFetchException } from '@@/bits/error_tracker'
import { __ } from '@@/bits/intl'
import { $ } from '@@/bits/jquery'
import { MAX_SECTION_TITLE_LENGTH, MIN_SECTION_TITLE_LENGTH } from '@@/bits/numbers'
import { getVuexStore } from '@@/bits/pinia'
import { isPostPinned } from '@@/bits/post_state'
import sectionSorter from '@@/bits/section_sorter'
import { vDel } from '@@/bits/vue'
import { useGlobalAlertDialogStore } from '@@/pinia//global_alert_dialog'
import {
  OzConfirmationDialogBoxButtonScheme,
  useGlobalConfirmationDialogStore,
} from '@@/pinia/global_confirmation_dialog'
import { SnackbarNotificationType, useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import { useNativeAppStore } from '@@/pinia/native_app'
import { useSurfaceStore } from '@@/pinia/surface'
import { useSurfaceContainerSizeStore } from '@@/pinia/surface_container_size'
import { useSurfaceDraftsStore } from '@@/pinia/surface_drafts'
import { useSurfaceMapStore } from '@@/pinia/surface_map'
import { useSurfaceOnboardingDemoPadletPanelStore } from '@@/pinia/surface_onboarding_demo_padlet_panel_store'
import { useSurfacePostsStore } from '@@/pinia/surface_posts'
import { useSurfaceShareLinksStore } from '@@/pinia/surface_share_links'
import { WallApi } from '@@/surface/api/wall'
import { WallSectionApi } from '@@/surface/api/wall_section'
import type { DeleteSectionOptions, Id, Section } from '@@/types'
import type { RootState } from '@@/vuexstore/surface/types'
import type { JsonAPIResource, JsonAPIResponse } from '@padlet/arvo'
import { isEmpty } from 'lodash-es'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

const isSectionNew = (section: Section): boolean => {
  return String(section.id).startsWith('new_')
}

export const useSurfaceSectionsStore = defineStore('surfaceSections', () => {
  const surfaceVuexStore = getVuexStore<RootState>() // For gradual conversion to pinia
  const globalAlertDialogStore = useGlobalAlertDialogStore()
  const globalSnackbarStore = useGlobalSnackbarStore()
  const nativeAppStore = useNativeAppStore()
  const surfaceContainerSizeStore = useSurfaceContainerSizeStore()
  const surfaceStore = useSurfaceStore()
  const surfaceMapStore = useSurfaceMapStore()
  const surfacePostsStore = useSurfacePostsStore()
  const surfaceShareLinksStore = useSurfaceShareLinksStore()
  const surfaceDemoPadletPanelStore = useSurfaceOnboardingDemoPadletPanelStore()

  /*
   * STATE
   */
  const sectionEntities = ref<Record<Id, Section>>({})
  const areSectionsFetched = ref(false)
  const defaultSectionId = ref<Id>()
  const sectionRecentlyCreatedId = ref<Id | null>(null)
  const sectionBeingRenamedId = ref<Id | null>(null)
  const mostRecentlyTouchedSectionId = ref<Id | null>(null)
  const sectionUnderActionId = ref<Id | null>(null)
  const sectionUnderActionRightClickCoordinates = ref<{ x: number; y: number } | null>(null)
  const isASectionDragging = ref(false)

  /*
   * GETTERS
   */
  const sectionUnderAction = computed(() => getSectionById(sectionUnderActionId.value))
  const sectionIds = computed<Id[]>(() => Object.keys(sectionEntities.value).map((id) => Number(id)))
  const sortedSections = computed<Section[]>(() => {
    if (surfaceStore.isSectionBreakout) {
      const breakoutSection = getSectionById(surfaceStore.breakoutSectionId)
      return breakoutSection == null ? [] : [breakoutSection]
    }
    return sectionSorter.sortSections(Object.values(sectionEntities.value))
  })
  const sortedSectionIds = computed(() => {
    return sortedSections.value.filter((s) => s.id != null).map((s) => s.id)
  })
  const usableSections = computed(() => Object.values(sectionEntities.value).filter((s) => !isSectionNew(s)))
  const numOfSections = computed(() => sectionIds.value.length)
  const hasFetchedSections = computed<boolean>(() => (surfaceStore.canUseSections ? areSectionsFetched.value : true))

  const wallHasSections = computed(
    () => !isEmpty(sortedSectionIds.value) && surfaceStore.isSectioned && surfaceStore.canUseSections,
  )

  const assignedWallSectionId = computed<Id | null>(() => surfaceVuexStore?.getters['post/assignedWallSectionId'])

  /*
   * RENAME SECTION
   */
  // https://whimsical.com/sections-0-1-stream-UwXiARuawScRnEGKF2bScC@2bsEvpTYFZsxPP7WckcQqNHxJtrkdXQAyBZ
  const xSectionSpecificButtons = computed<boolean>(() => {
    if (surfaceStore.isGrid) return false
    if (surfaceStore.isMap) return surfaceMapStore.postListPosition === 'side'
    if (device.touchable) return !surfaceContainerSizeStore.isContainerSmallerThanDesktop
    return !surfaceContainerSizeStore.isContainerSmallerThanTabletPortrait
  })

  const allowOpenSectionActionMenu = computed<boolean>(() => {
    // grid has no section specific buttons so handle accordlingly
    if (surfaceStore.isGrid && surfaceContainerSizeStore.isContainerSmallerThanTabletPortrait) return true
    if (surfaceStore.isGrid && !surfaceContainerSizeStore.isContainerSmallerThanTabletPortrait) return false
    return device.touchable || !xSectionSpecificButtons.value
  })

  const canEditSectionNameInline = computed<boolean>(() => {
    return (
      !allowOpenSectionActionMenu.value &&
      !surfaceDemoPadletPanelStore.isDemoPadletPanelDesktop &&
      !surfaceContainerSizeStore.isContainerSmallerThanTabletLandscape &&
      !surfaceStore.isSectionBreakout
    )
  })

  const xSectionRenameModal = computed<boolean>(
    () => sectionBeingRenamedId.value != null && !canEditSectionNameInline.value,
  )

  /*
   * GETTER FUNCTIONS
   */
  function getSectionById(sectionId: Id | null): Section | null {
    if (sectionId == null) return null
    return sectionEntities.value[sectionId]
  }

  function isSectionEmpty(sectionId: Id): boolean {
    const postsInSection = surfacePostsStore.postsBySectionId[sectionId]
    return postsInSection == null || postsInSection.length === 0
  }

  function hasPinnedPosts(sectionId: Id): boolean {
    const postsInSection = surfacePostsStore.postsBySectionId[sectionId] ?? []
    return postsInSection.some(isPostPinned)
  }

  function hasUnpinnedPosts(sectionId: Id): boolean {
    const postsInSection = surfacePostsStore.postsBySectionId[sectionId] ?? []
    return postsInSection.some((p) => !isPostPinned(p))
  }

  /*
   * STATE ACTIONS
   */
  function hideSectionActionMenu(): void {
    sectionUnderActionId.value = null
    sectionUnderActionRightClickCoordinates.value = null
  }

  function updateSectionEntities(json: JsonAPIResponse<Section>): void {
    const sections = (json.data as Array<JsonAPIResource<Section>>).map((d) => d.attributes)
    sectionEntities.value = sections.reduce<Record<Id, Section>>((acc, section) => {
      acc[section.id] = section
      return acc
    }, {})
    areSectionsFetched.value = true
    void nativeAppStore.postSurfaceState()
  }

  async function fetchSections(): Promise<void> {
    try {
      const json: JsonAPIResponse<Section> = await WallSectionApi.readAll({ wall: { id: surfaceStore.wallId } })
      updateSectionEntities(json)
    } catch (e) {
      captureFetchException(e, { source: 'fetchSections' })
    }
  }

  function insertSection(section: Section): void {
    if (section.id == null) return
    const existingSection = sectionEntities.value[section.id]
    if (existingSection != null) return // don't insert if already exists

    sectionEntities.value = {
      ...sectionEntities.value,
      [section.id]: section,
    }
  }

  function updateSection(section: Section): void {
    if (section.id == null) return

    // upsert the section
    sectionEntities.value = {
      ...sectionEntities.value,
      [section.id]: section,
    }
  }

  function removeSection(section: Section): void {
    vDel(sectionEntities.value, section.id)
  }

  /*
   * CREATE ACTIONS
   */
  /**
   * Create a new section and with the given title
   * @param title Name of section
   * @param sortIndex If not provided, the new section will be created at the bottom of the wall.
   */
  async function createSectionWithName(
    title: string | null,
    options?: {
      promptRenameSection?: boolean
      sortIndex?: number
      gapSize?: number
    },
  ): Promise<Section | null> {
    try {
      const defaultSectionTitle = __('Section %{number}', {
        number: numOfSections.value + 1,
      })

      const sectionData = await WallSectionApi.create({
        wall_id: surfaceVuexStore?.getters.wallId,
        title: title ?? defaultSectionTitle,
        sort_index: options?.sortIndex ?? sectionSorter.getBottomSortIndex(),
        gap_size: options?.gapSize ?? null,
      })

      const section = (sectionData.data as JsonAPIResource<Section>).attributes
      insertSection(section)
      sectionRecentlyCreatedId.value = section.id ?? null

      if (options?.promptRenameSection === true) sectionBeingRenamedId.value = section.id ?? null

      updateMostRecentlyTouchedSection(section.id)
      void useNativeAppStore().postSurfaceState()

      void surfaceShareLinksStore.fetchSectionBreakoutLinks()

      return section
    } catch (e) {
      useGlobalSnackbarStore().setSnackbar({
        notificationType: SnackbarNotificationType.error,
        message: __('Failed to create section'),
      })
      captureException(e)
      return null
    }
  }

  /**
   * Create a new section and give it a default name: `Section 1`, `Section 2`,... `Section n`
   * where `n` is the number of sections in the wall.
   * @param sortIndex If not provided, the new section will be created at the bottom of the wall.
   */
  async function createSectionWithDefaultName(options?: {
    promptRenameSection?: boolean
    sortIndex?: number
    gapSize?: number
  }): Promise<void> {
    await createSectionWithName(null, options)
  }

  function createSectionBefore(sectionId: Id): void {
    const { sortIndex, gapSize } = sectionSorter.getNewIndexBefore($(`#section-${sectionId}`))
    const createSectionOptions = {
      sortIndex,
      gapSize,
      promptRenameSection: true,
    }
    void createSectionWithDefaultName(createSectionOptions)
  }

  function createSectionAfter(sectionId: Id): void {
    const { sortIndex, gapSize } = sectionSorter.getNewIndexAfter($(`#section-${sectionId}`))
    const createSectionOptions = {
      sortIndex,
      gapSize,
      promptRenameSection: true,
    }
    void createSectionWithDefaultName(createSectionOptions)
  }

  /*
   * DEFAULT SECTION ACTIONS
   */

  function updateMostRecentlyTouchedSection(sectionId: Id): void {
    mostRecentlyTouchedSectionId.value = sectionId
  }

  function setDefaultSectionAndRecentlyTouchedSection(sectionId: Id): void {
    defaultSectionId.value = sectionId
    updateMostRecentlyTouchedSection(sectionId)
  }

  async function changeDefaultSection(sectionId: Id): Promise<void> {
    try {
      await WallApi.update({
        ...surfaceVuexStore?.state.wall,
        wish_arrangement: {
          ...surfaceVuexStore?.state.wall.wish_arrangement,
          default_section_id: sectionId,
        },
      })

      setDefaultSectionAndRecentlyTouchedSection(sectionId)

      globalSnackbarStore.setSnackbar({
        notificationType: SnackbarNotificationType.success,
        message: __('Default section changed'),
      })
    } catch (e) {
      globalSnackbarStore.setSnackbar({
        notificationType: SnackbarNotificationType.error,
        message: __('Failed to update default section'),
      })
      captureException(e)
    }
  }

  /*
   * EDIT ACTIONS
   */

  function editSectionTitle(payload: { sectionId: Id; title: string }): boolean {
    const { sectionId, title } = payload
    const trimmedTitle = title.trim()

    // Validation
    const isTitleTooShort = trimmedTitle.length < MIN_SECTION_TITLE_LENGTH
    const isTitleTooLong = trimmedTitle.length > MAX_SECTION_TITLE_LENGTH
    if (isTitleTooShort) {
      globalAlertDialogStore.openAlertDialog({
        title: __('Section name is too short'),
        body: __('Section name must be at least %{min} characters long.', { min: MIN_SECTION_TITLE_LENGTH }),
        closeButtonText: __('OK'),
        shouldFadeIn: true,
      })
      return false
    }

    if (isTitleTooLong) {
      globalAlertDialogStore.openAlertDialog({
        title: __('Section name is too long'),
        body: __('Section name must be at most %{max} characters long.', { max: MAX_SECTION_TITLE_LENGTH }),
        closeButtonText: __('OK'),
        shouldFadeIn: true,
      })
      return false
    }

    // Update local state first so it appears to be updated immediately
    const section = sectionEntities.value[sectionId]
    if (section.title !== trimmedTitle) {
      updateSection({ ...section, title: trimmedTitle })
    }

    // Now update the server. Can be done asynchronously.
    void WallSectionApi.patch(section.id, { title: trimmedTitle })
    void useNativeAppStore().postSurfaceState()
    return true
  }

  function startRenamingSection(sectionId: Id): void {
    sectionBeingRenamedId.value = sectionId
  }

  function stopRenamingSection(): void {
    sectionBeingRenamedId.value = null
  }

  /*
   * DELETE ACTIONS
   */

  async function deleteSection(sectionId: Id, options: DeleteSectionOptions = { showSnackbar: true }): Promise<void> {
    const { showSnackbar = true } = options

    try {
      const existingSection = sectionEntities.value[sectionId]
      if (existingSection == null) return
      await WallSectionApi.delete(existingSection)
      removeSection(existingSection)
      if (showSnackbar) {
        globalSnackbarStore.setSnackbar({
          notificationType: SnackbarNotificationType.success,
          message: __('Section deleted'),
        })
      }

      if (sectionId === defaultSectionId.value) {
        // if deleted section is default, switch default to the first section to match new default in backend
        defaultSectionId.value = sortedSectionIds.value[0] ?? null
      }
      useSurfaceDraftsStore().reassignSections({
        oldSectionId: sectionId,
        newSectionId: defaultSectionId.value as number,
      })
      void nativeAppStore.postSurfaceState()
    } catch (e) {
      globalSnackbarStore.genericFetchError()
      captureFetchException(e, { source: 'deleteSection' })
    }
  }

  function askToDeleteSection(sectionId: Id | null): void {
    if (sectionId == null) return
    if (sortedSections.value.length === 1) {
      globalAlertDialogStore.openAlertDialog({
        title: __('Section cannot be deleted'),
        body: __('At least one section is required. Please create a new section if you want to delete this one.'),
        closeButtonText: __('OK'),
        shouldFadeIn: true,
      })
    } else {
      void useGlobalConfirmationDialogStore().openConfirmationDialog({
        title: __('Delete this section?'),
        body: __('Are you sure you want to delete this section and all its posts? This cannot be undone!'),
        confirmButtonText: __('Delete'),
        cancelButtonText: __('Cancel'),
        buttonScheme: OzConfirmationDialogBoxButtonScheme.Danger,
        afterConfirmActions: [async () => await deleteSection(sectionId)],
      })
    }
  }

  /*
   * MOVE ACTIONS
   */

  function sortSection(payload: { sectionId: Id; sortIndex: number; gapSize: number }): void {
    const { sectionId, sortIndex, gapSize } = payload
    const sectionToChange = getSectionById(sectionId)
    if (sectionToChange == null) {
      throw new Error("Section doesn't exist")
    }
    const isSameIndex = sectionToChange.sort_index === sortIndex
    const isStuck = gapSize <= 4
    if (isSameIndex && !isStuck) return

    const updatedSection = { ...sectionToChange, sort_index: sortIndex }
    updateSection(updatedSection)
    void WallSectionApi.patch(updatedSection.id, { sort_index: sortIndex }, gapSize)
    void useNativeAppStore().postSurfaceState()
  }

  function moveSectionBefore(sectionId: Id): void {
    const index = sortedSectionIds.value.indexOf(sectionId)

    // No-op if already the first section
    if (index <= 0) return

    const section = sortedSections.value[index]
    let sortIndex = section.sort_index
    let gapSize: number | null = null

    if (index === 1) {
      // If is second-to-first section, use top sort index
      // to become the new first section
      sortIndex = sectionSorter.getTopSortIndex()
      const currentFirstSection = sortedSections.value[0]
      gapSize = currentFirstSection.sort_index - sortIndex
    } else {
      const newBeforeSection = sortedSections.value[index - 2]
      const newAfterSection = sortedSections.value[index - 1]
      sortIndex = (newAfterSection.sort_index + newBeforeSection.sort_index) / 2
      gapSize = (newAfterSection.sort_index - newBeforeSection.sort_index) / 2
    }

    sortSection({ sectionId, sortIndex, gapSize })
  }

  function moveSectionAfter(sectionId: Id): void {
    const index = sortedSectionIds.value.indexOf(sectionId)

    // No-op if already the last section
    if (index === -1 || index >= sortedSectionIds.value.length - 1) return

    const section = sortedSections.value[index]
    let sortIndex = section.sort_index
    let gapSize: number | null = null

    if (index === sortedSections.value.length - 2) {
      // If is second-to-last section, use bottom sort index
      // to become the new last section
      sortIndex = sectionSorter.getBottomSortIndex()
      const currentLastSection = sortedSections.value[sortedSections.value.length - 1]
      gapSize = sortIndex - currentLastSection.sort_index
    } else {
      const newBeforeSection = sortedSections.value[index + 1]
      const newAfterSection = sortedSections.value[index + 2]
      sortIndex = (newAfterSection.sort_index + newBeforeSection.sort_index) / 2
      gapSize = (newAfterSection.sort_index - newBeforeSection.sort_index) / 2
    }

    sortSection({ sectionId, sortIndex, gapSize })
  }

  /*
   * REMOTE ACTION
   */
  function insertSectionRemote(section: Section): void {
    insertSection(section)
    void nativeAppStore.postSurfaceState()
  }

  function updateSectionRemote(section: Section): void {
    updateSection(section)
    void nativeAppStore.postSurfaceState()
  }

  function removeSectionRemote(section: Section): void {
    removeSection(section)
    void nativeAppStore.postSurfaceState()
  }

  return {
    // state
    sectionEntities,
    sectionRecentlyCreatedId,
    sectionBeingRenamedId,
    mostRecentlyTouchedSectionId,
    sectionUnderActionId,
    sectionUnderActionRightClickCoordinates,
    isASectionDragging,
    wallHasSections,

    // getters
    sectionUnderAction,
    defaultSectionId,
    sectionIds,
    sortedSections,
    sortedSectionIds,
    usableSections,
    numOfSections,
    canEditSectionNameInline,
    xSectionRenameModal,
    xSectionSpecificButtons,
    hasFetchedSections,
    assignedWallSectionId,

    // getter functions
    getSectionById,
    isSectionEmpty,
    hasPinnedPosts,
    hasUnpinnedPosts,

    // actions
    hideSectionActionMenu,
    updateSectionEntities,
    fetchSections,
    insertSectionRemote,
    updateSectionRemote,
    removeSectionRemote,
    updateMostRecentlyTouchedSection,
    setDefaultSectionAndRecentlyTouchedSection,
    changeDefaultSection,
    createSectionWithName,
    createSectionWithDefaultName,
    createSectionBefore,
    createSectionAfter,
    editSectionTitle,
    startRenamingSection,
    stopRenamingSection,
    deleteSection,
    askToDeleteSection,
    sortSection,
    moveSectionBefore,
    moveSectionAfter,
  }
})
