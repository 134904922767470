// @file Network requests related to wall exports from surface

import { transformCurrentUrl } from '@@/bits/location'
import { useSurfaceStore } from '@@/pinia/surface'
import type { WhiteboardPdfExportParams } from '@@/pinia/whiteboard_exports'
import FetchableObject from '@@/surface/api/fetchable_object'
import { HTTPMethod } from '@padlet/fetch'

class WallExportsApi extends FetchableObject {
  public static get fetchDownloadWhiteboardPagesZipUrl(): string {
    return `/padlets/\${ publicKey }/exports/zip-whiteboard`
  }

  public static async downloadWhiteboardPagesZip(): Promise<any> {
    return await fetch(
      this.buildUrl(this.fetchDownloadWhiteboardPagesZipUrl, {
        publicKey: useSurfaceStore()?.publicKey ?? '',
      }),
      {
        method: HTTPMethod.get,
      },
    )
  }

  public static get fetchDownloadWhiteboardPagesPdfUrl(): string {
    return `/padlets/\${ publicKey }/exports/whiteboard-pdf`
  }

  public static async downloadWhiteboardPagesPdf(params?: WhiteboardPdfExportParams): Promise<any> {
    const path = this.buildUrl(this.fetchDownloadWhiteboardPagesPdfUrl, {
      publicKey: useSurfaceStore().publicKey,
    })

    const url = transformCurrentUrl(
      {},
      {
        path,
        search: params !== undefined ? (params as any as Record<string, string>) : undefined,
      },
    )

    return await fetch(url, {
      method: HTTPMethod.get,
    })
  }
}

export { WallExportsApi }
