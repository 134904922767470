// @file Network requests related to padlet starting state from surface

import { currentPathWithoutLeadingSlash, getSearchParams, transformCurrentUrl } from '@@/bits/location'
import FetchableObject from '@@/surface/api/fetchable_object'
import { fetchJson } from '@@/surface/api_fetch'
import type { SurfaceState } from '@@/vuexstore/surface/types'
import { HTTPMethod } from '@padlet/fetch'

class PadletStartingStateApi extends FetchableObject {
  public static async fetch(fetchOptions = {}): Promise<SurfaceState> {
    const startingStatePath = transformCurrentUrl(
      {},
      {
        path: `/api/6/padlet-starting-state/${currentPathWithoutLeadingSlash()}`,
        searchParams: getSearchParams(), // We need to pass the search params to the api controllers
      },
    )
    const response = await fetchJson(startingStatePath, {
      method: HTTPMethod.get,
      ...fetchOptions,
    })
    return response
  }
}

export { PadletStartingStateApi }
