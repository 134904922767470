// @file The store for handling the surface onboarding demo padlet panel copy
import { isAppUsing } from '@@/bits/flip'
import { __ } from '@@/bits/intl'
import {
  StepStatus,
  useSurfaceOnboardingDemoPadletPanelStore,
} from '@@/pinia/surface_onboarding_demo_padlet_panel_store'
import { useSurfaceSectionsStore } from '@@/pinia/surface_sections'
import { useSurfaceStartingStateStore } from '@@/pinia/surface_starting_state'
import type { PostAttributes, Section } from '@@/types'
import { defineStore } from 'pinia'
import { computed } from 'vue'

export enum OnboardingType {
  General = 'general',
  Teacher = 'teacher',
}

interface LandingPanelStep {
  text: string
}

type StepPanelContent = {
  [key in StepStatus]: {
    heading: string
    subheading?: string
    showButton: boolean
  }
}

interface SurfaceTitleCopy {
  title: string
  description: string
}
interface SharePanelSpinnerCopy {
  isSendingOutLinksCompleted: string
  isMakingContributionsCompleted: string
  isCollaboratingAndInteractingCompleted: string
  isSendingOutLinksInProgress: string
  isMakingContributionsInProgress: string
  isCollaboratingAndInteractingInProgress: string
}

interface CommentData {
  name: string
  avatar: string
  commentBody: string
}

interface PostAttributionData {
  name: string
  avatar: string
}

export const useSurfaceOnboardingDemoPadletPanelCopyStore = defineStore(
  'surfaceOnboardingDemoPadletPanelCopyStore',
  () => {
    const surfaceStartingStateStore = useSurfaceStartingStateStore()
    const surfaceDemoPadletPanelStore = useSurfaceOnboardingDemoPadletPanelStore()

    const isTeacher = computed<boolean>(() => {
      return surfaceStartingStateStore.startingState.onboardingFlow?.isTeacher ?? false
    })
    const userOnboardingType = computed<OnboardingType>(() => {
      return isTeacher.value && isAppUsing('onboardingDemoV2Copy') ? OnboardingType.Teacher : OnboardingType.General
    })

    // ------------------------------
    // Landing step
    // ------------------------------

    // TODO: ENG-19270 rename this getter to landingPanelContent
    const landingPanelSteps = computed((): LandingPanelStep[] => getLandingPanelSteps())
    function getLandingPanelSteps(): LandingPanelStep[] {
      if (!isAppUsing('onboardingDemoV2Copy')) {
        return [{ text: __('Set a topic') }, { text: __('Add some posts') }, { text: __('Share and collaborate') }]
      }
      switch (userOnboardingType.value) {
        case OnboardingType.Teacher:
          return [
            { text: __('Set a topic for your lesson activity') },
            { text: __('Add some posts') },
            { text: __('Share and collaborate') },
          ]
        default:
          return [{ text: __('Set a topic') }, { text: __('Add some posts') }, { text: __('Share and collaborate') }]
      }
    }

    // ------------------------------
    // Topic step
    // ------------------------------

    const currentTopicPanelCopy = computed(() => {
      const allTopicPanelCopy = getTopicPanelCopy()
      return allTopicPanelCopy[surfaceDemoPadletPanelStore.activeStepStatus]
    })

    function getTopicPanelCopy(): StepPanelContent {
      switch (userOnboardingType.value) {
        case OnboardingType.Teacher:
          return {
            [StepStatus.NotStarted]: {
              heading: __(
                'Teachers use Padlet for all kinds of activities. To start, let’s create a discussion board!',
              ),
              subheading: __('Start by clicking the “Next” button below.'),
              showButton: true,
            },
            [StepStatus.InProgress]: {
              heading: __('For this example, we’ll create a gratitude board to share with your class.'),
              subheading: __('Time to add some posts to your new padlet!'),
              showButton: true,
            },
            [StepStatus.Complete]: {
              heading: __('We added a title and some sections to get you started.'),
              subheading: __('Time to add some posts to your new padlet!'),
              showButton: true,
            },
          }
        default:
          return {
            [StepStatus.NotStarted]: {
              heading: __("Let's create a padlet about places around the world!"),
              subheading: __('Start by clicking the "Next" button below.'),
              showButton: true,
            },
            [StepStatus.InProgress]: {
              heading: __('Just a moment, your padlet is coming to life...'),
              showButton: true,
            },
            [StepStatus.Complete]: {
              heading: __('We added a title and some sections to get you started.'),
              subheading: __('Time to add some posts to your new padlet!'),
              showButton: true,
            },
          }
      }
    }

    // ------------------------------
    // Post step
    // ------------------------------

    const currentPostPanelCopy = computed(() => {
      const allPostPanelCopy = getPostPanelCopy()
      return allPostPanelCopy[surfaceDemoPadletPanelStore.activeStepStatus]
    })

    function getPostPanelCopy(): StepPanelContent {
      switch (userOnboardingType.value) {
        case OnboardingType.Teacher:
          return {
            [StepStatus.NotStarted]: {
              heading: __("Let's add your first post!"),
              subheading: __('Click the “Next” button to add an example post for your students to follow.'),
              showButton: true,
            },
            [StepStatus.InProgress]: {
              heading: __('Here’s an example for you!'),
              subheading: __('Tweak it to your liking and hit “Publish” to add this post to your padlet.'),
              showButton: true,
            },
            [StepStatus.Complete]: {
              heading: __('Awesome work!'),
              subheading: __('Now, let’s share your padlet to fill it with more posts!'),
              showButton: true,
            },
          }
        default:
          return {
            [StepStatus.NotStarted]: {
              heading: __('Let’s add your first post!'),
              subheading: __(
                'Click the "Next" button to add your thoughts, images, or links and watch your ideas take flight.',
              ),
              showButton: true,
            },
            [StepStatus.InProgress]: {
              heading: __('Here’s an idea for your first post!'),
              subheading: __('Tweak it to your liking and hit "Publish" to add this post to your padlet.'),
              showButton: true,
            },
            [StepStatus.Complete]: {
              heading: __('Awesome work!'),
              subheading: __('Let’s share your padlet to fill it with more posts!'),
              showButton: true,
            },
          }
      }
    }

    // ------------------------------
    // Share step
    // ------------------------------

    const currentSharePanelCopy = computed(() => {
      const allSharePanelCopy = getSharePanelCopy()
      return allSharePanelCopy[surfaceDemoPadletPanelStore.activeStepStatus]
    })

    function getSharePanelCopy(): StepPanelContent {
      switch (userOnboardingType.value) {
        case OnboardingType.Teacher:
          return {
            [StepStatus.NotStarted]: {
              heading: __(
                'For this first go, we’ll act as your students so you can see what real-time collaboration on Padlet is like!',
              ),
              showButton: true,
            },
            [StepStatus.InProgress]: {
              heading: '',
              showButton: true,
            },
            [StepStatus.Complete]: {
              heading: __('Check it out! Your students have discovered your padlet and added their own touches.'),
              showButton: true,
            },
          }
        default:
          return {
            [StepStatus.NotStarted]: {
              heading: __(
                'For this first go, we’ll act as your friends so you can see what real-time collaboration on Padlet is like!',
              ),
              showButton: true,
            },
            [StepStatus.InProgress]: {
              heading: '',
              showButton: true,
            },
            [StepStatus.Complete]: {
              heading: __('Check it out! New friends have discovered your padlet and added their own touches.'),
              showButton: true,
            },
          }
      }
    }

    // ------------------------------
    // Section names based on onboarding type
    // ------------------------------
    const sectionNames = computed(() => {
      switch (userOnboardingType.value) {
        case OnboardingType.Teacher:
          return [__('School & Learning'), __('Family & Friends'), __('Activities & Hobbies')]
        default:
          return [__('Favorite places'), __('Wishlist destinations'), __('No-go zones')]
      }
    })

    // ------------------------------
    // General
    // ------------------------------

    function getCommentData(): CommentData[][] {
      // The index of the array represent the post number of the non user-added posts
      switch (userOnboardingType.value) {
        case OnboardingType.Teacher:
          return [
            // First post added comments
            [],
            // Second post added comments
            [
              {
                name: __('Liam'),
                avatar:
                  'https://v1.padlet.pics/1/image.webp?t=c_thumb%2Cdpr_2%2Cg_auto%2Ch_128%2Cw_128&url=https%3A%2F%2Fpadlet.net%2Fmonsters%2F17.png',
                commentBody: __('Glad to have you on our team!'),
              },
            ],
            // Third post added comments
            [
              {
                name: __('Sophia'),
                avatar:
                  'https://v1.padlet.pics/1/image.webp?t=c_thumb%2Cdpr_2%2Cg_auto%2Ch_128%2Cw_128&url=https%3A%2F%2Fpadlet.net%2Fmonsters%2F20.png',
                commentBody: __('Love you too!'),
              },
            ],
            // Fourth post added comments
            [],
            // Fifth post added comments
            [
              {
                name: __('Mia'),
                avatar:
                  'https://v1.padlet.pics/1/image.webp?t=c_thumb%2Cdpr_2%2Cg_auto%2Ch_128%2Cw_128&url=https%3A%2F%2Fpadlet.net%2Fmonsters%2F19.png',
                commentBody: __('Aw you look so cute here'),
              },
              {
                name: __('Liam'),
                avatar:
                  'https://v1.padlet.pics/1/image.webp?t=c_thumb%2Cdpr_2%2Cg_auto%2Ch_128%2Cw_128&url=https%3A%2F%2Fpadlet.net%2Fmonsters%2F17.png',
                commentBody: __('Love this!'),
              },
            ],
          ]
        default:
          return [
            // First post added comments
            [
              {
                name: __('Minnie'),
                avatar:
                  'https://images.unsplash.com/photo-1663250422517-569ac20aec47?q=80&w=128&h=128&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
                commentBody: __('Same here!'),
              },
            ],
            // Second post added comments
            [
              {
                name: __('Shannon'),
                avatar:
                  'https://v1.padlet.pics/1/image.webp?t=c_thumb%2Cdpr_2%2Cg_auto%2Ch_128%2Cw_128&url=https%3A%2F%2Fpadlet.net%2Fmonsters%2F16.png',
                commentBody: __('Love that movie'),
              },
              {
                name: __('Nitesh'),
                avatar:
                  'https://v1.padlet.pics/1/image.webp?t=c_thumb%2Cdpr_2%2Cg_auto%2Ch_128%2Cw_128&url=https%3A%2F%2Fpadlet.net%2Fmonsters%2F24.png',
                commentBody: __('An interesting place indeed...'),
              },
            ],
            // Third post added comments
            [],
          ]
      }
    }

    function getPostAttributionData(): PostAttributionData[] {
      switch (userOnboardingType.value) {
        case OnboardingType.Teacher:
          return [
            {
              name: __('Lily'),
              avatar:
                'https://v1.padlet.pics/1/image.webp?t=c_thumb%2Cdpr_2%2Cg_auto%2Ch_128%2Cw_128&url=https%3A%2F%2Fpadlet.net%2Fmonsters%2F25.png',
            },
            {
              name: __('Ethan'),
              avatar:
                'https://v1.padlet.pics/1/image.webp?t=c_thumb%2Cdpr_2%2Cg_auto%2Ch_128%2Cw_128&url=https%3A%2F%2Fpadlet.net%2Fmonsters%2F21.png',
            },
            {
              name: __('Jordan'),
              avatar:
                'https://v1.padlet.pics/1/image.webp?t=c_thumb%2Cdpr_2%2Cg_auto%2Ch_128%2Cw_128&url=https%3A%2F%2Fpadlet.net%2Fmonsters%2F22.png',
            },
            {
              name: __('Chris'),
              avatar:
                'https://v1.padlet.pics/1/image.webp?t=c_thumb%2Cdpr_2%2Cg_auto%2Ch_128%2Cw_128&url=https%3A%2F%2Fpadlet.net%2Fmonsters%2F23.png',
            },
            {
              name: __('Taylor'),
              avatar:
                'https://v1.padlet.pics/1/image.webp?t=c_thumb%2Cdpr_2%2Cg_auto%2Ch_128%2Cw_128&url=https%3A%2F%2Fpadlet.net%2Fmonsters%2F24.png',
            },
          ]
        default:
          return [
            {
              name: __('Daisy'),
              avatar: 'https://padlet.net/avatars/onboarding_demo/daisy.png',
            },
            {
              name: __('Alice'),
              avatar: 'https://padlet.net/avatars/onboarding_demo/alice.png',
            },
            {
              name: __('Sherlock Holmes'),
              avatar: 'https://padlet.net/avatars/onboarding_demo/sherlock_holmes.png',
            },
          ]
      }
    }

    function getPostDataWithSections(sections: Section[]): PostAttributes[] {
      switch (userOnboardingType.value) {
        case OnboardingType.Teacher:
          return [
            {
              subject: __('My wonderful students'),
              body: __("I'm so grateful to have the most wonderful and eager to learn students at the school!"),
              attachment:
                'https://padlet-artifacts.storage.googleapis.com/e4910f1c8cd19d06f1580f9e3e37df7f1d14dc7e/e77ec91e05316d614a99d646748b31e9-h-e4cef1836afdc580934005cf0c6741f8.jpeg',
              wish_content: {
                attachment_props: {
                  url: 'https://padlet-artifacts.storage.googleapis.com/e4910f1c8cd19d06f1580f9e3e37df7f1d14dc7e/e77ec91e05316d614a99d646748b31e9-h-e4cef1836afdc580934005cf0c6741f8.jpeg',
                },
              },
              wallSectionId: sections[0]?.id,
              wall_section_hashid: sections[0]?.hashid,
            },
            {
              subject: __('<3 Soccer'),
              body: __("I don't know what I'd do without soccer! It's the best sport ever!"),
              attachment:
                'https://padlet-artifacts.storage.googleapis.com/4fe660442519a5fec8d086bbcf43bab1f31433e3/e1010c8f471f0fb8e9ea08dcdb08bd12-h-01d03348c5133ea511d7337501c79b34.jpeg',
              wish_content: {
                attachment_props: {
                  url: 'https://padlet-artifacts.storage.googleapis.com/4fe660442519a5fec8d086bbcf43bab1f31433e3/e1010c8f471f0fb8e9ea08dcdb08bd12-h-01d03348c5133ea511d7337501c79b34.jpeg',
                },
              },
              wallSectionId: sections[2]?.id,
              wall_section_hashid: sections[2]?.hashid,
            },
            {
              subject: __('Best project team!'),
              body: __(
                'Thankful for my project team that has helped answer all of my questions! They’ve been so nice and helpful.',
              ),
              wallSectionId: sections[0]?.id,
              wall_section_hashid: sections[0]?.hashid,
            },
            {
              subject: __('Best friends forever!'),
              body: __(
                'Shoutout to my besties Sophia and Alex! They always have my back. So thankful for our weekly movie nights too!',
              ),
              wallSectionId: sections[1]?.id,
              wall_section_hashid: sections[1]?.hashid,
            },
            {
              subject: __('Thankful for the band'),
              body: __(
                'Thanks to our band for giving me a place where I belong! Playing trumpet is my favorite part of the day now :)',
              ),
              attachment:
                'https://padlet-artifacts.storage.googleapis.com/0305f33a35f28127966b77ae56cd2caf7af9a4c1/24614168e2d523f1cbcf875e7cdcdac8-h-488bc769bfca32f4d0090c0707a752b8.gif',
              wish_content: {
                attachment_props: {
                  url: 'https://padlet-artifacts.storage.googleapis.com/0305f33a35f28127966b77ae56cd2caf7af9a4c1/24614168e2d523f1cbcf875e7cdcdac8-h-488bc769bfca32f4d0090c0707a752b8.gif',
                },
              },
              wallSectionId: sections[2]?.id,
              wall_section_hashid: sections[2]?.hashid,
            },
            {
              subject: __('My amazing mom <3'),
              body: __("I'm really grateful for my mom who always helps me and supports me no matter what."),
              attachment:
                'https://padlet-artifacts.storage.googleapis.com/d167a5499bbe4eeeb29e9e473627a11b2e8cc5c3/0ae0d1e04df6a8046a6bd8e27ece7033-h-4e96fccfd5b6cd31002ac44d7ce26d51.jpg',
              wish_content: {
                attachment_props: {
                  url: 'https://padlet-artifacts.storage.googleapis.com/d167a5499bbe4eeeb29e9e473627a11b2e8cc5c3/0ae0d1e04df6a8046a6bd8e27ece7033-h-4e96fccfd5b6cd31002ac44d7ce26d51.jpg',
                },
              },
              wallSectionId: sections[1]?.id,
              wall_section_hashid: sections[1]?.hashid,
            },
          ]
        default:
          return [
            {
              subject: __('Golden Gate Bridge'),
              attachment:
                'https://images.unsplash.com/photo-1521747116042-5a810fda9664?q=80&w=2970&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
              wish_content: {
                attachment_props: {
                  url: 'https://images.unsplash.com/photo-1521747116042-5a810fda9664?q=80&w=2970&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
                },
              },
              location_name: __('San Francisco, CA'),
              locationPoint: {
                latitude: 37.7896,
                longitude: -122.4076,
              },
              wallSectionId: sections[0]?.id,
              wall_section_hashid: sections[0]?.hashid,
            },
            {
              subject: __('Disney World'),
              body: __('Daisy here! 🌼 Just had a blast at Disney World. Met so many friends – real and costumed! 🎡'),
              attachment:
                'https://images.unsplash.com/photo-1626816205347-91b13437b39a?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3',
              wish_content: {
                attachment_props: {
                  url: 'https://images.unsplash.com/photo-1626816205347-91b13437b39a?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3',
                },
              },
              attachment_caption: __('30 year anniversary'),
              location_name: __('Disney World, Orlando'),
              locationPoint: {
                latitude: 28.385233,
                longitude: -81.563873,
              },
              wallSectionId: sections[0]?.id,
              wall_section_hashid: sections[0]?.hashid,
            },
            {
              subject: __('Wonderland'),
              body: __(
                "Ever since I left, I've been longing to tumble back down the rabbit hole to revisit Wonderland. I miss the enigmatic Cheshire Cat's riddles, the whimsical tea parties with the Mad Hatter, and the Queen's eccentric croquet games. A trip back to that fantastical realm would be a dream come true!",
              ),
              attachment:
                'https://images.unsplash.com/photo-1621461526336-cfe08596d398?q=80&w=1632&auto=format&fit=crop&ixlib=rb-4.0.3',
              wish_content: {
                attachment_props: {
                  url: 'https://images.unsplash.com/photo-1621461526336-cfe08596d398?q=80&w=1632&auto=format&fit=crop&ixlib=rb-4.0.3',
                },
              },
              wallSectionId: sections[1]?.id,
              wall_section_hashid: sections[1]?.hashid,
            },
            {
              subject: __('Bermuda Triangle'),
              body: __(
                'The Bermuda Triangle? A mystery even I prefer to steer clear of. Too many unsolved disappearances for my taste!',
              ),
              attachment:
                'https://images.unsplash.com/photo-1627408413283-83bd1c42de2d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3',
              wish_content: {
                attachment_props: {
                  url: 'https://images.unsplash.com/photo-1627408413283-83bd1c42de2d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3',
                },
              },
              location_name: __('Western North Atlantic Ocean'),
              locationPoint: {
                latitude: 25.5117,
                longitude: -70.3493,
              },
              wallSectionId: sections[2]?.id,
              wall_section_hashid: sections[2]?.hashid,
            },
          ]
      }
    }

    function getSurfaceTitleCopy(): SurfaceTitleCopy {
      switch (userOnboardingType.value) {
        case OnboardingType.Teacher:
          return {
            title: __('Our class gratitude board'),
            description: __('A space for our class to share what they’re thankful for!'),
          }
        default:
          return {
            title: __('Places'),
            description: __('Let’s explore the world!'),
          }
      }
    }

    function getSharePanelSpinnerCopy(): SharePanelSpinnerCopy {
      switch (userOnboardingType.value) {
        case OnboardingType.Teacher:
          return {
            isSendingOutLinksInProgress: __('Inviting students...'),
            isSendingOutLinksCompleted: __('Invited students'),
            isMakingContributionsInProgress: __('Adding posts...'),
            isMakingContributionsCompleted: __('Added posts'),
            isCollaboratingAndInteractingInProgress: __('Commenting and reacting...'),
            isCollaboratingAndInteractingCompleted: __('Added comments and reactions'),
          }
        case OnboardingType.General:
          return {
            isSendingOutLinksInProgress: __('Inviting a few friends...'),
            isSendingOutLinksCompleted: __('Invited a few friends'),
            isMakingContributionsInProgress: __('Making some contributions...'),
            isMakingContributionsCompleted: __('Made some contributions'),
            isCollaboratingAndInteractingInProgress: __('Adding a couple of comments...'),
            isCollaboratingAndInteractingCompleted: __('Added a couple of comments'),
          }
        default:
          return {
            isSendingOutLinksInProgress: __('Inviting a few friends...'),
            isSendingOutLinksCompleted: __('Invited a few friends'),
            isMakingContributionsInProgress: __('Making some contributions...'),
            isMakingContributionsCompleted: __('Made some contributions'),
            isCollaboratingAndInteractingInProgress: __('Adding a couple of comments...'),
            isCollaboratingAndInteractingCompleted: __('Added a couple of comments'),
          }
      }
    }

    // Create computed properties for the data

    const commentData = computed(() => getCommentData())
    const postAttributionData = computed(() => getPostAttributionData())
    const postData = computed<PostAttributes[]>(() => {
      const sections: Section[] = useSurfaceSectionsStore().sortedSections
      return getPostDataWithSections(sections)
    })
    const surfaceTitleCopy = computed(() => getSurfaceTitleCopy())
    const currentSharePanelSpinnerCopy = computed<SharePanelSpinnerCopy>(() => getSharePanelSpinnerCopy())
    return {
      // ------------------------------
      // Landing step
      // ------------------------------
      landingPanelSteps,
      // ------------------------------
      // Topic step
      // ------------------------------
      currentTopicPanelCopy,
      // ------------------------------
      // Post step
      // ------------------------------
      currentPostPanelCopy,
      // ------------------------------
      // Share step
      // ------------------------------
      currentSharePanelCopy,
      currentSharePanelSpinnerCopy,
      // ------------------------------
      // Section names
      // ------------------------------
      sectionNames,
      // ------------------------------
      // Demo Data
      // ------------------------------
      commentData,
      postAttributionData,
      postData,
      surfaceTitleCopy,
    }
  },
)
