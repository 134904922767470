// @file Network requests related to post connection from surface

import FetchableObject from '@@/surface/api/fetchable_object'

const API_VERSION = 5

class PostConnectionApi extends FetchableObject {
  public static get url(): string {
    return `/api/${API_VERSION}/wish_connections`
  }

  public static get fetchUrl(): string {
    return `/api/${API_VERSION}/wish_connections?wall_id=\${ wall.id }`
  }
}

export { PostConnectionApi }
