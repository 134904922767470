// @file Network requests related to remake link from surface

import { asciiSafeStringify } from '@@/bits/json_stringify'
import type { VersionedFetchOptions } from '@@/surface/api/fetchable_object'
import FetchableObject from '@@/surface/api/fetchable_object'
import { fetchJson } from '@@/surface/api_fetch'
import type { WallCamelCase, WallId, WallRemakeLink, WallRemakeLinkProps, WallWhiteboardRemakeLink } from '@@/types'
import { HTTPMethod } from '@padlet/fetch'
import { mapKeys, snakeCase } from 'lodash-es'

class RemakeLinkApi extends FetchableObject {
  public static get remakeLinkUrl(): string {
    return `/api/\${ apiVersion }/walls/\${ wallId }/remake-link\${ method }`
  }

  public static async fetch(
    wallId: WallId,
    fetchOptions: VersionedFetchOptions = { apiVersion: 1 },
  ): Promise<WallRemakeLink | WallWhiteboardRemakeLink | undefined> {
    const { apiVersion, ...restFetchOptions } = fetchOptions

    const response = await fetchJson(this.buildUrl(this.remakeLinkUrl, { wallId, apiVersion, method: '' }), {
      method: HTTPMethod.get,
      ...restFetchOptions,
    })

    // Return undefined if remake link is not enabled
    return response?.data?.attributes
  }

  public static async enable(
    wallId: WallId,
    fetchOptions: VersionedFetchOptions = { apiVersion: 1 },
  ): Promise<WallRemakeLink | WallWhiteboardRemakeLink> {
    const { apiVersion, ...restFetchOptions } = fetchOptions

    const response = await fetchJson(this.buildUrl(this.remakeLinkUrl, { wallId, apiVersion, method: '/enable' }), {
      method: HTTPMethod.patch,
      ...restFetchOptions,
    })
    return response.data.attributes
  }

  public static async disable(wallId: WallId, fetchOptions: VersionedFetchOptions = { apiVersion: 1 }): Promise<void> {
    const { apiVersion, ...restFetchOptions } = fetchOptions

    await fetchJson(this.buildUrl(this.remakeLinkUrl, { wallId, apiVersion, method: '/disable' }), {
      method: HTTPMethod.patch,
      ...restFetchOptions,
    })
  }

  public static async update(
    wallId: WallId,
    props: Partial<WallRemakeLinkProps>,
    fetchOptions: VersionedFetchOptions = { apiVersion: 1 },
  ): Promise<WallRemakeLink> {
    const { apiVersion, ...restFetchOptions } = fetchOptions
    // Convert props to snake_case to send to backend
    const propsBody = mapKeys(props, (value, key) => snakeCase(key))
    const response = await fetchJson(this.buildUrl(this.remakeLinkUrl, { wallId, apiVersion, method: '' }), {
      method: HTTPMethod.patch,
      body: asciiSafeStringify(propsBody),
      ...restFetchOptions,
    })
    return response.data.attributes
  }

  public static async fetchWallsRemade(
    wallId: WallId,
    fetchOptions: VersionedFetchOptions = { apiVersion: 1 },
  ): Promise<Array<Pick<WallCamelCase, 'title' | 'builder' | 'links' | 'updatedAt' | 'thumbnail'>>> {
    const { apiVersion, ...restFetchOptions } = fetchOptions
    const response = await fetchJson(
      this.buildUrl(this.remakeLinkUrl, { wallId, apiVersion, method: '/walls-remade' }),
      {
        method: HTTPMethod.get,
        ...restFetchOptions,
      },
    )
    return response?.data?.attributes
  }
}

export { RemakeLinkApi }
