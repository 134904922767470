// @file Network requests related to poll from surface

import { asciiSafeStringify } from '@@/bits/json_stringify'
import type { VersionedFetchOptions } from '@@/surface/api/fetchable_object'
import FetchableObject from '@@/surface/api/fetchable_object'
import { fetchJson } from '@@/surface/api_fetch'
import type { Id } from '@@/types'
import type { JsonAPIResource, Wish } from '@padlet/arvo'
import { HTTPMethod } from '@padlet/fetch'

class PollApi extends FetchableObject {
  private static get updatePollUrl(): string {
    return `/api/\${ apiVersion }/polls/\${ pollId }`
  }

  private static get pollVoteUrl(): string {
    return `/api/\${ apiVersion }/polls/\${ pollId }/vote`
  }

  public static async updatePoll(
    pollParams: Record<string, any>,
    fetchOptions: VersionedFetchOptions = { apiVersion: 1 },
  ): Promise<Wish> {
    const { apiVersion, ...otherFetchOptions } = fetchOptions
    const response = await fetchJson(this.buildUrl(this.updatePollUrl, { apiVersion, pollId: pollParams.poll_id }), {
      ...otherFetchOptions,
      method: HTTPMethod.patch,
      body: asciiSafeStringify(pollParams),
    })
    const updatedWish = (response.data as JsonAPIResource<Wish>).attributes
    return updatedWish
  }

  public static async vote(
    { pollId, wishId, pollChoiceIds }: { pollId: Id; wishId: Id; pollChoiceIds: Id[] },
    fetchOptions: VersionedFetchOptions = { apiVersion: 1 },
  ): Promise<Wish> {
    const { apiVersion, ...otherFetchOptions } = fetchOptions
    const response = await fetchJson(this.buildUrl(this.pollVoteUrl, { apiVersion, pollId }), {
      ...otherFetchOptions,
      method: HTTPMethod.post,
      body: asciiSafeStringify({ wish_id: wishId, poll_choice_ids: pollChoiceIds }),
    })
    const updatedWish = (response.data as JsonAPIResource<Wish>).attributes
    return updatedWish
  }
}

export { PollApi }
