// @file Network requests related to wall onboarding panel from surface

import { asciiSafeStringify } from '@@/bits/json_stringify'
import FetchableObject from '@@/surface/api/fetchable_object'
import { fetchJson } from '@@/surface/api_fetch'
import type { WallId, WallOnboardingPanel } from '@@/types'
import type { JsonAPIResponse } from '@padlet/arvo'
import { HTTPMethod } from '@padlet/fetch'

class WallOnboardingPanelApi extends FetchableObject {
  public static async fetch(wallId: WallId): Promise<JsonAPIResponse<WallOnboardingPanel>> {
    return await fetchJson(`/api/1/walls/${wallId}/onboarding-panel/hide`, {
      method: HTTPMethod.post,
    })
  }

  public static async show(wallId: WallId): Promise<JsonAPIResponse<WallOnboardingPanel>> {
    return await fetchJson(`/api/1/walls/${wallId}/onboarding-panel/show`, {
      method: HTTPMethod.post,
    })
  }

  public static async dontShowGalleryAgain(
    wallId: WallId,
    templateId: string,
  ): Promise<JsonAPIResponse<WallOnboardingPanel>> {
    return await fetchJson(`/api/1/walls/${wallId}/onboarding-panel/disable-gallery-template`, {
      method: HTTPMethod.post,
      body: asciiSafeStringify({ template_id: templateId }),
    })
  }

  public static async generateSamplePosts(
    wallId: WallId,
    samplePostIds: string[],
  ): Promise<JsonAPIResponse<WallOnboardingPanel>> {
    return await fetchJson(`/api/1/walls/${wallId}/onboarding-panel/sample-posts`, {
      method: HTTPMethod.post,
      body: asciiSafeStringify({ sample_post_ids: samplePostIds }),
      headers: {
        'X-UID': '', // remove x-uid header so current_user can listen to 'add_wish' realtime event for the post to show up.
      },
    })
  }
}

export { WallOnboardingPanelApi }
