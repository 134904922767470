// @file Network requests related to wall privacy options from surface

import FetchableObject from '@@/surface/api/fetchable_object'
import { fetchJson } from '@@/surface/api_fetch'
import type { CanSetWallPrivacyOptions } from '@@/types'
import type { JsonAPIResponse } from '@padlet/arvo'
import { HTTPMethod } from '@padlet/fetch'

class WallPrivacyOptionsApi extends FetchableObject {
  public static get wallPrivacyOptionsUrl(): string {
    return `/api/\${ apiVersion }/surface/walls/\${ wallId }/privacy_options`
  }

  public static async fetchWallPrivacyOptions(
    wallId,
    fetchOptions = { apiVersion: 1 },
  ): Promise<JsonAPIResponse<CanSetWallPrivacyOptions>> {
    const { apiVersion, ...restFetchOptions } = fetchOptions

    return await fetchJson(this.buildUrl(this.wallPrivacyOptionsUrl, { wallId, apiVersion }), {
      method: HTTPMethod.get,
      ...restFetchOptions,
    })
  }
}

export { WallPrivacyOptionsApi }
