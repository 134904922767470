// @file Network requests related to wall post properties from surface
/* eslint-disable no-return-await, no-template-curly-in-string */
import { asciiSafeStringify } from '@@/bits/json_stringify'
import { getSubmissionRequestTokenHash } from '@@/bits/surface_share_links_helper'
import type { VersionedFetchOptions } from '@@/surface/api/fetchable_object'
import FetchableObject from '@@/surface/api/fetchable_object'
import { fetchJson } from '@@/surface/api_fetch'
import type { WallPostProperties as WallPostPropertiesType } from '@@/types'
import type { JsonAPIResponse } from '@padlet/arvo'
import { HTTPMethod } from '@padlet/fetch'

class WallPostPropertiesApi extends FetchableObject {
  public static get url(): string {
    return `/api/\${ apiVersion }/walls/\${ wallId }/post-properties`
  }

  public static async fetchWallPostProperties(
    wallId: number,
    fetchOptions: VersionedFetchOptions = { apiVersion: 1 },
  ): Promise<WallPostPropertiesType> {
    const { apiVersion, ...otherFetchOptions } = fetchOptions
    const response: JsonAPIResponse<WallPostPropertiesType> = await fetchJson(
      this.buildUrl(this.url, { wallId, apiVersion }),
      {
        method: HTTPMethod.get,
        query: getSubmissionRequestTokenHash(),
        ...otherFetchOptions,
      },
    )
    const postProperties = Array.isArray(response.data) ? response.data[0]?.attributes : response.data?.attributes
    if (postProperties == null) throw new Error('Invalid response from server')
    return postProperties
  }

  public static async updateWallPostProperties(
    wallId: number,
    postProperties: Partial<WallPostPropertiesType>,
    fetchOptions: VersionedFetchOptions = { apiVersion: 1 },
  ): Promise<WallPostPropertiesType> {
    const { apiVersion, ...otherFetchOptions } = fetchOptions
    const response: JsonAPIResponse<WallPostPropertiesType> = await fetchJson(
      this.buildUrl(this.url, { wallId, apiVersion }),
      {
        method: HTTPMethod.patch,
        body: asciiSafeStringify({ data: { attributes: postProperties } }),
        ...otherFetchOptions,
      },
    )
    const updatedPostProperties = Array.isArray(response.data)
      ? response.data[0]?.attributes
      : response.data?.attributes
    if (updatedPostProperties == null) throw new Error('Invalid response from server')
    return updatedPostProperties
  }
}

export { WallPostPropertiesApi }
