// @file state and actions for the "About this padlet" panel
import { useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import { useSurfaceStore } from '@@/pinia/surface'
import { WallApi } from '@@/surface/api/wall'
import { WallAnalyticsApi } from '@@/surface/api/wall_analytics'
import type { WallAnalytics } from '@@/types'
import type { JsonAPIResource } from '@padlet/arvo'
import { defineStore } from 'pinia'
import { ref } from 'vue'

interface WallSummary {
  wishes: number
  reactions: number
  comments: number
  contributors: number
  privacy: string
  storage_used: string
  format: string
}

export enum WallSummaryStatus {
  Loading = 'Loading',
  Errored = 'Errored',
  Completed = 'Completed',
}

export enum WallAnalyticsStatus {
  Loading = 'Loading',
  Errored = 'Errored',
  Completed = 'Completed',
}

export const useSurfaceDetailsPanelStore = defineStore('surfaceDetailsPanel', () => {
  const surfaceStore = useSurfaceStore()
  const globalSnackbarStore = useGlobalSnackbarStore()

  const xSurfaceDetailsPanel = ref(false)
  const wallSummary = ref<WallSummary | null>(null)
  const wallSummaryStatus = ref<WallSummaryStatus>(WallSummaryStatus.Loading)

  const wallAnalytics = ref<WallAnalytics | null>(null)
  const wallAnalyticsStatus = ref<WallAnalyticsStatus>(WallAnalyticsStatus.Loading)

  const showSurfaceDetailsPanel = (): void => {
    xSurfaceDetailsPanel.value = true
  }

  const closeSurfaceDetailsPanel = (): void => {
    xSurfaceDetailsPanel.value = false
    wallSummary.value = null
    wallSummaryStatus.value = WallSummaryStatus.Loading
    wallAnalytics.value = null
    wallAnalyticsStatus.value = WallAnalyticsStatus.Loading
  }

  const fetchWallSummary = async (): Promise<void> => {
    try {
      const wallSummaryData = await WallApi.fetchSummary({ hashid: surfaceStore.wallHashid })
      wallSummary.value = wallSummaryData
      wallSummaryStatus.value = WallSummaryStatus.Completed
    } catch {
      globalSnackbarStore.genericFetchError()
      wallSummaryStatus.value = WallSummaryStatus.Errored
    }
  }

  const fetchWallAnalytics = async (): Promise<void> => {
    try {
      const wallAnalyticsData = await WallAnalyticsApi.fetch(surfaceStore.wallHashid)
      wallAnalytics.value = (wallAnalyticsData.data as JsonAPIResource<WallAnalytics>)?.attributes || {}
      wallAnalyticsStatus.value = WallAnalyticsStatus.Completed
    } catch {
      globalSnackbarStore.genericFetchError()
      wallAnalyticsStatus.value = WallAnalyticsStatus.Errored
    }
  }

  return {
    // State
    xSurfaceDetailsPanel,
    wallSummary,
    wallSummaryStatus,

    wallAnalytics,
    wallAnalyticsStatus,

    // Actions
    fetchWallSummary,
    fetchWallAnalytics,
    showSurfaceDetailsPanel,
    closeSurfaceDetailsPanel,
  }
})
