// @file Network requests related to Whiteboard Tool Settings from sandbox
/* eslint-disable no-return-await, no-template-curly-in-string */
import { asciiSafeStringify } from '@@/bits/json_stringify'
import type { VersionedFetchOptions } from '@@/surface/api/fetchable_object'
import FetchableObject from '@@/surface/api/fetchable_object'
import { fetchJson } from '@@/surface/api_fetch'
import type { WhiteboardToolSettings } from '@@/types'
import type { JsonAPIResponse } from '@padlet/arvo'
import { HTTPMethod } from '@padlet/fetch'

class WhiteboardToolSettingsApi extends FetchableObject {
  public static get url(): string {
    return `/api/\${ apiVersion }/walls/\${ wallHashid }/whiteboard/tool-settings`
  }

  public static async fetch(
    wallHashid: string,
    fetchOptions: VersionedFetchOptions = { apiVersion: 9 },
  ): Promise<WhiteboardToolSettings> {
    const { apiVersion, ...otherFetchOptions } = fetchOptions
    const response: JsonAPIResponse<WhiteboardToolSettings> = await fetchJson(
      this.buildUrl(this.url, { wallHashid, apiVersion }),
      {
        method: HTTPMethod.get,
        ...otherFetchOptions,
      },
    )
    const settings = Array.isArray(response.data) ? response.data[0]?.attributes : response.data?.attributes
    if (settings == null) throw new Error('Invalid response from server')
    return settings
  }

  public static async update(
    wallHashid: string,
    settings: WhiteboardToolSettings,
    fetchOptions: VersionedFetchOptions = { apiVersion: 9 },
  ): Promise<WhiteboardToolSettings> {
    const { apiVersion, ...otherFetchOptions } = fetchOptions
    const response: JsonAPIResponse<WhiteboardToolSettings> = await fetchJson(
      this.buildUrl(this.url, { wallHashid, apiVersion }),
      {
        method: HTTPMethod.patch,
        body: asciiSafeStringify({ data: { attributes: settings } }),
        ...otherFetchOptions,
      },
    )
    const updatedSettings = Array.isArray(response.data) ? response.data[0]?.attributes : response.data?.attributes
    if (updatedSettings == null) throw new Error('Invalid response from server')
    return updatedSettings
  }
}

export { WhiteboardToolSettingsApi }
